import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, Typography, Paper, Button } from "@material-ui/core";
import React from "react";
import MyCard from "../../main/components/ui-components/my-card";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";
import classNames from "classnames";

function createData(transaction, user, amount, date) {
  return { transaction, user, amount, date };
}

const rows = [
  createData("Beginner Program", "scott.mescudi@gmail.com", "$59.99", "30.12.2020 12:23"),
  createData("Beginner Program", "jonaswallmann@hotmail.de", "$59.99", "30.12.2020 14:23"),
  createData("Advanced Program", "jaquesW@gmail.com", "$59.99", "30.12.2020 15:23"),
  createData("Beginner Program", "some@email.de", "$59.99", "30.12.2020 20:23"),
  createData("Beginner Program", "scott.mescudi@gmail.com", "$59.99", "31.12.2020 14:23"),
  createData("Beginner Program", "jonaswallmann@hotmail.de", "$59.99", "31.12.2020 16:23"),
  createData("Advanced Program", "jaquesW@gmail.com", "$59.99", "31.12.2020 17:23"),
  createData("Beginner Program", "some@email.de", "$59.99", "31.12.2020 20:23")
];

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

export default function AdminDashboardPage() {
  const classes = useStyles();

  return (
    <div>
      <DashboardPageHeader title="Dashboard"></DashboardPageHeader>
      <DashboardPageContent>

        <Warning></Warning>

        <RecentStatistics></RecentStatistics>

        <Typography class="mx-auto mb-4 mt-8 px-4 text-lg leading-6 font-medium" color="textSecondary">Recent activity</Typography>

        <TableContainer component={MyCard}>
          <Table className={classes.table} aria-label="recent activity table">
            <TableHead component={Box}>
              <TableRow>
                <TableCell align="left">Transaction</TableCell>
                <TableCell align="right">User</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell>{row.transaction}</TableCell>
                  <TableCell align="right">{row.user}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardPageContent>
    </div>
  );
}

function Warning() {
  return (
    <div className="bg-yellow-50 mb-4 border-l-4 rounded-md border-yellow-400 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-md text-yellow-700">
            This is a n early version of Admin Panel. It represets the basic features but the design, usability, performance is not representative of the final version.
          </p>
        </div>
      </div>
    </div>
  );
}

const useStyle = makeStyles(theme => ({
  bg: {
    backgroundColor: theme.palette.background.lighter
  }
}))

function RecentStatistics() {

  const classes = useStyle();

  return (
    <div>
      <Typography class="mx-auto mb-4 px-4 text-lg leading-6 font-medium" color="textSecondary">Last 30 days</Typography>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <Paper className=" overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <Typography className="text-sm font-medium  truncate" color="textSecondary">Total Users</Typography>
                <dd className="flex items-baseline">
                  <Typography className="text-2xl font-semibold ">71,897</Typography>

                  <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                    <svg
                      className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Increased by</span>
                    122
                  </div>
                </dd>
              </div>
            </div>
          </div>
          <div className={classNames(classes.bg, "px-4 py-2")}>
            <div className="text-sm">
              <Button color="primary">View all</Button>
              {/* <Typography href="#" color="primary" className="font-medium text-indigo-600 hover:text-indigo-500">
                View all<span className="sr-only"> Total Subscribers stats</span>
              </Typography> */}
            </div>
          </div>
        </Paper>

        <Paper className="overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                  />
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <Typography className="text-sm font-medium truncate" color="textSecondary">Total Sales</Typography>
                <dd className="flex items-baseline">
                  <Typography className="text-2xl font-semibold">32.423</Typography>

                  <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                    <svg
                      className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Increased by</span>
                    5.4%
                  </div>
                </dd>
              </div>
            </div>
          </div>
          <div className={classNames(classes.bg, "px-4 py-2")}>
            <div className="text-sm">
              <Button color="primary">View all</Button>
              {/* <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                View all<span className="sr-only"> Avg. Open Rate stats</span>
              </a> */}
            </div>
          </div>
        </Paper>

        <Paper className="overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  />
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <Typography className="text-sm font-medium truncate" color="textSecondary">Total Revenue</Typography>
                <dd className="flex items-baseline">
                  <Typography className="text-2xl font-semibold">$123.456</Typography>

                  <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                    <svg
                      className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Increased by</span>
                    7.2%
                  </div>
                </dd>
              </div>
            </div>
          </div>
          <div className={classNames(classes.bg, "px-4 py-2")}>
            <div className="text-sm">
            <Button color="primary">View all</Button>
            </div>
          </div>
        </Paper>
      </dl>
    </div>
  );
}
