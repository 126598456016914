export const hasTutor = (exercise) => {
    return (exercise.tutor?.info && exercise.tutor?.info.length > 0) || ((exercise.progressions?.length) > 0);
}

export const getSelectedProgression = (exercise) => {
    if (exercise.selectedProgressionId === null && (exercise.progressions?.isNotEmpty ?? false)) {
        exercise.selectedProgressionId = exercise.progressions[0].id;
    }
    return exercise.progressions?.find(progression => progression.id === exercise.selectedProgressionId) ?? undefined
}

export const secondsToMMSS = (seconds) => {
    if (seconds == null) {
        return '--:--';
    }
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    const s = sec > 9 ? sec.toString() : '0' + sec.toString();
    const m = min > 9 ? min.toString() : '0' + min.toString();   
    return m + ':' + s; 
}