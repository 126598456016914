import {
  Box,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../../components/tailwind-components/buttons";
import { removeSlashSuffix } from "../../../../utils/utils";
import MyCard from "../../main/components/ui-components/my-card";
import Row from "../../main/components/ui-components/row";
import SearchBar from "../../main/components/ui-components/searchbar";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

export function DataTableSelector({ children, items, columns, initialSelection }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <SecondaryButton onClick={() => setOpen(true)}>{children}</SecondaryButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DataFilterTable items={items} columns={columns} initialSelection={initialSelection}></DataFilterTable>
      </Dialog>
    </div>
  );
}

export default function DataFilterTable({ items, columns, initialSelection, selectionChange, selectMode, searchableKey }) {
  const [searchedItems, setSearchedItems] = useState(items);

  function filterSearch(searchTerm) {
    if (!searchTerm || searchTerm === "") {
      setSearchedItems(items);
    }

    const filtered = items.filter(item => {
      return item[searchableKey]?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setSearchedItems(filtered);
  }

  return (
    <React.Fragment>
      <Row>
        {/* <ToggleButtonGroup value="table" exclusive>
          <ToggleButton value="table">
            <Icon>view_headline</Icon>
          </ToggleButton>
          <ToggleButton value="cards">
            <Icon>view_list</Icon>
          </ToggleButton>
        </ToggleButtonGroup> */}
        <SearchBar onCancelSearch={() => filterSearch("")} onChange={value => filterSearch(value)} className="flex-grow ml-4"></SearchBar>
      </Row>
      <ItemTable selectMode={selectMode} items={searchedItems} columns={columns} initialSelection={initialSelection} selectionChange={selectionChange}></ItemTable>
    </React.Fragment>
  );
}

function descendingComparator(a, b, orderBy) {
  if ((b[orderBy] ?? 0) < (a[orderBy] ?? 0)) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function ItemTable({ items, columns, initialSelection, selectionChange, selectMode }) {
  const classes = useStyles();
  const router = useHistory();

  const [selected, setSelected] = useState(initialSelection ?? []);
  const [orderBy, setOrderBy] = useState("calories");
  const [order, setOrder] = useState("asc");
  let isSelectMode = selectMode;

  function select(selection) {
    setSelected(selection);
    selectionChange(selection);
  }

  function handleClick(row) {
    if (!isSelectMode) {
      router.push(`${removeSlashSuffix(router.location.pathname)}/${row.id}`);
    } else {
      const selectedIndex = selected.indexOf(row.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      select(newSelected);
    }
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = items.map(p => p.id);
      select(newSelecteds);
      return;
    }
    select([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <TableContainer component={MyCard} className="mt-4">
        <Table className={classes.table} aria-label="simple table">
          <TableHead component={Box} color="primary">
            <TableRow>
              {isSelectMode && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < items.length}
                    checked={items.length > 0 && selected.length === items.length}
                    onChange={event => handleSelectAllClick(event)}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
              )}
              {columns.map(headCell => (
                <TableCell
                  key={headCell.id}
                  // align={headCell.numeric ? "right" : "left"}
                  // padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={event => handleRequestSort(event, headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              {/* <TableCell sortDirection={orderBy === "name" ? order : false}>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={createSortHandler("name")}
                  >
                    Name
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <p className="text-xs font-medium text-gray-500 uppercase tracking-wider">Created</p>
                </TableCell> */}
              {/* <TableCell>
                <p className="text-xs font-medium text-gray-500 uppercase tracking-wider">STATUS</p>
              </TableCell> */}
              { !isSelectMode && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(items, getComparator(order, orderBy)).map((item, index) => (
              <TableRow className="cursor-pointer" key={item.id} hover onClick={() => handleClick(item)}>
                {isSelectMode && (
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected(item.id)} inputProps={{ "aria-labelledby": `Program ${item.name}` }} />
                  </TableCell>
                )}
                {columns.map(cell => (
                  <TableCell>
                    <Typography variant="body1">{cell.child(item)}</Typography>
                  </TableCell>
                ))}
                {/* <TableCell component="th" scope="row">
                  <Typography variant="body1">{item.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{new Date(item.editedAt).toLocaleString()}</Typography>
                </TableCell> */}
                {/* <TableCell>
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                </TableCell> */}
                { !isSelectMode && <TableCell align="right">
                  <IconButton className="m-0 p-0">
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>}
                {/* <Link to={ location => `${location.pathname}/${item.id}` } className="text-indigo-600 hover:text-indigo-900">
                  Edit
                </Link> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <PrimaryButton onClick={ () => onSelect(selected) }>Done</PrimaryButton> */}
    </React.Fragment>
  );
}
