import { Field } from "formik";
import { TextField } from "formik-material-ui";
import FormCard from "../../../../../components/tailwind-components/form-card";
import MediaItemInput from "../../components/media-item-input";

export default function ProgramGeneralForm({ formik }) {
  return (
    <FormCard title="Meta Information">
      <Field component={TextField} fullWidth variant="outlined" label="Name" name="name" type="text" placeholder="Program Name"></Field>

      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Descriptor"
        name="subtitle"
        type="text"
        placeholder="E.g. Program, E-Book, ..."
      ></Field>

      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Description"
        type="text"
        name="description"
        multiline
        rows="3"
        placeholder="Brief description of your workout"
      ></Field>

      {/* TODO: Multiselect and option to create custom categories */}
      {/* <Field component={Select} fullWidth variant="outlined" name="categories" label="Categories">
        <MenuItem value="full-body">Full Body</MenuItem>
        <MenuItem value="upper-body">Upper Body</MenuItem>
        <MenuItem value="lower-body">Lower Body</MenuItem>
      </Field> */}

      <MediaItemInput object="thumbnail" label="Thumbnail" imageOnly formik={formik}></MediaItemInput>
      <MediaItemInput object={"media"} formik={formik} label="Video Banner (Optional)" videoOnly></MediaItemInput>
    </FormCard>
  );
}
