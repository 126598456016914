import { updateProgramStatus } from "./db";

class ProgramStatusPhase {
    constructor(phase) {
        this.name = phase.name;
        this.schedule = phase.schedule.map((routineId, i) => new ProgramStatusPhaseDay(routineId, i));
    }
}

class ProgramStatusPhaseDay {
    constructor(routineId, phaseDayIndex) {
        this.routineId = routineId;
        this.phaseDayIndex = phaseDayIndex;
    }
}

export default class ProgramStatus {

    static fromJson(json, id) {
        const status = new ProgramStatus();
        Object.assign(status, json);
        status.id = id;

        status.calculateCurrentPhase();
        status.calculateCurrentDay();
        return status;
    }

    static startProgram(program, uid, schoolId) {

        if (!program || !uid || !schoolId) {
            throw "missing arguments";
        }

        const status = new ProgramStatus();

        status.id = uid + program.id;
        status.uid = uid;
        status.programId = program.id;
        status.startedAt = new Date().getTime();
        status.createdAt = new Date().getTime();
        status.isPaused = false;
        status.phases = program.phases.map(phase => new ProgramStatusPhase(phase));
        status.programName = program.name;
        status.schoolId = schoolId;
        
        status.calculateCurrentPhase();
        status.calculateCurrentDay();
        status.saveChanges();
        return status;
    }

    calculateCurrentPhase() {
        // TODO: Is this correct with different timezones?
        const startedAt = new Date(this.startedAt);
        const now = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Calculate Days passed since started
        const utc1 = Date.UTC(startedAt.getFullYear(), startedAt.getMonth(), startedAt.getDate());
        const utc2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

        const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);

        // Check what phases are allready done
        let duration = 0;
        let curPhase = this.phases.findIndex((phase) => {
            duration += phase.schedule.length;
            return diffDays < duration;
        });

        // Check if Program is done
        if (diffDays >= duration) {
            curPhase = this.phases.length - 1;
        }

        this.currentPhaseIndex = curPhase;
    }

    // TODO: Test this method (in hinsicht auf timezones)
    calculateCurrentDay() {
        const startedAt = new Date(this.startedAt);
        const now = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    
        // Calculate Days passed since started
        const utc1 = Date.UTC(startedAt.getFullYear(), startedAt.getMonth(), startedAt.getDate());
        const utc2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
    
        const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    
        let daysBeforeCurrentPhase = 0;
        for (let i = 0; i < this.currentPhaseIndex; i++) {
            daysBeforeCurrentPhase += this.phases[i].schedule.length;
        }
    
        this.currentDay = diffDays - daysBeforeCurrentPhase;
        
        // Check for missed workouts
        let nextWorkoutIndex = 0;
        const currentPhase = this.phases[this.currentPhaseIndex];
        for (let i = 0; i < currentPhase.schedule.length; i++) {
          if (currentPhase.schedule[i]?.routineId !== null && currentPhase.schedule[i]?.workoutId === null) {
            nextWorkoutIndex = i;
            break;
          }
        }
    
        if (this.currentDay > nextWorkoutIndex) {
            // Fill missed days with empty days
            this.pushWorkoutBack(nextWorkoutIndex, this.currentDay - nextWorkoutIndex, this.currentPhaseIndex)
        }
    }

    pushWorkoutBack(index, length, phaseIndex) {
        const phase = this.phases[phaseIndex];
        const fillDays = [];
        for (let i = 0; i < length; i++) {
            fillDays.push({
                routineId: null,
            })
        }
        phase.schedule.splice(index, 0, ...fillDays )
    }

    saveChanges() {
        updateProgramStatus(this)
    }
}