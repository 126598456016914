// WORKOUT SETTINGS HELP ITEMS

import { REP_CHALLENGE_TYPE, TIME_CHALLENGE_TYPE } from "../exercise-types";

export const ALLOW_WORKOUT_LANDSCAPE_TITLE = "Enable Landscape Mode";
export const ALLOW_WORKOUT_LANDSCAPE_MESSAGE = "Enable rotating your device to switch to the landscape workout player with fullscreen exercise videos";

export const ENABLE_AUTOMATIC_REP_COUNTING_TITLE = "Automatic Rep Counting";
export const ENABLE_AUTOMATIC_REP_COUNTING_MESSAGE = "For exercises with exercise timings (up/hold/down) it is possible to count reps automatically. If activated, the counted res during a set will be applied after stopping this set.";

export const ENABLE_COUNT_REPS_LOUD_TITLE = "Count Reps Out Loud";
export const ENABLE_COUNT_REPS_LOUD_MESSAGE = "If enabled and automatic rep counting is enabled, the counted reps will be counted out loud";

export const ENABLE_EXERCISE_STATE_AUDIO = "Enable Exercise Timings Audio";
export const ENABLE_EXERCISE_STATE_MESSAGE = 'If enabled, exercise timings will have audio instructions ("Up", "Hold", "Down", ...)';

export const ENABLE_AUTO_FILL_WEIGHT_TITLE = "Enable Auto Fill Weight";
export const ENABLE_AUTO_FILL_WEIGHT_MESSAGE = "If enabled, when inputting weight into on set, this weight will also be applied to the following sets of this exercise";

export const ENABLE_PLAY_GET_READY_TITLE = "Enable Get Ready Timer";
export const ENABLE_PLAY_GET_READY_MESSAGE = "If enabled, before starting an exercise, a player of 10 Seconds will be played to provide time to get ready for the exercise";

export const ENABLE_KEEP_SCREEN_AWAKE_TITLE = "Keep Screen Awake";
export const ENABLE_KEEP_SCREEN_AWAKE_MESSAGE = "If enabled, in the workout screen, the phone will not turn off the screen, when not used";

export const ENABLE_EXERCISE_GOALS_TITLE = "Enable Exercise Goals";
export const ENABLE_EXERCISE_GOALS_MESSAGE = "If enabled, if an exercise has an Exercise Goal, the player will automatically stop the exercise and continue with the rest timer. An exercise goal can either be defined with reps or seconds, it will be displayed at the exercise infos";

export const ENABLE_MOBILITY_AUTO_PLAY_TITLE = "Enable Auto Play Exercises";
export const ENABLE_MOBILITY_AUTO_PLAY_MESSAGE = "If enabled, the player starts the next mobility exercise automatically once an exercise is done. Else the user has to tap on next exercise to go to the next exercise";

export const ENABLE_SHOW_EXERCISE_TIMINGS_TITLE = "Show Exercise Timings";
export const ENABLE_SHOW_EXERCISE_TIMINGS_MESSAGE = 'If enabled and the exercise has exercise timings ("Up", "Hold", "DOWN"), arrows with corresponding timings will be displayed, so you can easily follow the timings';

export const SHOW_REP_INPUT_TITLE = "Show Rep Input";
export const SHOW_REP_INPUT_MESSAGE = "If deactivated, the rep input is hidden. Disable this if you don't want to input reps.";

export const SHOW_WEIGHT_INPUT_TITLE = "Show Weight Input";
export const SHOW_WEIGHT_INPUT_MESSAGE = "If deactivated, the weight input is hidden. Dibble this if you don't want to input weights.";


// DIALOGS
export const UNFINISHED_WORKOUT_DIALOG_TITLE = "Unfinished Workout";
export const UNFINISHED_WORKOUT_DIALOG_MESSAGE = "You have an unfinished Workout. Do you want to over continue this workout or dismiss it and start a new workout anyways?";


// HELP ITEMS
export const REP_CHALLENGE_EXPLANATION_TITLE = "Rep Challenge Exercise";
export const REP_CHALLENGE_EXPLANATION_MESSAGE = 'In this exercise you have a goal of repetitions to reach over the whole exercise. You can take as many sets as you need but you should try to reach the goal as fast as possible. You\'re done with the exercise if you reached the goal. If "Enable Exercise Goals" in workout settings is enabled, the workout player will stop automatically, else you need to stop the exercise manually with the done button (next to the timer).';

export const TIME_CHALLENGE_EXPLANATION_TITLE = "Time Challenge Exercise";
export const TIME_CHALLENGE_EXPLANATION_MESSAGE = 'In this exercise your goal is to do as many repetitions as you as possible until the timer runs out.';

export const REP_GOAL_EXPLANATION_TITLE = "Rep Goal";
export const REP_GOAL_EXPLANATION_MESSAGE = 'This Exercises has a rep goals, which specify exactly how many repetitions you should do for one set.\nWhen "Enable Exercise Goals" is enabled in the workout settings, the exercise player stops and goes to resting when the goal is reached. If deactivated you have to stop the player manually';

export const SECOND_GOAL_EXPLANATION_TITLE = "Second Goal";
export const SECOND_GOAL_EXPLANATION_MESSAGE = 'Some Exercises have second goals, which specify exactly how many second you should perform this exercise for one set.\n When "Enable Exercise Goals" is enabled in the workout settings, the exercise player stops and goes to resting when the goal is reached. If deactivated you have to stop the player manually';

export const REP_RANGE_EXPLANATION_TITLE = "Rep Range";
export const REP_RANGE_EXPLANATION_MESSAGE = "The rep range of an exercise specifies how hard an exercise should be for you. For example, a rep range of 10 - 15 Reps, means you should at maximum be able be to perform between 10 and 15 repetitions in one set. If the exercise has progressions, choose one that matches the rep range for you skill level";

export const REP_CHALLENGE_GOAL_EXPLANATION_TITLE = "Rep Challenge Goal";
export const REP_CHALLENGE_GOAL_EXPLANATION_MESSAGE = "YFor Rep Challenges you have a number of reps you need to do for the whole exercise. You decide how many sets you take, how many reps you do in one set and when and how long to rest.";

export const TIME_CHALLENGE_GOAL_EXPLANATION_TITLE = "Time Challenge Goal";
export const TIME_CHALLENGE_GOAL_EXPLANATION_MESSAGE = "In the Time Challenge, you try to do as many reps within a specific duration. You decide how many sets you take, how many reps you do in one set and when and how long to rest.";

export const EXERTION_SCALE_EXPLANATION_TITLE = "Intensity";
export const EXERTION_SCALE_EXPLANATION_MESSAGE = 'The intensity of an exercise. For example "100 %" means go till failure; do as many repetitions as possible. "80%" means, stop when you have reached about 80% of your maximum repetitions.';

export const EMOM_REST_EXPLANATION_TITLE = "EMOM";
export const EMOM_REST_EXPLANATION_MESSAGE = "EMOM stands for \"Every minute on the minute\". In this exercise you start a new set every minute, after a set, you have the remaining of the minute to rest.";

export const COMBINED_EXERCISE_EXPLANATION_TITLE = "Dropset Exercise";
export const COMBINED_EXERCISE_EXPLANATION_MESSAGE = "Dropset Exercises are Basic Exercises where each set has a different Rep Range, Exertion Scale and possibly Rest. Swipe on the set list at the bottom of the exercise card to see the different values. You can select a different progression for each set, such that it fits your skill level for the different Rep Ranges and Exertion Scales.";

export const NEXT_REST_EXPLANATION_TITLE = "Rest";
export const getRestExplanation = (exercise) => {

  if (exercise.type === REP_CHALLENGE_TYPE || exercise.type === TIME_CHALLENGE_TYPE) {
    if ((exercise.exerciseTimings.lastRest ?? 0) > 0) {
      return `Rest ${exercise.exerciseTimings.lastRest} seconds after the exercise.`;
    } else {
      return `Rest ${exercise.exerciseTimings.rest ?? 0} seconds after the exercise. `;
    }
  }

  if ((exercise.exerciseTimings?.lastRest ?? 0) > 0) {
    return `Rest ${exercise.exerciseTimings.rest ?? 0} seconds between sets and ${exercise.exerciseTimings.lastRest} after the last set.`;
  } else {
    return `Rest ${exercise.exerciseTimings?.rest ?? 0} seconds between sets. `;
  }
}

export const MOBILITY_REPS_EXPLANATION_TITLE = "Reps";
export const MOBILITY_REPS_EXPLANATION_MESSAGE = "."; // TODO:

export const MOBILITY_SETS_EXPLANATION_TITLE = "Sets";
export const MOBILITY_SETS_EXPLANATION_MESSAGE = "."; // TODO:

export const MOBILITY_HOLD_EXPLANATION_TITLE = "Hold Time";
export const MOBILITY_HOLD_EXPLANATION_MESSAGE = "."; // TODO:


// TUTORIAL EXPLANATIONS
export const TUTOR_BUTTON_TUTORIAL_TITLE = "Exercise Tutor";
export const TUTOR_BUTTON_TUTORIAL_MESSAGE = "An exercise has difference progressions, you will find them here.\nYou can select a progression that you want to do in you workout.\nFurthermore in the Exercise Tutor you will also find explanations and tutorial videos for the exercise and its progressions.";

export const SET_COUNTER_TUTORIAL_TITLE = "Set Counter";
export const SET_COULTER_TUTORIAL_MESSAGE = "Displays how many sets you should do in this exercise";

export const NAME_PROGRESSION_TUTORIAL_TITLE = "Exercise Name & Progression";
export const NAME_PROGRESSION_TUTORIAL_MESSAGE = "The first line displays the selected Progression. You can select Progression in the Exercise Tutor. The second line displays the exercise name. if the exercise does not have progressions, it will only show the exercise name";

export const EXERCISE_TIMINGS_TUTORIAL_TITLE = "Exercise Timings";
export const EXERCISE_TIMINGS_TUTORIAL_MESSAGE = "Some exercises have timings. These timings display how long the phases of an exercise should be. In this example you should take 1 second for the negative part, hold the exercise at the bottom for 1 second, then take 1 second for the positive part and finally hold at the top for 1 second. This marks one repetition.";

export const REP_CHALLENGE_COUNTER_TITLE = "Rep Challenge Counter";
export const REP_CHALLENGE_COUNTER_MESSAGE = "Tells you how many reps of the overall goal you have completed.";

export const TIME_CHALLENGE_COUNTER_TITLE = "Time Challenge Timer and Counter";
export const TIME_CHALLENGE_COUNTER_MESSAGE = "On the left, you see the exercise timer, once it runs out, the exercise is done. Try to complete as many reps as possible in this duration. The counter on the right tells you how many reps you have completed.";

export const EMOM_TIMER_TITLE = "EMOM Timer";
export const EMOM_TIMER_MESSAGE = "The Emom timer tells you have much time of the current minute you have left";

export const PREV_SET_BTN_TITLE = "Previous Set";
export const PREV_SET_BTN_MESSAGE = "If you're currently resting, this starts the set over again, otherwise this goes to the previous set, or to the previous exercise if you're currently in the first set";

export const NEXT_SET_BTN_TITLE = "Next Set";
export const NEXT_SET_BTN_MESSAGE = "If you're resting, this starts the next set or goes to the next exercise if you in the last set. If you are currently in the set, this starts the rest timer";

export const EXERCISE_STATE_TITLE = "Exercise Phase Instructions";
export const EXERCISE_STATE_MESSAGE = 'Tells you at what phase of an exercise you currently are. Possible instructing: "Rest", "Get Ready", "Hold", "Negative", "Hold Top", "Positive" or "Hold Bottom" ';

export const EXERCISE_TIMINGS_PLAYER_TITLE = "Exercise Timer";
export const EXERCISE_TIMINGS_PLAYER_MESSAGE = "Based on the current Exercise Phase, this shows a timer (when resting) or the current exercise timings";

export const PLAY_BTN_TITLE = "Play Button";
export const PLAY_BTN_MESSAGE = "Plays/Pauses the workout player";

export const STOP_BTN_TITLE = "Stop Button";
export const STOP_BTN_MESSAGE = "Stops a set and starts the rest timer";

export const PREV_EXERCISE_BTN_TITLE = "Previous exercise button";
export const PREV_EXERCISE_BTN_MESSAGE = "Goes to the previous exercise";

export const NEXT_EXERCISE_BTN_TITLE = "next exercise button";
export const NEXT_EXERCISE_BTN_MESSAGE = "Skips to the next exercise";