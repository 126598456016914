import React, { useEffect } from "react";
import { Icon, IconButton, Typography, useMediaQuery, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ContentSection, HorizontalContentCard, VerticalContentCard } from "../../../../components/content-list/content-list";
import BannerCard from "../../../../components/tailwind-components/banner-card";
import MyContainer from "../../../../components/tailwind-components/my-container";
import { SchoolContext, UserTenantContext } from "../../school";
import Ratio from "../components/ratio";
import MyCard from "../components/ui-components/my-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { fetchProgram, fetchProgramStatuses, fetchRoutine } from "../../../../db";
import ProgramStatus from "../components/program-status";
import MobileNavbar from "../components/mobile-navbar";
import { Swiper, SwiperSlide, Autoplay } from "swiper/react";
import classNames from "classnames";
import { BANNER_TYPE_LINK, BANNER_TYPE_PROGRAM, BANNER_TYPE_ROUTINE } from "../../../../utils/banner-types";
import { useBasePath } from "../../../../utils/get-subdomain";
import MediaItem from "../components/media-item";
import { ThemeContext } from "../../school";
import MyButton from "../../../../components/my-button";


const useStyleNews = makeStyles(theme => ({
  pagination: {
    position: "absolute",
    bottom: 8,
    right: 8,
    zIndex: 1
  },
  paginationItem: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: theme.palette.foreground.default,
    marginLeft: 8,
    opacity: 0.5
  },
  active: {
    backgroundColor: theme.palette.blueAccent.main,
    opacity: 1
  },
  gradientBg: {
    background: "linear-gradient(to top, rgb(0, 0, 0, 0.5), rgba(0, 0, 0, 0))"
  }
}));

export default function HomePage(props) {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  const school = useContext(SchoolContext);

  const [routines, setRoutines] = useState(props.routines);
  const [programs, setPrograms] = useState(props.programs);

  const user = useContext(UserTenantContext);

  const { id } = useParams();

  if (school) {
    return (
      <main>
        <MyContainer>
          {!isLarge && <MobileBanner school={school}></MobileBanner>}
          {isLarge && <Banner school={school}></Banner>}
          <div className="px-4">
            <div className="md:grid md:grid-cols-4 md:gap-4 mt-4">
              {isLarge && <SchoolBio school={school}></SchoolBio>}
              <div className="col-span-3 space-y-6 mb-12">
                <ProgramStatuses user={user} school={school}></ProgramStatuses>
                <AssignedPrograms user={user} school={school}></AssignedPrograms>
                <AssignedRoutines user={user} school={school}></AssignedRoutines>
                <FavoriteRoutines user={user} school={school}></FavoriteRoutines>
                {/* <CoachingCTA></CoachingCTA> */}
                <ContentSection school={school} contentItems={school.config.contentItems}></ContentSection>
                <Warning></Warning>
              </div>
            </div>
          </div>
        </MyContainer>
      </main>
    );
  }
  if (school === undefined) {
    return <HomeSkeleton></HomeSkeleton>; // TODO: Show spinner
  }
  if (school === null) {
    return (
      <Typography variant="h2" className="text-center p-4 mt-10" color="textSecondary">
        Sorry, this school does not seem to exist
      </Typography>
    );
  }
}

function Warning() {
  return (
    <div className="bg-yellow-50 border-l-4 rounded-md border-yellow-400 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Please keep in mind that this is an early version of Exercisable, meaning the performance is not representative of the final
            product and there might still be some bugs and other minor issues.
          </p>
        </div>
      </div>
    </div>
  );
}

function ProgramStatuses({ user, school }) {
  const matches1 = useMediaQuery("(min-width:800px)");
  const matches2 = useMediaQuery(theme => theme.breakpoints.up("md"));
  const matches3 = useMediaQuery("(min-width:1170px)");
  const [statuses, setStatuses] = useState();
  const [page, setPage] = useState(0);

  let cols = "grid-cols-1";
  if (matches1) {
    cols = "grid-cols-2";
  }
  if (matches2) {
    cols = "grid-cols-1";
  }
  if (matches3) {
    cols = "grid-cols-2";
  }

  useEffect(() => {
    if (user && school) {
      fetchProgramStatuses(school.id, user.uid).then(res => setStatuses(res));
    }
  }, [user, school]);

  if (statuses?.length > 0) {
    return (
      <div className={`grid ${cols} gap-4`}>
        {statuses?.map(status => (
          <ProgramStatus style={{}} showLink key={status.id} status={status}></ProgramStatus>
        ))}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}

function FavoriteRoutines({ user, school }) {
  const [routines, setRoutines] = useState();

  useEffect(() => {
    if (user && user.favoriteRoutineIds && school)
      Promise.all(user.favoriteRoutineIds.reverse().map(id => fetchRoutine(school.id, id))).then(routines => {
        setRoutines(routines);
      });
  }, [user, school]);

  if (routines?.length > 0) {
    return (
      <div>
        <Typography variant="h3" color="textSecondary" className="ml-2 mb-3">
          Favorite Workouts
        </Typography>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
          {routines?.map((item, i) => (
            <HorizontalContentCard key={`${item?.id}-${i}`} item={item}></HorizontalContentCard>
          ))}
        </div>
      </div>
    );
  }
  return <React.Fragment></React.Fragment>;
}

function AssignedRoutines({ user, school }) {
  const [routines, setRoutines] = useState();

  useEffect(() => {
    if (user && user.assignedRoutines && school)
      Promise.all(user.assignedRoutines.reverse().map(id => fetchRoutine(school.id, id))).then(routines => {
        setRoutines(routines);
      });
  }, [user, school]);

  if (routines?.length > 0) {
    return (
      <div>
        <Typography variant="h3" color="textSecondary" className="ml-2 mb-3">
          Workouts created for you personally
        </Typography>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
          {routines?.map((item, i) => (
            <HorizontalContentCard key={`${item?.id}-${i}`} item={item}></HorizontalContentCard>
          ))}
        </div>
      </div>
    );
  }
  return <React.Fragment></React.Fragment>;
}

function AssignedPrograms({ user, school }) {
  const [programs, setPrograms] = useState();

  useEffect(() => {
    if (user && user.assignedPrograms && school)
      Promise.all(user.assignedPrograms.reverse().map(id => fetchProgram(school.id, id))).then(programs => {
        setPrograms(programs);
      });
  }, [user, school]);

  if (programs?.length > 0) {
    return (
      <div>
        <Typography variant="h3" color="textSecondary" className="ml-2 mb-3">
          Programs created for you personally
        </Typography>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
          {programs?.map((item, i) => (
            <VerticalContentCard key={`${item?.id}-${i}`} item={item}></VerticalContentCard>
          ))}
        </div>
      </div>
    );
  }
  return <React.Fragment></React.Fragment>;
}

function MobileBanner({ school }) {
  const theme = useTheme();
  return (
    <div style={{ backgroundColor: theme.palette.background.paper }}>
      <BannerCarousel school={school}></BannerCarousel>
      <div className="p-4 shadow-sm">
        <div className="flex">
          <img className="rounded-full z-10" style={{ maxWidth: "80px", maxHeight: "80px" }} src={school.config.avatar} alt="" />
          <div className="m-auto ml-2">
            <div className="">
              <Typography variant="h2" className="ml-2 flex-grow">
                {school.config?.name ?? "School Name"}
              </Typography>
              <SocialMedia school={school}></SocialMedia>
            </div>
          </div>
        </div>
        <Typography className="mt-2" variant="body1" color="textSecondary">
          {school.config?.description ?? "Write something about your school here"}
        </Typography>
        {/* <div>
          <IconButton>
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </IconButton>
          <IconButton>
            <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
          </IconButton>
          <IconButton>
            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
          </IconButton>
        </div> */}
      </div>
    </div>
  );
}

function CoachingCTA() {
  
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext); 

  return (
    <div class="">
      <div class="">
        <div class="">
          <div className={`${isDarkTheme ? "bg-gray-800" : "bg-gray-500" } relative rounded-2xl px-6 py-10  overflow-hidden shadow-xl sm:px-12 sm:py-20`}>
            <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg
                class="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  class="text-gray-700 text-opacity-40"
                  
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  class="text-gray-900 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div class="relative">
              <div class="sm:text-center">
                <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">1-ON-1 Personal Coaching</h2>
                <p class="mt-6 mx-auto max-w-2xl text-lg text-gray-300">
                  Together we achieve your goals! Let's sit together, you tell me your goals and I create the perfect workout and nutrition plan for you. We both put in the work and check with regular meetings your progress.
                </p>
              </div>
              <form action="#" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                <div class="min-w-0 flex-1">
                  <label for="cta_email" class="sr-only">
                    Email address
                  </label>
                  <input
                    id="cta_email"
                    type="email"
                    class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    placeholder="Enter your email"
                  />
                </div>
                <div class="sm:mt-0 sm:ml-3">
                  <MyButton className="my-0 block w-full rounded-md border border-transparent px-5 py-3 text-base font-medium text-white shadow sm:px-10" color="secondary">Contact me</MyButton>
                  {/* <button
                    type="submit"
                    class="block w-full rounded-md border border-transparent px-5 py-3 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                    style={{ backgroundColor: "#ff4081" }}
                  >
                    Contact me
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Banner({ school }) {
  return (
    <BannerCard className="lg:mx-4 overflow-hidden safariOverflow">
      <BannerCarousel school={school}></BannerCarousel>
    </BannerCard>
  );
}

function BannerCarousel({ school }) {
  const classes = useStyleNews();
  const [page, setPage] = useState(0);

  return (
    <div className="relative" className="safariOverflow">
      <Swiper onSlideChange={swiper => setPage(swiper.realIndex)} autoplay={{ delay: 5000 }}>
        {school?.config?.bannerItems?.map((item, i) => (
          <SwiperSlide key={i}>
            <BannerItem item={item}></BannerItem>
          </SwiperSlide>
        ))}
      </Swiper>
      {school?.config?.bannerItems?.length > 1 && (
        <div className="absolute right-4 bottom-4 z-10 flex">
          {school?.config?.bannerItems?.map((_, i) => (
            <div key={i} className={`${classes.paginationItem} ${i === page ? classes.active : ""}`}></div>
          ))}
        </div>
      )}
    </div>
  );
}

function BannerItem({ item }) {
  const router = useHistory();
  const basePath = useBasePath();
  const classes = useStyleNews();

  function body() {
    return (
      <div className="relative">
        <Ratio ratio={14 / 7}>
          <MediaItem media={item.media}></MediaItem>
        </Ratio>
        <div className={classNames(classes.gradientBg, "absolute p-4 pt-10 left-0 right-0 bottom-0")}>
          {item.title && item.title !== "" && (
            <Typography variant="h2" className="text-white max-line-1">
              {item.title}
            </Typography>
          )}
          {item.description && item.description !== "" && <Typography className="text-gray-200 max-line-1">{item.description}</Typography>}
        </div>
      </div>
    );
  }

  if (item.type === BANNER_TYPE_LINK) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={item.link}>
        {body()}
      </a>
    );
  } else if (item.type === BANNER_TYPE_PROGRAM) {
    return (
      <div className="cursor-pointer" onClick={() => router.push(`/${basePath}program/${item.link}`)}>
        {body()}
      </div>
    );
  } else if (item.type === BANNER_TYPE_ROUTINE) {
    return (
      <div className="cursor-pointer" onClick={() => router.push(`/${basePath}routine/${item.link}`)}>
        {body()}
      </div>
    );
  }
}

function SchoolBio({ school }) {
  return (
    <MyCard className="mb-4 p-4" style={{ maxHeight: "-moz-max-content", height: "fit-content" }}>
      <div className="">
        {school?.config?.avatar && <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 p-2" src={school.config.avatar} alt="" />}
        <div className="mt-2 pl-2">
          <div className="font-medium text-lg leading-6 space-y-1">
            <Typography variant="h2">{school.config?.name ?? "School Name"}</Typography>
            <Typography variant="body1" color="textSecondary">
              {school.config?.description ?? "Write something about your school here"}
            </Typography>
          </div>
        </div>
        <SocialMedia className="mt-2" school={school}></SocialMedia>
      </div>
    </MyCard>
  );
}

function SocialMedia({ school, className }) {
  return (
    <div className={classNames(className, "flex")}>
      {school.config.youtube && (
        <IconButton onClick={() => window.open(school?.config?.youtube, "_blank")}>
          <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
        </IconButton>
      )}
      {school.config.instagram && (
        <IconButton onClick={() => window.open(school?.config?.instagram, "_blank")}>
          <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
        </IconButton>
      )}
      {school.config.twitter && (
        <IconButton onClick={() => window.open(school?.config?.twitter, "_blank")}>
          <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
        </IconButton>
      )}
      {school.config.facebook && (
        <IconButton onClick={() => window.open(school?.config?.facebook, "_blank")}>
          <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
        </IconButton>
      )}
    </div>
  );
}

function HomeSkeleton() {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <main>
      <MyContainer>
        <BannerCard className="overflow-hidden">
          <Ratio ratio={14 / 7}>
            <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
          </Ratio>
        </BannerCard>
        <div className="px-4">
          <div className="md:grid md:grid-cols-4 md:gap-4 mt-4">
            {isLarge && (
              <MyCard className="h-96">
                <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
              </MyCard>
            )}
            {/* {isLarge && <SchoolBio school={school}></SchoolBio>} */}
            <div className="col-span-3 space-y-6 mb-12">
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
                <MyCard className="h-56">
                  <Skeleton variant="rect" width="100%" height="100%"></Skeleton>
                </MyCard>
              </div>
              {/* <ContentSection school={school} contentItems={school.config.contentItems}></ContentSection> */}
            </div>
          </div>
        </div>
      </MyContainer>
    </main>
  );
}
