import {
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../../components/tailwind-components/buttons";
import DataFilterTable from "../components/data-filter-table";
import { listenPrograms } from "../../../../db";
import { SchoolContext } from "../../school";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";

export default function AdminProgramsPage() {
  const school = useContext(SchoolContext);
  const [programs, setPrograms] = useState();

  useEffect(() => {
    let programsListener;
    if (school) {
      programsListener = listenPrograms(school.id, programs => setPrograms(programs));
    }

    return () => {
      if (programsListener) {
        programsListener();
      }
    };
  }, [school]);

  const columns = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", child: item => item.name },
    { id: "editedAt", numeric: true, disablePadding: false, label: "Last Edit", child: item => new Date(item.editedAt).toLocaleString() }
  ];

  return (
    <div>
      <DashboardPageHeader
        title="Programs"
        actions={
          <Link to={location => `${location.pathname}/new`} type="button">
            <PrimaryButton className="ml-4">Create</PrimaryButton>
          </Link>
        }
      ></DashboardPageHeader>
      <DashboardPageContent>{programs && <DataFilterTable searchableKey="name" items={programs} columns={columns}></DataFilterTable>}</DashboardPageContent>
    </div>
  );
}
