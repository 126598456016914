import { Box, Button, Chip, CircularProgress, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core";
import "firebase/analytics";
import * as firebase from "firebase/app";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../../../../../components/loading-spinner";
import BannerCard from "../../../../../components/tailwind-components/banner-card";
import MyContainer from "../../../../../components/tailwind-components/my-container";
import { addRoutineToFavorite, fetchRoutine, removeRoutineFromFavorite } from "../../../../../db";
import { BASE_EXERCISE_TYPE, SUPERSET_EXERCISE_TYPE } from "../../../../../exercise-types";
import { useBasePath } from "../../../../../utils/get-subdomain";
import { mediaItemHasContent } from "../../../../../utils/utils";
import { LoginDialogContext, SchoolContext, UserTenantContext } from "../../../school";
import MediaItem from "../../components/media-item";
import Ratio from "../../components/ratio";
import BaseExercisePreview from "../../components/routine-page/base-exercise-preview";
import SupersetExercisePreview from "../../components/routine-page/superset-exercise-preview";
import ShareDialog from "../../components/share-dialog";
import { TutorDialog } from "../../components/tutor/tutor";
import Row from "../../components/ui-components/row";

const useStyle = makeStyles(theme => ({
  startButton: {
    width: "100%",
    margin: "auto",
    borderRadius: 10,
    fontWeight: "bold",
    padding: 8
  }
}));

export default function WorkoutPreview({ path }) {
  const [routine, setRoutine] = useState();
  const [selectedExercise, setSelectedExercise] = useState(0);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const user = useContext(UserTenantContext); 
  const school = useContext(SchoolContext);
  const basePath = useBasePath();
  const location = useLocation();
  const setOpen = useContext(LoginDialogContext);

  const router = useHistory();
  const { routineId } = useParams();

  const classes = useStyle();
  const route = `${path}routine/${routineId}`;

  useEffect(() => {
    if (school?.id && routineId) {
      // TODO: What if use is logged in and has bought routine but at this time auth object is not loaded yet?
      fetchRoutine(school.id, routineId).then(res => {
        setRoutine(res);
      });
    }
  }, [routineId, school]); // This makes sure routine is only loaded once after id changes

  function startWorkout() {

    const params = new URLSearchParams(location.search);
    const programId = params.get("id");
    const day = params.get("day");
    const phase = params.get("index");

    let data = {
      "host": `${window.location.hostname}/${basePath}`,
      "schoolId": school.id,
      "routine": routine
    }

    if (programId && day && phase)
    data = {
      ...data,
      "programStatusPointer": {
        "programId": programId,
        "phase": parseInt(phase),
        "day": parseInt(day)
      }
    }

    // Save started routine to localStorage so we can load it inside the flutter web app and start to workout from it
    localStorage.setItem("flutter.started-routine", JSON.stringify(data));
    // localStorage.setItem("flutter.hostname", "`${window.location.hostname}/${basePath}`");
    // localStorage.setItem("flutter.schoolId", "school?.id ?? ");

    firebase.analytics().logEvent("started_workout_web", {
      routine_id: routine?.id ?? "No Id",
      routine_name: routine?.name ?? "No Name",
    });

    router.push(`/${basePath}workout`);
  }

  function notFound() {
    return <Typography variant="h2" className="text-center p-4 mt-10" color="textSecondary">Sorry, this routine does not seem to exist</Typography>
  }

  function body() {
    return (
      <Switch>
        <Route path={`${route}/tutor/:exerciseId`}>
          <TutorDialog routine={routine}></TutorDialog>
          {/* <Dialog  fullScreen open  TransitionComponent={Transition}>
            <TutorPage
              routine={routine}
              // selectProgression={progId => {
              //   exc.selectedProgressionId = progId;
              // }}
            ></TutorPage>
          </Dialog> */}
        </Route>
        <Route path={route}>
          <BannerCard>
            <Ratio ratio={16 / 9}>
              <MediaItem media={mediaItemHasContent(routine.media) ? routine.media : routine.thumbnail}></MediaItem>
            </Ratio>
          </BannerCard>
          <Box m={2}>
            {routineInfo()}
            <div className="mt-8">{exercises()}</div>
          </Box>
          <ShareDialog
            open={showShareDialog}
            close={() => setShowShareDialog(false)}
            title={routine?.name ?? "Routine"}
            url={`https://fitness-platform.vercel.app/routine/${routine?.id ?? "routineId"}`}
          ></ShareDialog>
        </Route>
      </Switch>
    );
  }

  function onLogin() {
    if (setOpen) {
      setOpen(true);
    }
  }

  function toggleRoutineFavorite() {
    if (!user) {
      onLogin();
    } else {
      if (user?.favoriteRoutineIds?.includes(routine.id)) {
        removeRoutineFromFavorite(school.id, user.uid, routine.id);
      } else {
        addRoutineToFavorite(school.id, user.uid, routine.id);
      }
    }
  }

  function routineInfo() {
    return (
      <div className="mt-3">
        {routine.subtitle && (
          <Typography variant="subtitle1" color="primary" className="max-line-1 uppercase">
            {routine.subtitle}
          </Typography>
        )}
        <Row>
          <Typography variant="h1" className="max-line-1" style={{ flexGrow: 1 }}>
            {routine.name}
          </Typography>
          <IconButton onClick={() => setShowShareDialog(true)}>
            <Icon>reply</Icon>
          </IconButton>
          <IconButton onClick={toggleRoutineFavorite}>
            <Icon color={user?.favoriteRoutineIds?.includes(routine.id) ? "secondary" : "inherit"}>
              {user?.favoriteRoutineIds?.includes(routine.id) ? "favorite" : "favorite_outline"}
            </Icon>
          </IconButton>
        </Row>
        <Box>
          <Grid container alignItems="center">
            {routine.duration && (
              <Row>
                <Icon style={{ fontSize: 18 }}>timer</Icon>
                <Box paddingLeft="8px">
                  <Typography variant="subtitle1">{routine.duration}</Typography>
                </Box>
                <Box width={16}></Box>
              </Row>
            )}
            {routine.categories &&
              routine.categories.map(categorie => (
                <Box paddingX="4px" key={categorie}>
                  <Chip size="small" color="primary" label={categorie}></Chip>
                </Box>
              ))}
          </Grid>
        </Box>
        <Typography variant="body1" className="mt-4" color="textSecondary">
          {routine.description}
        </Typography>
        <br />
        <Row>
          {/* // TODO: On Click */}
          {routine.programName && (
            <Button style={{ width: "50%", textAlign: "start" }}>
              <Icon fontSize="small">event_note</Icon>
              <Box width={8}></Box>
              <Typography variant="subtitle1" style={{ flexGrow: 1 }} className="oneLineMax">
                {routine.programName}
              </Typography>
              <Icon>navigate_next</Icon>
              <Box width={16}></Box>
            </Button>
          )}
          {/* // TODO: On Click */}
          <Button style={{ width: routine.programName ? "50%" : "100%", textAlign: "start" }}>
            <Icon fontSize="small">timeline</Icon>
            <Box width={8}></Box>
            <Typography variant="subtitle1" style={{ flexGrow: 1 }} className="oneLineMax">
              Statistics
            </Typography>
            <Icon>navigate_next</Icon>
          </Button>
        </Row>

        <Box height={16}></Box>
        <Button className={classes.startButton} color="secondary" variant="contained" onClick={startWorkout}>
          Start Workout
        </Button>
        {/* <Box paddingY={2}>
          <Divider />
        </Box> */}
      </div>
    );
  }

  function exercises() {
    return routine.exercises?.map((exc, i) => exercise(exc, i));
  }

  function exercise(exc, i) {
    switch (exc.type) {
      case BASE_EXERCISE_TYPE:
        return (
          <Box paddingY={1} key={exc.id} onClick={() => setSelectedExercise(i)}>
            <BaseExercisePreview
              exercise={exc}
              expanded={selectedExercise === i}
              selectProgression={progId => {
                exc.selectedProgressionId = progId;
              }}
            ></BaseExercisePreview>
          </Box>
        );
        break;
      case SUPERSET_EXERCISE_TYPE:
        return (
          <Box paddingY={1} key={exc.id}>
            <SupersetExercisePreview
              exercise={exc}
              expanded={selectedExercise === i}
              onSelected={() => setSelectedExercise(i)}
              selectProgression={progId => {
                exc.selectedProgressionId = progId;
              }}
            ></SupersetExercisePreview>
          </Box>
        );
      default:
        break;
    }
  }

  return (
    // <MainLayout ref={ layoutRef } title={ routine?.name } description={ routine?.description } image={ routine?.media?.imageUrl } url={`/routine/${routine?.id}`}>
    // <ResponsiveCard>
    <MyContainer>
      {routine && body()}
      {routine === null && notFound()}
      {routine === undefined && <LoadingSpinner></LoadingSpinner>}
    </MyContainer>
    // </ResponsiveCard>
    // </MainLayout>
  );
}
