import * as firebase from 'firebase/app';
import 'firebase/auth';
import { createUser, createSchool, createSchoolUser, listenUserTenantDataChanges } from './db';

// const ownerAuth = firebase.app("owner_tenant").auth();



// SCHOOL AUTH
var firebaseConfig = {
    apiKey: "AIzaSyBCtPzKMJ06nTudrE1_h7TVcmzR6F5JiDc",
    authDomain: "fitnesshub-24b64.firebaseapp.com",
    databaseURL: "https://fitnesshub-24b64.firebaseio.com",
    projectId: "fitnesshub-24b64",
    storageBucket: "fitnesshub-24b64.appspot.com",
    messagingSenderId: "50705313157",
    appId: "1:50705313157:web:73e6d680f82d4502fa68ec",
    measurementId: "G-WMVSKD511C"
};

// Having a different Firebase Project for each school allow having multiple auth tokens at the same time, thus a user can be logged in at /my-school and /my-school1 at the same time
let schoolApp;

export const initializeSchoolApp = (school) => {
    // if (!app || app.tenantId !== school.tenantId) {
    // }
    try {
        schoolApp = firebase.initializeApp(firebaseConfig, school.id);
        schoolApp.auth().tenantId = school.tenantId;
        console.log("Initialized School App for tenant ", school.tenantId)
    } catch (e) {
        schoolApp = firebase.app(school.id)
        console.log("School App already created")
    }
}


export const listenUserTenantChanges = (schoolId, onChange) => {
    let userChangeUnsubscribe;

    if (!schoolApp?.auth()) {
        console.log("No School App");
        return;
    }

    return schoolApp.auth().onAuthStateChanged(async (user) => {
        console.log("User change", user, user?.id, user?.email, user?.tenantId);
        if (userChangeUnsubscribe) {
          userChangeUnsubscribe();
        }

        // if (user?.tenantId !== schoolApp.auth().tenantId) {
        //     throw "User tenantId and auth tenantId are not the same"
        // }
  
        if (user?.uid) {
            userChangeUnsubscribe = listenUserTenantDataChanges(schoolId, user.uid, (userData) => {
                console.log("User Change Data", userData);
                onChange({
                    uid: user.uid,
                    email: user.email,
                    ...userData
                });
                // onChange(userData ?? {
                //     uid: user.uid,
                // });
            })
        } else {
          onChange(null);
        }
      })
} 

export const login = (email, password) => {
    console.log("logging in at", schoolApp.auth().tenantId);
    return schoolApp.auth().signInWithEmailAndPassword(email, password);
}

export const signupEmail = async ({schoolId, email, password, username}) => {
    console.log("Signup", email, password, username, schoolId);
    if (!schoolId) {
        console.log("No School Id provided");
    }

    const { user } = await schoolApp.auth().createUserWithEmailAndPassword(email, password);
    return createSchoolUser(user.uid, user.email, username, schoolId);
    // try {
    //     return 'SUCCESS'
    // } catch(error) {
    //     console.log(error);
    //     switch (error.code) {
    //         case "auth/email-already-in-use":
    //             return "Email already in use";
    //         case "auth/argument-error":
    //             return "Please enter a valid email address";
    //         default:
    //             return 'An error occurred, please try again'
    //     }
    // }
}

export const logout = () => schoolApp.auth().signOut();


// OWNER AUTH

export const loginOwner = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

export const logoutOwner = async () => {
    return firebase.auth().signOut();
}

export const listenOwnerAuth = async (onChange) => {
    return firebase.auth().onAuthStateChanged(user => {
        onChange(user);
    })
}