import { Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchProgram, fetchRoutine } from "../../db";
import MediaItem from "../../pages/school/main/components/media-item";
import PriceTag from "../../pages/school/main/components/price-tag";
import { ProgramScheduleItem } from "../../pages/school/main/components/program-page/program-phase-section";
import Ratio from "../../pages/school/main/components/ratio";
import MyCard from "../../pages/school/main/components/ui-components/my-card";
import { PROGRAM_TYPE, ROUTINE_TYPE, SCHEDULE_TYPE, TEXT_TYPE } from "../../utils/content-types";
import { useBasePath } from "../../utils/get-subdomain";
import { MarkdownDisplay } from "../markdown";

export function ContentSection({ contentItems, school, className, program, status }) {
  return (
    <div className={className}>
      {contentItems?.map((row, i) => (
        <ContentRow row={row} school={school} program={program} status={status} key={i}></ContentRow>
      ))}
    </div>
  );
}

export function ContentRow({ row, school, program, status }) {
  const [contents, setContents] = useState();

  useEffect(() => {
    if (row && school) {
      Promise.all(
        row.contentIds
          ?.filter(id => id)
          .map(id => (row.type === ROUTINE_TYPE ? fetchRoutine(school.id, id) : fetchProgram(school.id, id))) ?? []
      ).then(res => setContents(res));
    }
  }, [row?.contentIds]);

  if (!row) {
    return <div></div>;
  }

  return (
    <div className="mb-4">
      {row.type === ROUTINE_TYPE && (
        <div>
          <Typography variant="h3" color="textSecondary" className="ml-2 mb-3">
            {row.title}
          </Typography>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
            {contents
              ?.filter(e => e)
              .map((item, i) => (
                <HorizontalContentCard key={`${item?.id}-${i}`} item={item}></HorizontalContentCard>
              ))}
          </div>
        </div>
      )}
      {row.type === PROGRAM_TYPE && (
        <div>
          <Typography variant="h3" color="textSecondary" className="ml-2 mb-3">
            {row.title}
          </Typography>
          {
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              {contents
                ?.filter(e => e)
                .map((item, i) => (
                  <VerticalContentCard key={`${item.id}-${i}`} item={item}></VerticalContentCard>
                ))}
            </div>
          }
        </div>
      )}
      {row.type === TEXT_TYPE && <TextContentItem item={row}></TextContentItem>}
      {row.type === SCHEDULE_TYPE && <ProgramScheduleItem section={row} program={program} status={status}></ProgramScheduleItem>}
    </div>
  );
}

export function HorizontalContentCard({ item }) {
  const basePath = useBasePath();
  const small = useMediaQuery(theme => theme.breakpoints.down("xs"));

  if (!item) return <div></div>;

  const path = item.contentType === ROUTINE_TYPE ? "routine" : "program";
  const route = `/${basePath}${path}/${item.id}`;

  return (
    // TODO: Link behaves different for when location.pathname ends with / or not
    <Link to={route}>
      <MyCard>
        <div className="flex">
          <div className="flex-shrink-0" style={{ width: "40%", maxHeight:  160}}>
            <MediaItem onlyImage media={item.thumbnail ?? item.media}></MediaItem>
          </div>
          <div className="sm:p-4 p-3">
            {item.subtitle && (
              <Typography color="primary" variant={!small ? "subtitle1" : "subtitle2"} className="uppercase max-line-1">
                {item.subtitle}
              </Typography>
            )}
            <Typography variant={!small ? "h2" : "h3"} className="max-line-1 sm:mb-0 mb-0.5">
              {item.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="max-line-3 min-h-6">
              {item.description}
            </Typography>
          </div>
        </div>
      </MyCard>
    </Link>
  );
}

export function VerticalContentCard({ item }) {
  const basePath = useBasePath();
  const path = item.contentType === ROUTINE_TYPE ? "routine" : "program";
  const route = `/${basePath}${path}/${item.id}`;

  if (!item) return <div></div>;

  return (
    // TODO: Link behaves different for when location.pathname ends with / or not
    <Link to={route}>
      <MyCard className="mx-auto overflow-hidden ">
        <div className="">
          <div className="relative">
            {item.price && <PriceTag price={item.price}></PriceTag>}
            <Ratio ratio={16 / 9}>
              <MediaItem onlyImage media={item.thumbnail ?? item.media}></MediaItem>
            </Ratio>
          </div>
          <div className="p-4">
            {item.subtitle && (
              <Typography color="primary" variant="subtitle1" className="uppercase max-lines-1">
                {item.subtitle}
              </Typography>
            )}
            <Typography variant="h2" className="max-line-1">
              {item.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="max-line-3">
              {item.description}
            </Typography>
          </div>
        </div>
      </MyCard>
    </Link>
  );
}

function TextContentItem({ item }) {
  return (
    <MyCard className="overflow-hidden">
      { ((item.title ?? "" !== "") || (item.subtitle ?? "" !== "")) && <div className="p-4 pb-2">
        <Typography variant="subtitle1" color="primary">
          {item.subtitle}
        </Typography>
        <Typography variant="h1" className=" tracking-tight">
          {item.title}
        </Typography>
      </div>}
      <MediaItem media={item.media} showFallback={false}></MediaItem>
      { item.body && <MarkdownDisplay className="px-4 pb-4" value={item.body}></MarkdownDisplay>}
    </MyCard>
  );
}
