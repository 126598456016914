import { Box, Breadcrumbs, Icon, IconButton, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { MobileDrawerContext } from "../../admin";

const useStyle = makeStyles(theme => ({
  nav: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function DashboardPageHeader({ title, actions, children, breadcrumbItems }) {
  const { setOpen, open } = useContext(MobileDrawerContext);
  const classes = useStyle();

  return (
    <Box className={classNames(classes.nav)}>
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center flex-shrink-0">
          <IconButton className="mr-2" onClick={() => setOpen(!open)}>
            <Icon>menu</Icon>
          </IconButton>
          {/* <div className="flex">
            <button
              onClick={() => setOpen(!open)}
              className="pr-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
            </button>
          </div> */}
          <div className="flex-1 min-w-0">
            <Typography variant="h1">{title}</Typography>
            {/* <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate py-2">{title}</h1> */}
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">{actions}</div>
        </div>
        {breadcrumbItems?.length > 1 && (
          <Breadcrumbs separator={<Icon>navigate_next</Icon>} style={{ marginLeft: "56px" }}>
            {breadcrumbItems?.map((value, index) => {
              return (
                <Link key={index} to={value.link}>
                  <Typography variant="subtitle1">{value.title}</Typography>
                </Link>
              );
            })}
          </Breadcrumbs>
        )}
      </div>

      {children}
    </Box>
  );
}
