import { useRouteMatch } from "react-router-dom";

export const getSubdomain = () => {
    
    // return "my-school";
    return getSubdomainLogic(window.location.hostname);
    // return undefined;

};


function getSubdomainLogic(domain) {
    const domains = ["training-hub-app", "fitness-hub", "localhost", "exercisable"];
    let subdomain;

    const elements = domain.split(".");

    if (elements[0] === "www") {
        elements.splice(0, 1);
    }
    if (!domains.includes(elements[0])) {
        subdomain = elements[0];
    }
    return subdomain;
}

export function useBasePath() {
    
    const route = useRouteMatch();
    const schoolId = route.path.split("/")[1];

    const subdom = getSubdomain();

    if (!subdom) {
        return `${schoolId}/`
    } else {
        return ""
    }
}


// const testDomains = [
//     "www.training-hub-app.com",
//     "training-hub-app.com",
//     "subdomain.training-hub-app.com",
//     "www.subdomain.training-hub-app.com",
//     "www.subdomain.training-hub-app.com/hallo",
//     "www.training-hub-app.com",
//     "fitness-hub.vercel.app",
//     "subdomain.fitness-hub.vercel.app",
//     "www.subdomain.fitness-hub.vercel.app",
//     "www.subdomain.fitness-hub.vercel.app/hallo",
// ];

// testDomains.forEach(element => {
//     console.log("sub: ", getSubdomainLogic(element), "   domain: ", element);
// });