import { useContext, useEffect } from "react"
import { useBasePath } from "../../../../../utils/get-subdomain";
import { SchoolContext } from "../../../school";

export default function WorkoutPage() {

  const school = useContext(SchoolContext);
  const basePath = useBasePath();


    useEffect(() => {
        localStorage.setItem("flutter.hostname", JSON.stringify(`${window.location.hostname}/${basePath}`));
        localStorage.setItem("flutter.schoolId", JSON.stringify(school?.id));
    }, [school, basePath]);

    console.log(JSON.stringify(`${window.location.hostname}/${basePath}`));

    // return <div>
    //     <iframe src="/web/index.html" className="w-full h-screen"></iframe>
    // </div>

    if (school) {
        return <div className="h-full">
            {/* <div className="bg-green-500 flex-grow">adasd</div> */}
            <iframe title="Workout Player" src="/web/index.html" className="w-full h-full"></iframe>
        </div>
    } else {
        return <div>Loading</div>
    }
}