import { ListItem } from "@material-ui/core";
import { Field, FieldArray } from "formik";
import { Select, TextField } from "formik-material-ui";
import React from "react";
import { PrimaryButton } from "../../../../components/tailwind-components/buttons";
import FormCard from "../../../../components/tailwind-components/form-card";
import { FileInput, FormInput, FormSelect } from "../../../../components/tailwind-components/form-elements";
import { BANNER_TYPE_LINK, BANNER_TYPE_PROGRAM, BANNER_TYPE_ROUTINE } from "../../../../utils/banner-types";
import MediaItemInput from "../components/media-item-input";

const defaultBannerItem = {
    title: "",
    description: "",
    type: BANNER_TYPE_LINK,
    link: ""
  };

export default function BannerInput({ values, formik }) {
  return (
    <React.Fragment>
      <FieldArray name="bannerItems">
        {({ insert, remove, push }) => (
          <div>
            {values.bannerItems?.map((value, i) => (
              <BannerItemInput key={i} index={i} content={value} formik={formik}></BannerItemInput>
            ))}

            <PrimaryButton onClick={() => push(defaultBannerItem)}>Add Banner</PrimaryButton>
          </div>
        )}
      </FieldArray>
    </React.Fragment>
  );
}

function BannerItemInput({ values, index, formik }) {
  return (
    <FormCard>
      <Field component={TextField} label="Title" name={`bannerItems.${index}.title`} placeholder="Title" type="text" variant="outlined" fullWidth></Field>
      <Field component={TextField} label="Subtitle" name={`bannerItems.${index}.description`} placeholder="Subtitle" type="text" variant="outlined" fullWidth></Field>
      <MediaItemInput formik={formik} object={`bannerItems.${index}.media`}></MediaItemInput>
      {/* <FileInput></FileInput> */}
      <Field component={Select} label="Link Behavior"  name={`bannerItems.${index}.type`} variant="outlined" fullWidth>
        <ListItem value={BANNER_TYPE_LINK}>External Link</ListItem>
        <ListItem value={BANNER_TYPE_ROUTINE}>Link to Workout</ListItem>
        <ListItem value={BANNER_TYPE_PROGRAM}>Link to Program</ListItem>
      </Field>
      {/* TODO: Input based on type (select workout/program from list) */}
      <Field component={TextField} label="Link" name={`bannerItems.${index}.link`} placeholder="Link" type="text" variant="outlined" fullWidth></Field>
    </FormCard>
  );
}
