import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { PrimaryButton } from "../../../../components/tailwind-components/buttons";
import { updateSchoolConfig } from "../../../../db";
import { SchoolContext } from "../../school";
import DashboardPageTabView from "../components/dashboard/dashboard-page-tab-view";
import BannerInput from "./banner-form";
import { ContentList, EditContentItemRoute } from "./content-form";
import SchoolMeta from "./school-meta-form";
// import Editor from "react-markdown-editor-lite";
// import ReactMarkdown from "react-markdown";
// import "react-markdown-editor-lite/lib/index.css";

export default function AdminSchoolPage({ path }) {
  const school = useContext(SchoolContext);
  const [tabIndex, setTabIndex] = useState(0);

  const formikRef = useRef();

  async function saveConfig(values) {
    await updateSchoolConfig(school.id, values);
    console.log("Saved Config");
  }

  const breadcrumbItems = [{
    title: "School",
    link: path
  }]

  if (school) {
    return (
      <Formik enableReinitialize innerRef={formikRef} initialValues={school.config ?? {}} onSubmit={values => saveConfig(values)}>
        {formik => (
          <Switch>
            <Route path={`${path}/item/:itemId`}>
              <EditContentItemRoute formik={formik} breadcrumbItems={[ ...breadcrumbItems, { title: "Content", link: `${path}?tab=2` } ]}></EditContentItemRoute>
              {/* <EditContentItemPage
                items={formik.values.contentItems}
                applyEdit={newItem => {
                  const index = formik.values.contentItems.findIndex(item => item.id === newItem.id);
                  if ((index ?? -1) < 0) return;

                  const newItems = formik.values.contentItems;
                  newItems[index] = newItem;

                  formik.setFieldValue("contentItems", newItems);
                }}
              ></EditContentItemPage> */}
            </Route>
            <Route path={path}>
              <DashboardPageTabView
                title="School Configuration"
                breadcrumbItems={breadcrumbItems}
                actions={<PrimaryButton onClick={() => formikRef?.current?.handleSubmit()}>Save</PrimaryButton>}
                tabTitles={["General", "Banner", "Content"]}
                tabChildren={[
                  <div>
                    <SchoolMeta values={formik.values}></SchoolMeta>
                  </div>,
                  <div>
                    <BannerInput values={formik.values} formik={formik}></BannerInput>
                  </div>,
                  <div>
                    {/* <ContentInput formik={formik}></ContentInput> */}
                    <ContentList path={`${path}/item`} formik={formik}></ContentList>
                  </div>
                ]}
              ></DashboardPageTabView>
            </Route>
          </Switch>
        )}
      </Formik>
    );
  }
  return <div>loading...</div>;
}
