

const lightPalette = {
  type: "light",
  primary: {
    main: "rgb(99, 102, 241)"
  },
  secondary: {
    main: "#ff4081"
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)"
  },
  background: {
    paper: "#ffffff",
    default: "#f3f4f6",
    lighter: "#f5f5f5"
  },
  foreground: {
    default: "#fafafa"
  },
  blueAccent: {
    main: "rgb(99, 102, 241)"
  },
};

const darkPalette = {
  type: "dark",
  primary: {
    main: "rgb(165, 180, 252)"
  },
  secondary: {
    main: "#ff4081"
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)"
  },
  background: {
    lighter: "rgb(42, 42, 42, 1)",
    paper: "rgb(30, 30, 30, 1)",
    default: "#000"
  },
  foreground: {
    default: "#fafafa"
  },
  blueAccent: {
    main: "rgb(99, 102, 241)"
  },
};

// const darkPalette = {
//   type: "dark",
//   primary: {
//     main: "rgb(165, 180, 252)"
//   },
//   secondary: {
//     main: "#ff4081"
//   },
//   text: {
//     primary: "#fff",
//     secondary: "rgba(255, 255, 255, 0.7)"
//   },
//   background: {
//     lighter: "#191f2d",
//     paper: "#1f2937",
//     default: "#111827"
//   },
//   foreground: {
//     default: "#fafafa"
//   },
//   blueAccent: {
//     main: "rgb(99, 102, 241)"
//   },
// };

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1920
  }
}

export const baseTheme = {
  // palette: lightTheme,
  typography: {
    fontFamily:
      "apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontWeight: "bold",
      fontSize: "2em",
      '@media (max-width:640px)': {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      lineHeight: "2rem",
      // '@media (max-width:640px)': {
      //   fontSize: '1.25rem',
      // },
    },
    h3: {
      fontWeight: "600",
      fontSize: "18px",
      // '@media (max-width:640px)': {
      //   fontSize: '16px',
      // },
    },
    body1: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      // '@media (max-width:640px)': {
      //   fontSize: '1rem',
      // },
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      // '@media (max-width:640px)': {
      //   fontSize: '0.75rem',
      // },
    },
    subtitle1: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "600",
      // '@media (max-width:640px)': {
      //   fontSize: '0.75rem',
      // },
    }
  },
  breakpoints: breakpoints
};

export const darkTheme = { ...baseTheme, palette: darkPalette };
export const lightTheme = { ...baseTheme, palette: lightPalette };


// const theme = createMuiTheme(baseTheme);

// export default theme;


// export const baseTheme = {
//   palette: {
//     type: 'dark',
//     primary: {
//       main: '#137AEE',
//     },
//     secondary: {
//       main: '#ff4081'
//     },
//     background: {
//       paper: '#1e1e20',
//       default: '#121212',
//       lighter: '#171717',
//     },
//     foreground: {
//       default: '#3b3b3b'
//     }
//   },
//   typography: {
//     h1: {
//       fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
//       fontWeight: "bold",
//       fontSize: "2em",
//     },
//     h2: {
//       fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
//       fontWeight: "bold",
//       fontSize: "24px",
//     },
//     h3: {
//       fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
//       fontWeight: "bold",
//       fontSize: "18px",
//     },
//   },
//   overrides: {
//     MuiTimelineItem: {
//       missingOppositeContent: {
//         "&:before": {
//           display: "none"
//         }
//       }
//     }
//   }
// }

// const theme = createMuiTheme(baseTheme);

// theme.overrides = {
//   ...theme.overrides,
//   MuiPaper: {
//     root: {
//       backgroundColor: theme.palette.background.default
//     }
//   }
// }

// export default theme;