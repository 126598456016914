import { Box, Typography } from "@material-ui/core";
import React from 'react';
import { ContentSection } from '../../../../../components/content-list/content-list';
import BannerCard from "../../../../../components/tailwind-components/banner-card";
import InfoItemList from "../info-item-list";
import MediaItem from "../media-item";
import Ratio from "../ratio";
import ResponsiveCard from "../ui-components/responsive-card";

export default function ProgramSection({ section, isPurchased, isLarge,  program, status }) {
  console.log(section);
  return (
    <React.Fragment>
      { section.media && <BannerCard isBanner={isLarge}>
        <Ratio ratio={16 / 9}>
          <MediaItem media={section.media}></MediaItem>
        </Ratio>
      </BannerCard>}
      <Box p={2} className="">
        {/* <Typography variant="h1" className="max-line-1" style={{ flexGrow: 1 }}>
          {section.name}
        </Typography> */}
        {/* <InfoItemList items={section.contentItems}></InfoItemList> */}
        <ContentSection contentItems={section.contentItems} program={program} status={status} className="mt-4"></ContentSection>
      </Box>
    </React.Fragment>
  );

  return (
    <ResponsiveCard>
      {/* TODO: include actual video */}
      {/* <Ratio ratio={16/9}>
            <MediaItem media={ {
                isVideo: true,
                videoUrl: "asdadsa",
                isExplaination: true,
                youtubeId: "vBKS8afomU4"
            }}></MediaItem>
        </Ratio> */}
      <Box p={2}>
        <h1>{section.title}</h1>
        <InfoItemList items={section.items}></InfoItemList>
      </Box>
    </ResponsiveCard>
  );
}
