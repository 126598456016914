import * as firebase from 'firebase';
import "firebase/storage";

export const uploadFile = async (file, fileName, schoolId) => {
    // const storageRef = firebase.app(schoolId).storage.ref();

    const storageRef = firebase.storage(firebase.app(schoolId)).ref();
    const imageRef = storageRef.child(schoolId).child(fileName);
    try {
        const res = await imageRef.put(file);
        return res.ref.getDownloadURL();
    } catch (error) {
        console.log(error);
        throw error;
    }
}