import { Typography } from "@material-ui/core";
import MyCard from "../../pages/school/main/components/ui-components/my-card";

const FormCard = ({ title, children }) => {
    return <div className="mt-5 mb-6 md:mt-0 md:col-span-2">
        {title && <div className="px-2 pb-2 pt-0">
            <Typography variant="body1" color="textSecondary">{title}</Typography>
        </div>}
        <MyCard>
            <div className="px-4 py-5 space-y-4">
                { children }
            </div>
        </MyCard>
    </div>;
}
 
export default FormCard;