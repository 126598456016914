import { FormControlLabel } from "@material-ui/core";
import { Field, Formik } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import FormCard from "../../../../../components/tailwind-components/form-card";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import MediaItemInput from "../../components/media-item-input";
import { EditContentItemRoute, TutorContentList } from "../../school/content-form";

export default function AdminProgressionEditPage({ applyEdit, exercise, path, breadcrumbItems }) {
  const { progressionId } = useParams();
  const progression = exercise.progressions?.find(prog => prog.id === progressionId);
  const router = useHistory();

  if (!progression.tutor) {
    progression.tutor = {};
  }

  breadcrumbItems = [ ...breadcrumbItems, { title: progression?.name ?? "New Progression", link: `${path}/${progressionId}` } ]

  return (
    <Formik
      initialValues={progression}
      validationSchema={Yup.object({
        name: Yup.string().required("Required")
      })}
      onSubmit={values => {
        applyEdit(values);
        router.goBack();
      }}
    >
      {formik => (
        <Switch>
          <Route path={`${path}/${progressionId}/item/:itemId`}>
            <EditContentItemRoute formik={formik} values={formik.values.tutor} breadcrumbItems={breadcrumbItems}></EditContentItemRoute>
          </Route>
          <Route path={`${path}/${progressionId}`}>
            <DashboardPageTabView
              title="Edit Progression"
              actions={<SecondaryButton onClick={formik.handleSubmit}>Done</SecondaryButton>}
              breadcrumbItems={breadcrumbItems}
              tabTitles={["General", "Tutor Page"]}
              tabChildren={[
                <FormCard>
                  <Field component={TextField} variant="outlined" fullWidth name="name" type="text" label="Progression Name"></Field>
                  <FormControlLabel
                      label="Is Default"
                      control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name={"isDefault"}></Field>}
                  ></FormControlLabel>
                  <br></br>
                  <FormControlLabel
                      label="Is Weighted"
                      control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="isWeighted"></Field>}
                  ></FormControlLabel>
                  <br></br>
                  <MediaItemInput
                    label="Progression Video/Image"
                    formik={formik}
                    object="media"
                    >
                  </MediaItemInput>
                </FormCard>,
                <TutorContentList formik={formik} path={`${path}/${progressionId}/item`}></TutorContentList>
              ]}
            ></DashboardPageTabView>
          </Route>
        </Switch>
      )}
    </Formik>
  );
}
