import { FieldArray } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../../../../components/tailwind-components/buttons";
import { generateUniqueId, removeSlashSuffix } from "../../../../../utils/utils";
import { ExerciseDnDList } from "../../components/dashboard/dnd-list";

export const initSection = () => {
  return {
    id: generateUniqueId(),
  };
};

export default function AdminEditSectionList({formik}) {

  const router = useHistory();

  return (
    <FieldArray name="sections">
      {({ remove, push }) => (
        <div>
          <ExerciseDnDList
            formik={formik}
            name="sections"
            remove={remove}
            onEdit={item => router.push(`${removeSlashSuffix(router.location.pathname)}/section/${item.id}`)}>
          </ExerciseDnDList>
          <PrimaryButton onClick={() => router.push(`${removeSlashSuffix(router.location.pathname)}/section/${generateUniqueId()}`)}>Add Section</PrimaryButton>
        </div>
      )}
    </FieldArray>
  );
}
