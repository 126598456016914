import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fetchProgram, fetchRoutine } from '../../../../db';
import { ROUTINE_TYPE } from '../../../../utils/content-types';
import { SchoolContext } from '../../school';
import ProgramCard from '../components/program-card';
import RoutineCard from '../components/routine-card';

const useStyle = makeStyles(theme => ({
    paginationItem: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: theme.palette.foreground.default,
        margin: 2
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    }
}))

export default function ContentRow({ row }) {

    const classes = useStyle();
    const school = useContext(SchoolContext);

    const [ page, setPage ] = useState(0);
    const [contents, setContents] = useState();

    useEffect(() => {
        Promise.all(row.contentIds.map(id => row.type === ROUTINE_TYPE ? fetchRoutine(school.id, id) : fetchProgram(school.id, id))).then(res => setContents(res))
    }, [row.contentIds])

    if (contents) {
        return (
            <Box p={0.5}>
                <Box paddingX={2} paddingY={1}>
                    <Typography variant="h3" color="textSecondary">{ row.title }</Typography>
                </Box>
                {
                    row.type === "ROUTINES"
                    ?   <Swiper 
                                renderExternalUpdate
                                spaceBetween={4}
                                slidesPerView={ 1.5 } 
                                // onSlideChange={ (swiper) => setPage(swiper.realIndex) }
                                breakpoints={{ 450: { slidesPerView: 2 },  700: { slidesPerView: 3 }, 1250: { slidesPerView: 4 }, }} >
                            { contents?.map((routine, i) => {
                                return <SwiperSlide key={ `${routine.id}-${i}` }>
                                    <RoutineCard routine={ routine }></RoutineCard>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    :   <Swiper 
                            spaceBetween={4} 
                            slidesPerView={ 1 }
                            // onSlideChange={ (swiper) => setPage(swiper.realIndex) }
                            breakpoints={{ 450: { slidesPerView: 2 },  700: { slidesPerView: 2 }, 1250: { slidesPerView: 3 }, }} >
                            { contents?.map((program, i) => {
                                return <SwiperSlide key={ `${program.id}-${i}` }>
                                    <ProgramCard program={program}></ProgramCard>
                                </SwiperSlide>
                            })}
                        </Swiper>
                }
    
            </Box>
        )
    } else {
        return <React.Fragment></React.Fragment>
    }
}