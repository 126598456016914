import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import ProgramStatus from "./program-status.model";
import { PROGRAM_TYPE, ROUTINE_TYPE, SCHEDULE_TYPE } from "./utils/content-types";

if (!firebase.apps.length) {
  var firebaseConfig = {
    apiKey: "AIzaSyBCtPzKMJ06nTudrE1_h7TVcmzR6F5JiDc",
    authDomain: "fitnesshub-24b64.firebaseapp.com",
    databaseURL: "https://fitnesshub-24b64.firebaseio.com",
    projectId: "fitnesshub-24b64",
    storageBucket: "fitnesshub-24b64.appspot.com",
    messagingSenderId: "50705313157",
    appId: "1:50705313157:web:73e6d680f82d4502fa68ec",
    measurementId: "G-WMVSKD511C"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.initializeApp(firebaseConfig, "owner_tenant");
  if (typeof window !== "undefined" && firebaseConfig.measurementId) {
    firebase.analytics();
  }
} else {
  firebase.app();
}

export const fetchRoutines = async schoolId => {
  // const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Routines").where("isFree", "==", true).get();
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .where("availability", "==", "public")
    .get();
  return res.docs.map(doc => {
    const routine = doc.data();
    routine.contentType = ROUTINE_TYPE;
    routine.id = doc.id;
    return routine;
  });
};

export const editRoutine = async (schoolId, routine) => {
  if (routine.id) {
    const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Routines").doc(routine.id).set(routine);
    return routine;
  } else {
    return firebase.firestore().collection("Schools").doc(schoolId).collection("Routines").add(routine);
  }
};

export const fetchRoutine = async (schoolId, id) => {
  try {
    const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Routines").doc(id).get();
    if (res.exists) {
      const routine = res.data();
      routine.id = res.id;
      routine.contentType = ROUTINE_TYPE;
      return routine;
    }
    return null;
  } catch (e) {
    console.log("Error fetchRoutine", e);
    return null;
  }
};

export const fetchConfig = async () => {
  const res = await firebase.firestore().collection("Configs").doc("HomePageConfig").get();
  return res.data();
};

export const fetchUser = async uid => {
  const res = await firebase.firestore().collection("Users").doc(uid).get();
  const user = res.data();
  user.uid = uid;
  return user;
};

export const fetchUsers = async schoolId => {
  const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Users").get();
  return res.docs.map(user => {
    return {
      id: user.id,
      ...user.data()
    };
  });
};

export const listenUserTenantDataChanges = (schoolId, uid, onChange) => {
  const unsub = firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .onSnapshot(doc => {
      if (doc.exists) {
        const user = doc.data();
        user.uid = uid;
        onChange(user);
      } else {
        onChange(null);
      }
    });

  return unsub;
};

export const listenUserChanges = (uid, onChange) => {
  const unsub = firebase
    .firestore()
    .collection("Users")
    .doc(uid)
    .onSnapshot(doc => {
      if (doc.exists) {
        const user = doc.data();
        user.uid = uid;
        onChange(user);
      } else {
        onChange(null);
      }
    });

  return unsub;
};

export const fetchPrograms = async schoolId => {
  const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Programs").get();
  return res.docs.map(doc => {
    const program = doc.data();
    program.contentType = PROGRAM_TYPE;
    program.id = doc.id;
    return program;
  });
};

export const fetchProgram = async (schoolId, id) => {
  const res = await firebase.firestore().collection("Schools").doc(schoolId).collection("Programs").doc(id).get();
  if (res.exists) {
    const program = res.data();
    program.contentType = PROGRAM_TYPE;
    program.id = res.id;
    program.sections = await fetchProgramSections(schoolId, id);
    return program;
  }
  return null;
};

// Can't have Sections in the program doc, bc. I can't restrict read access, so I save it in another document and combine it when loading a program
const fetchProgramSections = async (schoolId, programId) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Programs")
    .doc(programId)
    .collection("Sections")
    .doc("Sections")
    .get();
  if (res.exists) {
    return res.data().sections ?? [];
  }
  return [];
};

export const editProgram = async (schoolId, program) => {
  const sections = program.sections;

  const phases = [];
  program.sections?.forEach((section, index) => {
    section.contentItems?.forEach(item => {
      if (item.type === SCHEDULE_TYPE) {
        item.phase = index;
        phases.push({
          name: item.title,
          schedule: item.schedule
        });
      }
    });
  });

  program.phases = phases ?? [];
  program.editedAt = new Date().getTime();

  delete program.sections;

  await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Programs")
    .doc(program.id)
    .collection("Sections")
    .doc("Sections")
    .set({ sections: sections ?? [] });

  return firebase.firestore().collection("Schools").doc(schoolId).collection("Programs").doc(program.id).set(program, { merge: true });
};

export const fetchProgramRatings = async (schoolId, programId, startAt) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Programs")
    .doc(programId)
    .collection("Ratings")
    .orderBy("date", "desc")
    .startAfter(startAt)
    .limit(15)
    .get();
  return res.docs.map(doc => doc.data());
};

export const fetchWorkouts = async (schoolId, uid, startAt) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .collection("Workouts")
    .orderBy("finishedAt", "desc")
    .startAfter(startAt)
    .limit(15)
    .get();
  return res.docs.map(doc => {
    const workout = doc.data();
    workout.id = doc.id;
    return workout;
  });
};

export const fetchWorkout = async (schoolId, uid, id) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .collection("Workouts")
    .doc(id)
    .get();
  if (res.exists) {
    const routine = res.data();
    routine.id = res.id;
    return routine;
  }
  return null;
};

export const fetchProgramStatuses = async (schoolId, uid) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .collection("ProgramStatuses")
    .get();
  return res.docs.map(doc => ProgramStatus.fromJson(doc.data(), doc.id));
};

export const fetchProgramStatus = async (schoolId, uid, programId) => {
  const res = await firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .collection("ProgramStatuses")
    .doc(uid + programId)
    .get();
  if (res.exists) {
    return ProgramStatus.fromJson(res.data(), res.id);
  }
};

export const updateProgramStatus = programStatus => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(programStatus.schoolId)
    .collection("Users")
    .doc(programStatus.uid)
    .collection("ProgramStatuses")
    .doc(programStatus.uid + programStatus.programId)
    .set(JSON.parse(JSON.stringify(programStatus)));
};

export const purchaseProgram = async (schoolId, uid, programId) => {
  // TODO: replace with actual pruchase functionality
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .set(
      {
        programIds: firebase.firestore.FieldValue.arrayUnion(programId)
      },
      { merge: true }
    );
  // var purchase = firebase.functions().httpsCallable('purchaseProgram');
  // try {
  //   const res = await purchase({programId: programId})
  //   console.log("Purchase Res");
  // } catch (e) {
  //   console.log(e);
  // }
};

export const createSchool = async values => {
  const schoolCreation = firebase.functions().httpsCallable("schoolCreation");
  console.log(values);
  const res = await schoolCreation(values);
  return res.data;
};

export const addRoutineToFavorite = async (schoolId, uid, routineId) => {
  try {
    firebase
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Users")
      .doc(uid)
      .set(
        {
          favoriteRoutineIds: firebase.firestore.FieldValue.arrayUnion(routineId)
        },
        { merge: true }
      );
  } catch (e) {
    console.log(e);
  }
};

export const assignRoutine = async (schoolId, uid, routineId) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .set(
      {
        assignedRoutines: firebase.firestore.FieldValue.arrayUnion(routineId)
      },
      { merge: true }
    );
};

export const assignProgram = async (schoolId, uid, routineId) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .set(
      {
        assignedPrograms: firebase.firestore.FieldValue.arrayUnion(routineId)
      },
      { merge: true }
    );
};

// TODO: Combine 3 functions below to one
export const removeRoutineFromFavorite = async (schoolId, uid, routineId) => {
  try {
    firebase
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Users")
      .doc(uid)
      .set(
        {
          favoriteRoutineIds: firebase.firestore.FieldValue.arrayRemove(routineId)
        },
        { merge: true }
      );
  } catch (e) {
    console.log(e);
  }
};

export const removeAssignedRoutine = async (schoolId, uid, routineId) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .set(
      {
        assignedRoutines: firebase.firestore.FieldValue.arrayRemove(routineId)
      },
      { merge: true }
    );
};

export const removeAssignedProgram = async (schoolId, uid, routineId) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Users")
    .doc(uid)
    .set(
      {
        assignedPrograms: firebase.firestore.FieldValue.arrayRemove(routineId)
      },
      { merge: true }
    );
};

// export const createUser = async (uid, email, username, schoolId) => {
//   return firebase.firestore().collection("Users").doc(uid).set({
//     email: email,
//     userName: username,
//     schoolId: schoolId,
//     signupDate: new Date().getTime(),
//   })
// }

export const createSchoolUser = async (uid, email, username, schoolId) => {
  return firebase.firestore().collection("Schools").doc(schoolId).collection("Users").doc(uid).set({
    email: email,
    userName: username,
    signupDate: new Date().getTime(),
    createdAt: new Date().getTime()
  });
};

// export const createSchool = async (schoolId, schoolName, ownerId) => {
//   try {
//     await firebase.firestore().collection("Schools").doc(schoolId).set({
//       ownerId: ownerId,
//       config: {
//         name: schoolName,
//       },
//       createdAt: new Date().getTime()
//     });
//     return schoolId;
//   } catch (error) {
//     throw error;
//   }
// }

export const updateSchoolConfig = async (schoolId, values) => {
  try {
    await firebase.firestore().collection("Schools").doc(schoolId).update({
      config: values
    });
    return schoolId;
  } catch (error) {
    throw error;
  }
};

export const fetchSchool = async schoolId => {
  console.log(schoolId);
  const res = await firebase.firestore().collection("Schools").doc(schoolId).get();
  if (res.exists) {
    const school = res.data();
    school.id = res.id;
    return school;
  }
  return null;
};

export const createRoutine = async (schoolId, routine) => {
  return firebase.firestore().collection("Schools").doc(schoolId).collection("Routines").add(routine);
};

export const listenRoutines = (schoolId, onChange) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .onSnapshot(res => {
      const routines = res.docs.map(doc => {
        const routine = doc.data();
        routine.id = doc.id;
        return routine;
      });
      onChange(routines);
    });
};

export const listenPrograms = (schoolId, onChange) => {
  return firebase
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Programs")
    .onSnapshot(res => {
      onChange(
        res.docs.map(doc => {
          const program = doc.data();
          program.id = doc.id;
          return program;
        })
      );
    });
};

export const addReview = async () => {
  firebase.firestore().collection("Programs").doc("tALHGyfHzWsnHsy7RmVd").collection("Ratings").add({
    date: new Date().getTime(),
    title: "Great App",
    rating: 5,
    message:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
    programId: "tALHGyfHzWsnHsy7RmVd",
    userName: "TylerTheCreator"
  });
  firebase.firestore().collection("Programs").doc("tALHGyfHzWsnHsy7RmVd").collection("Ratings").add({
    date: new Date().getTime(),
    title: "Love it!",
    rating: 4,
    message:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
    programId: "tALHGyfHzWsnHsy7RmVd",
    userName: "Kid Cudi"
  });
  firebase.firestore().collection("Programs").doc("tALHGyfHzWsnHsy7RmVd").collection("Ratings").add({
    date: new Date().getTime(),
    title: "Awesome App!",
    rating: 5,
    message:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
    programId: "tALHGyfHzWsnHsy7RmVd",
    userName: "Travis Scott"
  });
  firebase.firestore().collection("Programs").doc("tALHGyfHzWsnHsy7RmVd").collection("Ratings").add({
    date: new Date().getTime(),
    title: "Dope App mate",
    rating: 5,
    message:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
    programId: "tALHGyfHzWsnHsy7RmVd",
    userName: "Kanye West"
  });
  firebase.firestore().collection("Programs").doc("tALHGyfHzWsnHsy7RmVd").collection("Ratings").add({
    date: new Date().getTime(),
    title: "Great Program",
    rating: 4,
    message:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
    programId: "tALHGyfHzWsnHsy7RmVd",
    userName: "Denzel Curry"
  });
};
