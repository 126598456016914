import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import MyCard from "./ui-components/my-card";
import Row from "./ui-components/row";

const useStyle = makeStyles(theme => ({
  setCounter: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    position: "relative"
    // TODO: Blue Shadow
  },
  setCount: {
    textAlign: "center",
    lineHeight: "38px",
    color: "white",
    fontWeight: 700,
    fontSize: 16
  },
  subtitle: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.text.secondary
  },
  repeatIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  titleLg: {
    lineHeight: "38px"
  },
  titleSm: {
    lineHeight: "22px"
  },
  subtitleRow: {
    height: "16px"
  }
}));

export function ExerciseCard({ exerciseTitle, body, color, expanded, exerciseColor, trailing, sets }) {
  return (
    <MyCard>
      <ExerciseCardTitle setCount={sets} exerciseColor={exerciseColor} exerciseTitle={exerciseTitle}></ExerciseCardTitle>
    </MyCard>
  );
}

export function ExerciseCardTitle({ setCount, exerciseTitle, exerciseColor, trailing }) {
  const classes = useStyle();

  return (
    <Box padding="10px" style={{ cursor: "pointer" }}>
      <Row>
        <Box width={38} height={38} minWidth={38} className={classes.setCounter}>
          <img className={classes.repeatIcon} src="/assets/icons/repeat.icon.3.svg"></img>
          <h4 className={classes.setCount}>{setCount >= 0 ? setCount : "X"}</h4>
        </Box>
        <Box width={16}></Box>
        <div style={{ flexGrow: 1 }}>{exerciseTitle}</div>
        {/* TODO: trailing (see Flutter) */}
        {trailing}
      </Row>
    </Box>
  );
}

export function ExerciseTitle({ title, subtitle, exerciseExplanation }) {
  const classes = useStyle();

  if (subtitle || exerciseExplanation) {
    return (
      <div>
        <Typography variant="h3">{title}</Typography>
        <Row container className={classes.subtitleRow}>
          {subtitle && <Typography className={`${classes.subtitle} oneLineMax`}>{subtitle}</Typography>}
          {/* {subtitle && <p className={`${classes.subtitle} oneLineMax`}>{subtitle}</p>} */}
          {subtitle && exerciseExplanation && (
            <Box paddingX={1} paddingY="2px" height={14}>
              <Divider orientation="vertical"></Divider>
            </Box>
          )}
          <span className={`${classes.subtitle} oneLineMax`}>{exerciseExplanation}</span>
        </Row>
      </div>
    );
  } else {
    return (
      <Typography variant="h2" className={classes.titleLg}>
        {title}
      </Typography>
    );
  }
}
