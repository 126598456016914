import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { MarkdownDisplay } from "../markdown";

const useStyle = makeStyles(theme => ({
  input: {
    // backgroundColor: theme.palette.background.default
  }
}))

export const FormInput = ({ label, trailing, leading, className, ...props }) => {
  const classes = useStyle();
  const [field, meta] = useField(props);

  return (
    <div className="col-span-3 sm:col-span-2">
      {label && (
        <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="flex rounded-md shadow-sm">
        {leading}
        <input
          {...field}
          {...props}
          className={`${ classes.input } focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-600 ${className}`}
        />
        {trailing}
      </div>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export const FormTextarea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="col-span-3 sm:col-span-2">
      <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <textarea
        className="text-input"
        {...field}
        {...props}
        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
      />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export const FormMarkdownEditor = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [value, setValue] = React.useState("**Hello world!!!**");

  return (
    <div className="col-span-3 sm:col-span-2">
      <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <ReactMde
        // value={value}
        // onChange={setValue}
        {...field}
        {...props}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={
          markdown => {
            console.log(markdown);
            // return <ReactMarkdown source={markdown} />;
            return Promise.resolve(<MarkdownDisplay value={markdown} />)
          }
        }
        // loadSuggestions={loadSuggestions}
        childProps={{
          writeButton: {
            tabIndex: -1
          }
        }}
        // paste={{
        //   saveImage: save
        // }}
      />
      {/* <textarea
        className="text-input"
        {...field}
        {...props}
        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
      /> */}
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="col-span-6 sm:col-span-3">
      <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        {...field}
        {...props}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export const FileInput = ({ label }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-sm text-gray-600">
            <button className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Upload a file
            </button>
            or drag and drop
          </p>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
    </div>
  );
};
