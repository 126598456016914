import { BrowserRouter, Switch, Route } from "react-router-dom";
import LandingPage from "./pages/landing"
import SignUpPage from "./pages/signup";
import GeneratePage from "./pages/generate";
import SchoolPage from "./pages/school/school";
import 'swiper/swiper-bundle.css';
import { getSubdomain } from "./utils/get-subdomain";
import SwiperCore, { Autoplay } from 'swiper';

// export const OwnerContent = createContext();

function App() {

  const subdomain = getSubdomain();

  console.log("SUBDOMAIN", subdomain);

  SwiperCore.use([Autoplay])

  if (!subdomain) {
    return <BrowserRouter>
      <Switch>
        <Route path="/signup">
          <SignUpPage></SignUpPage>
        </Route>
        <Route path="/generate">
          <GeneratePage></GeneratePage>
        </Route>
        <Route path="/:id">
          <SchoolPage></SchoolPage>
        </Route>
        <Route path="/">
          <LandingPage></LandingPage>
        </Route>
      </Switch>
    </BrowserRouter>
  } else {
    return <BrowserRouter>      
      <SchoolPage></SchoolPage>
    </BrowserRouter>
  }
}

export default App;
