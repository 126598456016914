import { FormControlLabel, ListItem, Radio } from "@material-ui/core";
import { Field, FieldArray, Formik } from "formik";
import { Checkbox, RadioGroup, Select, TextField } from "formik-material-ui";
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import FormCard from "../../../../../components/tailwind-components/form-card";
import { HOLD, HOLD_DOWN, NEGATIVE, POSITIVE } from "../../../../../utils/exercise-states";
import { generateUniqueId, removeSlashSuffix } from "../../../../../utils/utils";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import { ExerciseDnDList } from "../../components/dashboard/dnd-list";
import MediaItemInput from "../../components/media-item-input";
import { EditContentItemRoute, TutorContentList } from "../../school/content-form";
import AdminProgressionEditPage from "./edit-progression";
import { BASE_EXERCISE_TYPE, SUPERSET_EXERCISE_TYPE } from "../../../../../exercise-types";

function initProgression() {
  return {
    id: generateUniqueId(),
    media: {
      isExplanation: false
    },
    tutor: {
      media: {
        isExplanation: true,
        isYoutubeVideo: false
      }
    },
    name: undefined,
    isDefault: false,
    isWeighted: false
  };
}

export default function BaseExerciseForm({ applyEdit, exercise, path, breadcrumbItems }) {
  const router = useHistory();

  if (!exercise.tutor) {
    exercise.tutor = {};
  }

  breadcrumbItems = [...breadcrumbItems, { title: exercise.name, link: `${path}/${exercise.id}` }];

  return (
    <Formik
      initialValues={exercise}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        sets: Yup.number().integer("Only integers").moreThan(0, "Must be greater then 0").required("Required"),
        // rest: Yup.number().integer("Input as seconds").positive("Only positive numbers").required("Required")
      })}
      onSubmit={values => {
        applyEdit(values);
        router.goBack();
      }}
    >
      {formik => (
        <Switch>
          <Route path={`${path}/${exercise.id}/item/:itemId`}>
            <EditContentItemRoute
              formik={formik}
              values={formik.values.tutor}
              breadcrumbItems={[...breadcrumbItems, { title: "Tutor", link: `${path}/${exercise.id}?tab=2` }]}
            ></EditContentItemRoute>
          </Route>
          <Route path={`${path}/${exercise.id}/progression/:progressionId`}>
            <AdminProgressionEditPage
              path={`${path}/${exercise.id}/progression`}
              exercise={formik.values}
              breadcrumbItems={[...breadcrumbItems, { title: "Progressions", link: `${path}/${exercise.id}?tab=1` }]}
              applyEdit={progression => {
                const progressions = formik.values.progressions;
                const index = progressions.findIndex(prog => prog.id === progression.id);
                progressions[index] = progression;
                formik.setFieldValue("progressions", progressions);
              }}
            ></AdminProgressionEditPage>
          </Route>
          <Route path={`${path}/${exercise.id}`}>
            <DashboardPageTabView
              title="Edit Exercise"
              actions={<SecondaryButton onClick={() => { console.log("****", formik.values); formik.handleSubmit(); }}>Done</SecondaryButton>}
              breadcrumbItems={breadcrumbItems}
              tabTitles={["General", "Progressions", "Tutor"]}
              tabChildren={[
                <div>
                  <MetaDataForm formik={formik}></MetaDataForm>
                  <GeneralForm formik={formik}></GeneralForm>
                </div>,
                <ProgressionForm formik={formik}></ProgressionForm>,
                <TutorContentList formik={formik} path={`${path}/${exercise.id}/item`}></TutorContentList>
              ]}
            ></DashboardPageTabView>
          </Route>
        </Switch>
      )}
    </Formik>
  );
}

function MetaDataForm({ formik }) {
  return <FormCard title="Meta Information">
    <Field
      component={TextField}
      fullWidth
      variant="outlined"
      label="Exercise Name"
      name="name"
      type="text"
      placeholder="Exercise name"
    ></Field>
    <Field
      component={TextField}
      fullWidth
      variant="outlined"
      label="Default Progression ID (TODO)"
      name="selectedProgressionId"
      type="text"
      placeholder="Default Progression ID"
    ></Field>
    <MediaItemInput object="media" label="Exercise Video/Image" formik={formik}></MediaItemInput>
    {/* <FileInput label="Exercise Image"></FileInput>
    <FileInput label="Exercise Video"></FileInput> */}
  </FormCard>;
}

function GeneralForm({ formik }) {
  return (
    <FormCard title="Exercise behavior">
      {/* Whats the best type here? // define values and export in other file */}
      <Field component={Select} fullWidth variant="outlined" label="Starting Exercise State" name="type">
        <ListItem value={BASE_EXERCISE_TYPE}>Basic Exercise</ListItem>
        <ListItem value="repChallenge">Repetition Challenge</ListItem>
        <ListItem value="durationChallenge">Duration Challenge</ListItem>
        <ListItem value="dropsetExercise">Pyramid/Dropset Exercise</ListItem>
      </Field>
      <Field component={TextField} fullWidth variant="outlined" label="Exercise Sets" name="sets" type="number" placeholder="Sets"></Field>
      <div className="flex space-x-4">
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Rest"
          name="exerciseTimings.rest"
          type="number"
          placeholder="Rest in seconds"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Rest After Last Set"
          name="exerciseTimings.lastRest"
          type="number"
          placeholder="Last Rest in seconds"
        ></Field>
      </div>
      <div className="flex space-x-4">
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Hold Down"
          name="exerciseTimings.holdDown"
          type="number"
          placeholder="Hold Down in seconds"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Positive"
          name="exerciseTimings.positives"
          type="number"
          placeholder="Positive in seconds"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Hold Top"
          name="exerciseTimings.hold"
          type="number"
          placeholder="Hold Top in seconds"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Negative"
          name="exerciseTimings.negatives"
          type="number"
          placeholder="Negative in seconds"
        ></Field>
      </div>
      <div className="flex space-x-4">
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Intensity"
          name="rpe"
          type="text"
          placeholder="Intensity"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Intensity Value"
          name="rpeValue"
          type="number"
          placeholder="Intensity"
        ></Field>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          label="Rest"
          name="repRange"
          type="text"
          placeholder="Rep Range"
        ></Field>
      </div>
      <Field component={Select} type="checkbox" fullWidth variant="outlined" label="Starting Exercise State" name="startingExerciseStatus">
        <ListItem value={HOLD_DOWN}>Hold Down</ListItem>
        <ListItem value={POSITIVE}>Positive</ListItem>
        <ListItem value={HOLD}>Hold</ListItem>
        <ListItem value={NEGATIVE}>Negative</ListItem>
      </Field>
      <FormControlLabel
        label="Is Weighted Exercise"
        control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="isWeighted"></Field>}
      ></FormControlLabel>
      <FormControlLabel
        label="Is Static Exercise"
        control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="isStatic"></Field>}
      ></FormControlLabel>
      {/* TODO: Replace this with below comment */}
      <br></br>
      <FormControlLabel
        label="useRepGoal"
        control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="useRepGoal"></Field>}
      ></FormControlLabel>
      <FormControlLabel
        label="useSecondsGoal"
        control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="useSecondsGoal"></Field>}
      ></FormControlLabel>
      { formik.values.useRepGoal === true && <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Rep Goal"
        name="repGoal"
        type="number"
        placeholder="Rep Goal"
      ></Field> }
      { formik.values.useSecondsGoal === true && <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Goal Value"
        name="secondsGoal"
        type="number"
        placeholder="Seconds Goal (Second or Reps)"
      ></Field>}
      {/* TODO: implement this */}
      {/* <Field component={RadioGroup} type="checkbox" fullWidth variant="outlined" name="goalType">
        <FormControlLabel value="none" control={<Radio />} label="No Exercise Goal" />
        <FormControlLabel value="repGoal" control={<Radio />} label="Use Rep Goal" />
        <FormControlLabel value="timeGoal" control={<Radio />} label="Use Time Goal" />
      </Field>
      {formik.values.goalType === "timeGoal" && <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Goal Value"
        name="secondsGoal"
        type="number"
        placeholder="Seconds Goal (Second or Reps)"
      ></Field>}
      { formik.values.goalType === "repGoal" && <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Goal Value"
        name="repRange"
        type="number"
        placeholder="Rep Goal (Second or Reps)"
      ></Field>}
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Challenge Goal Value"
        name="challengeGoal"
        type="number"
        placeholder="Challenge Value (Second or Reps based on Challenge type)"
      ></Field> */}
    </FormCard>
  );
}

function ProgressionForm({ formik }) {
  const router = useHistory();
  return (
    <FieldArray name="progressions">
      {({ remove, push }) => (
        <div>
          <ExerciseDnDList
            formik={formik}
            name="progressions"
            remove={remove}
            onEdit={item => router.push(`${removeSlashSuffix(router.location.pathname)}/progression/${item.id}`)}
          ></ExerciseDnDList>
          <PrimaryButton
            onClick={() => {
              const prog = initProgression();
              push(prog);
              router.push(`${removeSlashSuffix(router.location.pathname)}/progression/${prog.id}`);
            }}
          >
            Add Progression
          </PrimaryButton>
        </div>
      )}
    </FieldArray>
  );
}
