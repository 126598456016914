import { FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../../../../components/tailwind-components/buttons";
import { BASE_EXERCISE_TYPE, SUPERSET_EXERCISE_TYPE } from "../../../../../exercise-types";
import { POSITIVE } from "../../../../../utils/exercise-states";
import { useBasePath } from "../../../../../utils/get-subdomain";
import { generateUniqueId, removeSlashSuffix } from "../../../../../utils/utils";
import { ExerciseDnDList } from "../../components/dashboard/dnd-list";

export const initExercise = () => {
  return {
    type: BASE_EXERCISE_TYPE,
    id: generateUniqueId(),
    name: undefined,
    media: {
      isExplanation: false
    },
    sets: 1,
    tutor: {
      media: {}
    },
    startingExerciseStatus: POSITIVE,
    isWeighted: false,
    isStatic: false,
    useRepGoal: false,
    useSecondsGoal: false,
    goalType: "none"
  };
};

const initAlternatingExercise = () => {
  return {
    type: SUPERSET_EXERCISE_TYPE,
    id: generateUniqueId(),
    exercises: []
  };
};

export default function ExerciseForm({ formik }) {
  const basePath = useBasePath();
  const router = useHistory();

  return (
    <div>
      <FieldArray name="exercises">
        {({ remove, push }) => (
          <div>
            <ExerciseDnDList
              formik={formik}
              name="exercises"
              remove={remove}
              onEdit={item => router.push(`${removeSlashSuffix(router.location.pathname)}/exercise/${item.id}`)}
            ></ExerciseDnDList>
            <PrimaryButton onClick={() => push(initExercise())}>Add Exercise</PrimaryButton>
            <PrimaryButton
              onClick={() => {
                push(initAlternatingExercise());
              }}
              className="ml-4"
            >
              Add Alternating Exercise Group
            </PrimaryButton>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
