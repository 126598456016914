export function PrimaryButton({ children, className, ...props }) {
  return (
    <button
      type="button"
      {...props}
      className={`${className} text-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      {children}
    </button>
  );
}



export function SecondaryButton({ children, className, ...props }) {
  return (
    <button
      type="button"
      {...props}
      className={`${className} inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      {children}
    </button>
  );
}

export function OutlineButton({ children, className, ...props }) {
  return (
    <button
      type="button"
      {...props}
      className={`${className} inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    >
      {children}
    </button>
  );
}
