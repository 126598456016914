import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { HorizontalContentCard, VerticalContentCard } from "../../../components/content-list/content-list";
import MyContainer from "../../../components/tailwind-components/my-container";
import { fetchPrograms, fetchRoutines } from "../../../db";
import { PROGRAM_TYPE, ROUTINE_TYPE } from "../../../utils/content-types";
import { SchoolContext } from "../school";
import MobileNavbar from "./components/mobile-navbar";
import RoutineCard from "./components/routine-card";
import ResponsiveCard from "./components/ui-components/responsive-card";
import ResponsiveGrid from "./components/ui-components/responsive-grid";
import Row from "./components/ui-components/row";
import SearchBar from "./components/ui-components/searchbar";

const useStyle = makeStyles(theme => ({
  filterBtn: {
    backgroundColor: theme.palette.foreground.default,
    borderRadius: 4,
    marginLeft: 16
  }
}));

const totalCategories = ["Strength Building", "Increase Muscle", "Cardio", "Mobility", "Core", "Legs", "Full Body"];

export default function WorkoutsPage() {
  const school = useContext(SchoolContext);
  const router = useRouteMatch;
  // const routeTypes = router.query.types;
  const routeTypes = undefined;
  const classes = useStyle();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("xs"));

  const [content, setContent] = useState([]);
  const [filteredContent, setFilteredContent] = useState([]);
  let filter = {
    categories: totalCategories,
    types: [ROUTINE_TYPE, PROGRAM_TYPE],
    // types: router.query.types ? routeTypes : ["workouts", "programs"],
    order: "latest"
  };
  const [showFilter, setShowFilter] = useState(false);

  let searchTerm = "";

  useEffect(() => {
    if (school) {
      Promise.all([fetchRoutines(school.id), fetchPrograms(school.id)]).then(res => {
        setContent([...res[0], ...res[1]]);
      });
    }
  }, [school]);

  useEffect(() => {
    filterContent();
  }, [content]);

  // TODO: At initial load the route query is not being loaded which leads to showing all contents before the filter of the route is applied

  function filterContent() {
    // filter
    // TODO: fix filter
    let filteredContent = content.filter(item => {
      return (
        filter.types.includes(item.contentType) &&
        (item.categories?.some(categorie => filter.categories.includes(categorie)) || filter.categories.length === totalCategories.length)
      );
    });

    // search
    filteredContent = filteredContent.filter(item => {
      return item.name?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // order
    if (filter.order == "name") {
      filteredContent.sort((c1, c2) => c1.name.localeCompare(c2.name));
    } else if (filter.order == "latest") {
      filteredContent.sort((c1, c2) => {
        return (c2.createdAt ?? 0) - (c1.createdAt ?? 0);
      });
    }
    setFilteredContent(filteredContent);
  }

  return (
    <React.Fragment>
      <MyContainer className="py-4">
        <div className="flex px-4 pb-4" color="default">
          <SearchBar
            className="flex-1 rounded-m"
            onCancelSearch={() => {
              searchTerm = "";
              filterContent();
            }}
            onChange={value => {
              searchTerm = value;
              filterContent();
            }}
          ></SearchBar>
          <Card className="ml-2 rounded-md">
            <IconButton onClick={() => setShowFilter(true)}>
              <Icon>menu</Icon>
            </IconButton>
          </Card>
        </div>
        <div className="px-4 grid md:grid-cols-3 sm:grid-cols-2 gap-6">
          {filteredContent.map((item, i) =>
            isSmall ? (
              <HorizontalContentCard key={`${item.id}.${i}`} item={item}></HorizontalContentCard>
            ) : (
              <VerticalContentCard key={`${item.id}.${i}`} item={item}></VerticalContentCard>
            )
          )}
        </div>

      </MyContainer>
      {/* <div className="max-w-7xl mx-auto py-4">
      </div> */}
      <FilterDialog
        opened={showFilter}
        filter={filter}
        setFilter={value => {
          filter = value;
          filterContent();
        }}
        close={() => setShowFilter(false)}
      ></FilterDialog>
    </React.Fragment>
  );

  // return (
  //   <div>
  //     <MobileNavbar>
  //       <ResponsiveCard>
  //         <Box p={1}>
  //           <Row>
  //             <SearchBar
  //               onCancelSearch={() => {
  //                 searchTerm = "";
  //                 filterContent();
  //               }}
  //               onChange={value => {
  //                 searchTerm = value;
  //                 filterContent();
  //               }}
  //               style={{ flexGrow: 1 }}
  //             ></SearchBar>
  //             <Box className={classes.filterBtn}>
  //               <IconButton onClick={() => setShowFilter(true)}>
  //                 <Icon>menu</Icon>
  //               </IconButton>
  //             </Box>
  //           </Row>
  //         </Box>
  //         <ResponsiveGrid>
  //           {filteredContent?.map(content => {
  //             return <RoutineCard key={content.id} routine={content}></RoutineCard>;
  //             //   if (content.type === "workouts") {
  //             //     return <RoutineCard key={content.id} routine={content}></RoutineCard>;
  //             //   } else {
  //             //     return <ProgramCard key={content.id} program={content}></ProgramCard>;
  //             //   }
  //           })}
  //         </ResponsiveGrid>
  //       </ResponsiveCard>
  //     </MobileNavbar>
  //     <FilterDialog
  //       opened={showFilter}
  //       filter={filter}
  //       setFilter={value => {
  //         filter = value;
  //         filterContent();
  //       }}
  //       close={() => setShowFilter(false)}
  //     ></FilterDialog>
  //   </div>
  // );
}

function FilterDialog({ opened, close, filter, setFilter }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [order, setOrder] = useState(filter.order);
  const [types, setTypes] = useState(filter.types);
  const [categories, setCategories] = useState(filter.categories);

  function addOrRemove(value) {
    if (types.includes(value)) {
      setTypes(types.filter(t => t !== value));
    } else {
      setTypes([...types, value]);
    }
  }

  return (
    <Dialog
      open={opened}
      PaperProps={{ style: { borderRadius: fullScreen ? 0 : 10 } }}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Search Options</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="h3" color="textSecondary">
            Categories
          </Typography>
        </Box>
        <Grid container>
          {totalCategories.map(categorie => (
            <Box
              paddingX="4px"
              paddingTop="4px"
              onClick={() =>
                categories.includes(categorie)
                  ? setCategories(categories.filter(c => c !== categorie))
                  : setCategories([...categories, categorie])
              }
              key={categorie}
            >
              <Chip
                size="small"
                style={{ cursor: "pointer" }}
                color={categories.includes(categorie) ? "primary" : undefined}
                label={categorie}
              ></Chip>
            </Box>
          ))}
        </Grid>
        <Box height={8}></Box>
        <Divider />
        <Box p={1}>
          <Typography variant="h3" color="textSecondary">
            Type
          </Typography>
        </Box>
        <List>
          <ListItem dense button onClick={() => addOrRemove(ROUTINE_TYPE)}>
            <ListItemIcon>
              <Checkbox edge="start" checked={types.includes(ROUTINE_TYPE)} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary="Workouts" />
          </ListItem>
          <ListItem dense button onClick={() => addOrRemove(PROGRAM_TYPE)}>
            <ListItemIcon>
              <Checkbox edge="start" checked={types.includes(PROGRAM_TYPE)} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary="Programs" />
          </ListItem>
        </List>
        <Divider />
        <Box p={1}>
          <Typography variant="h3" color="textSecondary">
            Order By
          </Typography>
        </Box>
        <Box p={1}>
          <Select style={{ width: "100%" }} value={order} onChange={event => setOrder(event.target.value)}>
            <MenuItem value="latest">Latest</MenuItem>
            <MenuItem value="name">Name A-Z</MenuItem>
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          onClick={() => {
            console.log(order);
            setFilter({
              categories: categories,
              types: types,
              order: order
            });
            close();
          }}
          color="primary"
          autoFocus
        >
          Apply
        </Button>
      </DialogActions>
      {/* </Box> */}
    </Dialog>
  );
}
