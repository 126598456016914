import { useMediaQuery, useTheme } from "@material-ui/core";
import MyCard from "../../pages/school/main/components/ui-components/my-card";

export default function BannerCard({ children, className, isBanner }) {

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

    const trigger = isBanner ?? isLarge;

    return <MyCard className={`${className} ${trigger ? "mt-4" : ""}`} radius={!trigger ? 0 : undefined}>
        {children}
    </MyCard>
}