import { makeStyles, Switch, Typography, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { createContext, useContext, useEffect, useState } from "react";
import DarkModeToggle from "react-dark-mode-toggle";
import { Link, NavLink } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { SecondaryButton } from "../../../../components/tailwind-components/buttons";
import { useBasePath } from "../../../../utils/get-subdomain";
import { getLogo } from "../../../../utils/utils";
import { LoginDialogContext, SchoolContext, ThemeContext, UserTenantContext } from "../../school";
import LoginDialog from "./login-dialog";

const useStyle = makeStyles(theme => ({
  navLink: {
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.lighter,
      color: theme.palette.text.primary
    }
  },
  navLinkActive: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  }
}));

export default function SchoolNavBar({ children, className, leading, ...props }) {
  const theme = useTheme();
  const classes = useStyle();

  // const [open, setOpen] = useState(false);
  const setOpen = useContext(LoginDialogContext);
  const user = useContext(UserTenantContext);
  const school = useContext(SchoolContext);
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);
  const basePath = useBasePath();

  function toggleDarkMode(value) {
    if (typeof value === "boolean") {
      localStorage.setItem("flutter.USE_DARK_MODE", value);
      setDarkTheme(value);
    }
  }

  return (
    <React.Fragment>
      <nav className={`${className} w-full shadow-md fixed z-30`} style={{ backgroundColor: theme.palette.background.paper }}>
        <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {leading}
            <Link to={`/${basePath}`} className="flex-grow flex items-center">
              {school?.config?.icon && <img className="sm:h-8 h-7" src={getLogo(school, isDarkTheme)} alt="Logo" />}
              {/* <img className="h-8 w-8" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" /> */}
              <Typography className="sm:ml-4 ml-2 max-line-1" variant="h2">
                {school?.config?.name}
              </Typography>
            </Link>

            {/* <DarkModeToggle checked={isDarkTheme} speed={1} onChange={value => toggleDarkMode(value)} size={50} /> */}

            <div className="hidden sm:block">
              <div className="ml-4 flex items-baseline space-x-4">
                <NavLink
                  exact
                  activeStyle={{ color: "red" }}
                  activeClassName={classes.navLinkActive}
                  className={classNames(classes.navLink, "px-3 py-2 rounded-md")}
                  to={`/${basePath}`}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    Home
                  </Typography>
                </NavLink>

                <NavLink
                  activeStyle={{ color: "red" }}
                  activeClassName={classes.navLinkActive}
                  className={classNames(classes.navLink, "px-3 py-2 rounded-md")}
                  to={`/${basePath}workouts`}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    Workouts
                  </Typography>
                </NavLink>

                {/* <NavLink activeStyle={{ color: "red" }} activeClassName={ classes.navLinkActive } className={classNames(classes.navLink, "px-3 py-2 rounded-md")} to={`${id}shop`}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Shop
                  </Typography>
                </NavLink> */}

                <NavLink
                  activeStyle={{ color: "red" }}
                  activeClassName={classes.navLinkActive}
                  className={classNames(classes.navLink, "px-3 py-2 rounded-md")}
                  to={`/${basePath}profile`}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    Profile
                  </Typography>
                </NavLink>

                {!user && (
                  <SecondaryButton className="ml-2" onClick={() => setOpen(true)}>
                    Login
                  </SecondaryButton>
                )}

                {user?.isAdmin === true && (
                  <Link to={`/${basePath}admin`}>
                    <SecondaryButton className="ml-2">Admin</SecondaryButton>
                  </Link>
                )}

                {/* {user ? (
                  <div className="ml-4">
                    <button
                      className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      id="user-menu"
                      aria-haspopup="true"
                    >
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </button>
                  </div>
                ) : (
                  <SecondaryButton className="ml-4" onClick={() => setOpen(true)}>
                    Login
                  </SecondaryButton>
                )} */}
              </div>
            </div>
            <DarkModeSwitch
              checked={!isDarkTheme}
              onChange={value => toggleDarkMode(!value)}
              moonColor="#111827"
              sunColor="#fff"
              className="ml-4"
            ></DarkModeSwitch>
          </div>
        </div>
      </nav>
      <div className="h-16"></div>

      {children}
    </React.Fragment>
  );
}
