import { InputAdornment, ListItem, makeStyles, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { initializeSchoolApp, listenOwnerAuth, login, loginOwner } from "../auth";
import MyButton from "../components/my-button";
import * as db from "../db";
import { createSchool } from "../db";

import * as firebase from "firebase";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  main: {
    padding: "5rem 0",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    margin: 0,
    lineHeight: 1.15,
    fontSize: "4rem"
  },
  description: {
    textAlign: "center",
    lineHeight: 1.5,
    fontSize: "1.5rem"
  }
}));

export default function GeneratePage() {
  const classes = useStyles();
  const router = useHistory();

  const [section, setSection] = useState(0);

  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = listenOwnerAuth(user => setUser(user));

    return () => {
      if (unsubscribe && typeof unsubscribe === "function") unsubscribe();
    };
  });

  function next() {
    if (section < 2) {
      setSection(section + 1);
    }
  }

  function prev() {
    if (section > 0) {
      setSection(section - 1);
    }
  }

  async function save(values, setSubmitting, setFieldError) {
    setError(undefined);
    console.log(values);
    try {
      // Create School & Accounts
      const school = await createSchool({
        email: `admin@${values.id}.com`,
        billingPlan: "business",
        schoolName: values.name,
        schoolId: values.id,
        password: `access-${values.id}`,
        passwordConfirm: `access-${values.id}`,
        username: values.name,
        userName: values.name,
      });
      console.log("DONE; GENERATE CONTENT");
      await populateSchoolData(values.id, values.name, values.avatar);
      // Sign in to created accounts
      initializeSchoolApp(school);
      login(values.email, `access-${values.id}`);
      //   loginOwner(values.email, values.password);
    //   router.push(`/${values.schoolId?.replace(/\s+/g, "-").toLowerCase()}`);
    } catch (error) {
      console.log("SchoolCreation Error", error.message, error.code);
      if (error.code === "already-exists") {
        // TODO: Handle other error cases (email in use,...)
        setFieldError("schoolId", "This School Subdomain is already in use.");
      } else {
        setError(error.message);
      }
      setSubmitting(false);
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 ">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
            <Link to="/"></Link>
            <Link to="/" className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <h2 className="text-gray-900 text-xl leading-5 font-medium">Create School</h2>
              </div>
            </Link>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-12">
        <Formik
          initialValues={{
            name: "",
            id: "",
            avatar: ""
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => save(values, setSubmitting, setFieldError)}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            id: Yup.string().required("Required"),
            avatar: Yup.string().required("Required")
          })}
        >
          {formik => (
            <div className="space-y-2">
              <Field component={TextField} variant="outlined" fullWidth name="name" label="Name" type="text"></Field>
              <Field component={TextField} variant="outlined" fullWidth name="id" label="id" type="text"></Field>
              <Field component={TextField} variant="outlined" fullWidth name="avatar" label="avatar" type="text"></Field>

              <MyButton onClick={formik.handleSubmit} className="bg-indigo-500">
                Create School
              </MyButton>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

function populateSchoolData(id, school_name, avatar) {
  const schoolId = id;

  const avatarUrl = avatar;
  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Ficon.png?alt=media&token=a8ab597e-d508-42da-8069-9d3023922e26";
  const logoDarkUrl = null;

  const banner1 =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Fjump.jpg?alt=media&token=6979e306-9f6d-49dc-86b7-f6b6a0361a7c";
  const banner2 =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Frope2.jpg?alt=media&token=e6690e7b-0f37-49fc-974e-0404894468b1";
  const banner3 =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Frope2.jpg?alt=media&token=e6690e7b-0f37-49fc-974e-0404894468b1";

  // School Config
  const name = school_name;
  const desc = "With over 10 years of experience as a personal coach and athlete, I have created this platform to help you achieve your goals. Whether it's my 1-on-1 coaching or my workout programs, there is the perfect solution for everybody. Profit from my workout app, that guides you through the workout with audio and visual instructions.";

  const insta = "https://www.instagram.com/asap_jxnas/";
  const youtube = "https://www.youtube.com/";
  const facebook = "https://www.facebook.com/";

  const youtubeVideoUrl = "https://youtu.be/cfetualiJqs";
  const youttubeTutorId = "cfetualiJqs";
  const youtubeTumbnail = banner2;
  const youtubeTitle = null;

  // Routines
  const pushUpStockVideo =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400";

  const bannerWorkoutA = banner1;
  const bannerWorkoutB = banner2;
  const bannerWorkoutHiit = banner3;
  const bannerHomeWorkout = banner2;

  // PROGRAMS
  const beginnerProgramBannerA =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Fball.jpg?alt=media&token=4d1cf55d-5aee-409d-a72f-0095ac22fc87";
  const beginnerProgramName = "Beginner Program";
  const beginnerProgramDesc = "Beginner program the build muscle and increase strength. Start now to achieve your goals.";
  const beginnerProgramReq = "- 5 Pull ups\n- 8 Chin ups\n- 15 Push ups\n- 8-10 Dips";
  const beginnerProgramEquip = "";

  const advancedProgramBannerA =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Fpushup.jpg?alt=media&token=3281e90d-c5e5-4515-b88b-1ca77eecfd3c";
  const advancedProgramName = "Advanced Program";
  const advancedProgramDesc = "Advanced program the build muscle and increase strength. Start now to achieve your goals.";
  const advancedProgramReq = "- 10 Pull ups\n- 12 Chin ups\n- 30 Push ups\n- 20-25 Dips";

  const expertProgramBannerA =
    "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/training_hub%2Fjump.jpg?alt=media&token=6979e306-9f6d-49dc-86b7-f6b6a0361a7c";
  const expertProgramName = "Expert Program";
  const expertProgramDesc = "Expert program the build muscle and increase strength. Start now to achieve your goals.";
  const expertProgramReq = "- 20 Pull ups\n- 25 Chin ups\n- 50-100 Push ups\n- 40-50 Dips";
  const expertProgramEquip = "";

  const contentTitle = "Are you ready to start real training?";
  const contentBody =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ";

  firebase.default.firestore().collection("Schools").doc(schoolId).set({ config: schoolConfig() }, { merge: true });
  firebase.default
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .doc(createFullBodyWorkoutA().id)
    .set(createFullBodyWorkoutA());
  firebase.default
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .doc(createFullBodyWorkoutB().id)
    .set(createFullBodyWorkoutB());
  firebase.default
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .doc(createHIITWorkout().id)
    .set(createHIITWorkout());
  firebase.default
    .firestore()
    .collection("Schools")
    .doc(schoolId)
    .collection("Routines")
    .doc(createHomeWorkout().id)
    .set(createHomeWorkout());
  db.editProgram(schoolId, createBeginnerProgram());
  db.editProgram(schoolId, createAdvancedProgram());
  db.editProgram(schoolId, createExpertProgram());
  addReview(createBeginnerProgram().id);
  addReview(createAdvancedProgram().id);
  addReview(createExpertProgram().id);

  function schoolConfig() {
    return {
      instagram: insta,
      bannerItems: [
        {
          type: "BANNER_TYPE_PROGRAM",
          link: "JkoGPVWpe7ReLoKg7YJO",
          media: {
            imageUrl: banner1
          },
          description: "NEW: Advanced Program"
        }
        // {
        //   "title": youtubeTitle,
        //   "media": {
        //     "imageUrl": youtubeTumbnail
        //   },
        //   "link": youtubeVideoUrl,
        //   "type": "BANNER_TYPE_LINK",
        //   "description": "Check out my new YouTube Video"
        // }
      ],
      icon: logoUrl,
      iconDark: logoDarkUrl,
      name: name,
      facebook: facebook,
      avatar: avatarUrl,
      youtube: youtube,
      description: desc,
      contentItems: [
        {
          id: "qelIIb4rERidcfSkT83f",
          title: "My Programs",
          type: "PROGRAM",
          contentIds: ["NCmepxY4O8IrYwr6iYPj", "4BwNS0KvJ37q3mLy7vMx", "JkoGPVWpe7ReLoKg7YJO"]
        },
        {
          title: "Free Workouts",
          type: "ROUTINE",
          id: "LDF4OTagYplW60IWpdBz",
          contentIds: ["QOpvD1JR1HrQpzsR5frC", "woHMA9ZbQhMgxJcQuKkZ", "PsHTMD0hqWpO7jX8hF1l", "jIyn9ePjZHck04Wqlozc"]
        },
        {
          type: "TEXT",
          body: contentBody,
          title: contentTitle,
          id: "62eoz1EPw2rUmwBP492K",
          subtitle: ""
        }
      ]
    };
  }

  function createBeginnerProgram() {
    return {
      id: "NCmepxY4O8IrYwr6iYPj",
      totalRatings: 5,
      avrgRating: 4.6,
      sumRating: 23,
      createdAt: 1608929514542,
      media: {
        isExplanation: true,
        youtubeId: youttubeTutorId,
        isYoutubeVideo: true,
        imageUrl: beginnerProgramBannerA,
        isVideo: true
      },
      price: 5999,
      contentType: "PROGRAM",
      description: beginnerProgramDesc,
      phases: [
        {
          name: "Level 1",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          name: "Level 2",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ],
          name: "Level 3"
        }
      ],
      name: beginnerProgramName,
      contentItems: [
        {
          title: "Requirements",
          body: beginnerProgramReq,
          id: "wHKp6jIHhWOrUhXpr4Ri",
          type: "TEXT"
        },
        {
          type: "TEXT",
          body: beginnerProgramReq,
          title: "Equipment Necessary",
          id: "AZ3Umw5z4Caq3rYe6Cjq"
        }
      ],
      editedAt: 1609018334632,
      availability: "public",
      subtitle: "Workout Program",
      sections: [
        {
          id: "3GAqS7EkpOST2NceMLTE",
          contentItems: [
            {
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              type: "SCHEDULE_TYPE",
              title: "Level 1",
              id: "UsCeHQtudTckBs18zOJ7",
              phase: 0
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              id: "STgBuV6O3FA2mPcPxNfS"
            }
          ],
          name: "Level 1",
          isFree: true
        },
        {
          contentItems: [
            {
              phase: 1,
              type: "SCHEDULE_TYPE",
              title: "Level 2",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "8cTw0tvR9nD0Rwerv1Eq"
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "TM9ymUg6SGdYCbJcD5pO",
              type: "TEXT"
            }
          ],
          id: "upUiN4EDHnw95xDNwZXp",
          name: "Level 2"
        },
        {
          id: "fEO6haFO470V6HS0JyP0",
          name: "Level 3",
          contentItems: [
            {
              phase: 2,
              type: "SCHEDULE_TYPE",
              title: "Level 3",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "jwiH7sjBJjQgO0Mg7tB2"
            },
            {
              type: "TEXT",
              title: "Schedule Explanation",
              id: "aNwCbti1O2oonZw2zJih",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore"
            }
          ]
        },
        {
          name: "Regeneration",
          isFree: true,
          id: "ZSPTRodU7FyJSnmUm5io",
          media: {
            isVideo: true,
            isExplanation: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          },
          contentItems: [
            {
              type: "TEXT",
              subtitle: "Additional Information",
              title: "Regeneration",
              body:
                "This is a demo section, here you can have explanations to any possible topic. The following is some dummy text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "B9KtXEKbpD1XUg5hGQhm"
            },
            {
              body: "This is another information item about some additional information",
              title: "Other Information",
              id: "gxNgyfANZGzYl0IJjXOb",
              type: "TEXT"
            }
          ]
        },
        {
          id: "BKj6p1kUOn5AMankUuEQ",
          contentItems: [
            {
              subtitle: "Additional Information",
              id: "7hI56rpTocX8jswGxASm",
              body:
                "This is an example for anther information section. Write something useful here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              title: "Nutrition"
            }
          ],
          name: "Nutrition",
          media: {
            videoUrl: "",
            isExplanation: true,
            isVideo: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          }
        }
      ]
    };
  }

  function createAdvancedProgram() {
    return {
      id: "4BwNS0KvJ37q3mLy7vMx",
      totalRatings: 5,
      avrgRating: 4.6,
      sumRating: 23,
      createdAt: 1608929514542,
      media: {
        isExplanation: true,
        youtubeId: youttubeTutorId,
        isYoutubeVideo: true,
        imageUrl: advancedProgramBannerA,
        isVideo: true
      },
      price: 5999,
      contentType: "PROGRAM",
      description: advancedProgramDesc,
      phases: [
        {
          name: "Level 1",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          name: "Level 2",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ],
          name: "Level 3"
        }
      ],
      name: advancedProgramName,
      contentItems: [
        {
          title: "Requirements",
          body: advancedProgramReq,
          id: "wHKp6jIHhWOrUhXpr4Ri",
          type: "TEXT"
        },
        {
          type: "TEXT",
          body: advancedProgramReq,
          title: "Equipment Necessary",
          id: "AZ3Umw5z4Caq3rYe6Cjq"
        }
      ],
      editedAt: 1609018334632,
      availability: "public",
      subtitle: "Workout Program",
      sections: [
        {
          id: "3GAqS7EkpOST2NceMLTE",
          contentItems: [
            {
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              type: "SCHEDULE_TYPE",
              title: "Level 1",
              id: "UsCeHQtudTckBs18zOJ7",
              phase: 0
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              id: "STgBuV6O3FA2mPcPxNfS"
            }
          ],
          name: "Level 1",
          isFree: true
        },
        {
          contentItems: [
            {
              phase: 1,
              type: "SCHEDULE_TYPE",
              title: "Level 2",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "8cTw0tvR9nD0Rwerv1Eq"
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "TM9ymUg6SGdYCbJcD5pO",
              type: "TEXT"
            }
          ],
          id: "upUiN4EDHnw95xDNwZXp",
          name: "Level 2"
        },
        {
          id: "fEO6haFO470V6HS0JyP0",
          name: "Level 3",
          contentItems: [
            {
              phase: 2,
              type: "SCHEDULE_TYPE",
              title: "Level 3",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "jwiH7sjBJjQgO0Mg7tB2"
            },
            {
              type: "TEXT",
              title: "Schedule Explanation",
              id: "aNwCbti1O2oonZw2zJih",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore"
            }
          ]
        },
        {
          name: "Regeneration",
          isFree: true,
          id: "ZSPTRodU7FyJSnmUm5io",
          media: {
            isVideo: true,
            isExplanation: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          },
          contentItems: [
            {
              type: "TEXT",
              subtitle: "Additional Information",
              title: "Regeneration",
              body:
                "This is a demo section, here you can have explanations to any possible topic. The following is some dummy text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "B9KtXEKbpD1XUg5hGQhm"
            },
            {
              body: "This is another information item about some additional information",
              title: "Other Information",
              id: "gxNgyfANZGzYl0IJjXOb",
              type: "TEXT"
            }
          ]
        },
        {
          id: "BKj6p1kUOn5AMankUuEQ",
          contentItems: [
            {
              subtitle: "Additional Information",
              id: "7hI56rpTocX8jswGxASm",
              body:
                "This is an example for anther information section. Write something useful here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              title: "Nutrition"
            }
          ],
          name: "Nutrition",
          media: {
            videoUrl: "",
            isExplanation: true,
            isVideo: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          }
        }
      ]
    };
  }

  function createExpertProgram() {
    return {
      id: "JkoGPVWpe7ReLoKg7YJO",
      totalRatings: 5,
      avrgRating: 4.6,
      sumRating: 23,
      createdAt: 1608929514542,
      media: {
        isExplanation: true,
        youtubeId: youttubeTutorId,
        isYoutubeVideo: true,
        imageUrl: expertProgramBannerA,
        isVideo: true
      },
      price: 5999,
      contentType: "PROGRAM",
      description: expertProgramDesc,
      phases: [
        {
          name: "Level 1",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          name: "Level 2",
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ]
        },
        {
          schedule: [
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            "QOpvD1JR1HrQpzsR5frC",
            null,
            "woHMA9ZbQhMgxJcQuKkZ",
            null,
            null
          ],
          name: "Level 3"
        }
      ],
      name: expertProgramName,
      contentItems: [
        {
          title: "Requirements",
          body: expertProgramReq,
          id: "wHKp6jIHhWOrUhXpr4Ri",
          type: "TEXT"
        },
        {
          type: "TEXT",
          body: expertProgramReq,
          title: "Equipment Necessary",
          id: "AZ3Umw5z4Caq3rYe6Cjq"
        }
      ],
      editedAt: 1609018334632,
      availability: "public",
      subtitle: "Workout Program",
      sections: [
        {
          id: "3GAqS7EkpOST2NceMLTE",
          contentItems: [
            {
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              type: "SCHEDULE_TYPE",
              title: "Level 1",
              id: "UsCeHQtudTckBs18zOJ7",
              phase: 0
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              id: "STgBuV6O3FA2mPcPxNfS"
            }
          ],
          name: "Level 1",
          isFree: true
        },
        {
          contentItems: [
            {
              phase: 1,
              type: "SCHEDULE_TYPE",
              title: "Level 2",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "8cTw0tvR9nD0Rwerv1Eq"
            },
            {
              title: "Schedule Explanation",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "TM9ymUg6SGdYCbJcD5pO",
              type: "TEXT"
            }
          ],
          id: "upUiN4EDHnw95xDNwZXp",
          name: "Level 2"
        },
        {
          id: "fEO6haFO470V6HS0JyP0",
          name: "Level 3",
          contentItems: [
            {
              phase: 2,
              type: "SCHEDULE_TYPE",
              title: "Level 3",
              schedule: [
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                "QOpvD1JR1HrQpzsR5frC",
                null,
                "woHMA9ZbQhMgxJcQuKkZ",
                null,
                null
              ],
              id: "jwiH7sjBJjQgO0Mg7tB2"
            },
            {
              type: "TEXT",
              title: "Schedule Explanation",
              id: "aNwCbti1O2oonZw2zJih",
              body:
                "Write something about this schedule here. This is optional. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore"
            }
          ]
        },
        {
          name: "Regeneration",
          isFree: true,
          id: "ZSPTRodU7FyJSnmUm5io",
          media: {
            isVideo: true,
            isExplanation: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          },
          contentItems: [
            {
              type: "TEXT",
              subtitle: "Additional Information",
              title: "Regeneration",
              body:
                "This is a demo section, here you can have explanations to any possible topic. The following is some dummy text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              id: "B9KtXEKbpD1XUg5hGQhm"
            },
            {
              body: "This is another information item about some additional information",
              title: "Other Information",
              id: "gxNgyfANZGzYl0IJjXOb",
              type: "TEXT"
            }
          ]
        },
        {
          id: "BKj6p1kUOn5AMankUuEQ",
          contentItems: [
            {
              subtitle: "Additional Information",
              id: "7hI56rpTocX8jswGxASm",
              body:
                "This is an example for anther information section. Write something useful here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
              type: "TEXT",
              title: "Nutrition"
            }
          ],
          name: "Nutrition",
          media: {
            videoUrl: "",
            isExplanation: true,
            isVideo: true,
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true
          }
        }
      ]
    };
  }

  function createHIITWorkout() {
    return {
      description: "Short and efficient H.I.I.T Workout for fatloss and general fitness.",
      duration: "15 min.",
      rounds: 4,
      thumbnail: {
        imageUrl: bannerWorkoutHiit
      },
      availability: "public",
      id: "jIyn9ePjZHck04Wqlozc",
      type: "circuit",
      exercises: [
        {
          useExerciseGoals: true,
          useRepGoal: true,
          goalType: "timeGoal",
          exerciseVariation: "basic-exercise",
          id: "hoWjdYXGiDhNvhCl7Kk4",
          exerciseTimings: {
            rest: 15
          },
          test: "timeGoal",
          name: "Jumping Jacks",
          secondsGoal: 30,
          tutor: {},
          goal: 30,
          media: {
            videoUrl: pushUpStockVideo,
            isVideo: true
          },
          type: "BASE_EXERCISE",
          sets: 4,
          useSecondsGoal: true
        },
        {
          id: "PHq7KlEFYmmnokg63uee",
          test: "timeGoal",
          useRepGoal: true,
          goal: 30,
          type: "BASE_EXERCISE",
          exerciseVariation: "basic-exercise",
          media: {
            isVideo: true,
            videoUrl: pushUpStockVideo
          },
          tutor: {},
          sets: 4,
          useSecondsGoal: true,
          name: "Plank",
          isStatic: true,
          secondsGoal: 30,
          useExerciseGoals: true,
          goalType: "timeGoal",
          exerciseTimings: {
            rest: 15
          }
        },
        {
          exerciseTimings: {
            rest: 15
          },
          id: "au5wKsUtvqY3ldcmJtZb",
          secondsGoal: 30,
          useExerciseGoals: true,
          sets: 4,
          useSecondsGoal: true,
          type: "BASE_EXERCISE",
          goalType: "timeGoal",
          name: "Burpees",
          tutor: {},
          media: {
            videoUrl: pushUpStockVideo,
            isVideo: true
          }
        }
      ],
      contentType: "ROUTINE",
      subtitle: "Circuit Workout",
      isCircuit: true,
      name: "H.I.I.T Workout"
    };
  }

  function createFullBodyWorkoutA() {
    return {
      description: "Full body workout part A focuses on strength and muscle building. Hit every muscle group in 50 min.",
      name: "Full Body Workout A",
      type: "sets",
      duration: "50 min.",
      subtitle: "Sets and Reps Workout",
      id: "QOpvD1JR1HrQpzsR5frC",
      contentItems: [
        {
          type: "TEXT",
          subtitle: "Explanation",
          body:
            "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\n- Straight arms\n- Full range of motion\n- Some other information",
          title: "Exercise From",
          id: "pYvtN0635xwPEwhyRnA2"
        }
      ],
      thumbnail: {
        imageUrl: bannerWorkoutA
      },
      availability: "public",
      contentType: "ROUTINE",
      exercises: [
        {
          contentItems: [
            {
              type: "TEXT",
              title: "Exercise From",
              subtitle: "Explanation",
              id: "bCfvZeWzpWgB8BDJnaJ2",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            }
          ],
          name: "Pull ups",
          startingExerciseStatus: "Positive",
          selectedProgressionId: "dEw7NjueheOAhlnzV0g5",
          rpe: "90%",
          type: "BASE_EXERCISE",
          tutor: {
            contentItems: [
              {
                type: "TEXT",
                subtitle: "Explanation",
                id: "bCfvZeWzpWgB8BDJnaJ2",
                title: "Exercise From",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
              }
            ],
            media: {
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true,
              isExplanation: true,
              isVideo: true
            },
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true,
            isVideo: true,
            isExplanation: true
          },
          id: "W5iCcX1RF7jZBywBb8JK",
          repRange: "8 - 15 Reps",
          media: {
            videoUrl: pushUpStockVideo,
            isVideo: true
          },
          exerciseVariation: "basic-exercise",
          sets: 4,
          exerciseTimings: {
            hold: 1,
            negatives: 1,
            rest: 90,
            positives: 1
          },
          progressions: [
            {
              name: "Negative Pull ups",
              id: "80RVokygBhSGSXbH4jFO",
              contentItems: [
                {
                  title: "Progression Information",
                  type: "TEXT",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "7wzO6hco9ZQssPVxFLms"
                }
              ],
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "7wzO6hco9ZQssPVxFLms",
                    title: "Progression Information"
                  }
                ]
              }
            },
            {
              isDefault: true,
              id: "dEw7NjueheOAhlnzV0g5",
              contentItems: [
                {
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "GAC3nPGiYvP5d4ZEqhYL",
                  type: "TEXT"
                }
              ],
              name: "Pull ups",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true,
                  isExplanation: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "GAC3nPGiYvP5d4ZEqhYL"
                  }
                ]
              }
            },
            {
              tutor: {
                contentItems: [
                  {
                    id: "sU6beJzauX5pGiFk6Lou",
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional."
                  }
                ],
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                }
              },
              name: "Archer Pull ups",
              contentItems: [
                {
                  id: "sU6beJzauX5pGiFk6Lou",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "Lbdpuz0tsIzokSLCMFiw"
            },
            {
              name: "Weighted Pull ups",
              id: "lfbVqzSWtgydETHlxxY5",
              isWeighted: true,
              contentItems: [
                {
                  type: "TEXT",
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "D3Rwlhc7HvWrP21Kvqf1"
                }
              ],
              tutor: {
                media: {
                  isVideo: true,
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "D3Rwlhc7HvWrP21Kvqf1",
                    type: "TEXT"
                  }
                ]
              }
            }
          ]
        },
        {
          tutor: {
            contentItems: [
              {
                subtitle: "Explanation",
                id: "Qddxz96opeNDsIWyzAR2",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                type: "TEXT",
                title: "Exercise From"
              }
            ],
            media: {
              isExplanation: true,
              isVideo: true,
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true
            }
          },
          type: "BASE_EXERCISE",
          startingExerciseStatus: "Negative",
          sets: 4,
          exerciseVariation: "basic-exercise",
          name: "Dips",
          repRange: "8 - 15 RM",
          exerciseTimings: {
            rest: 90,
            negatives: 1,
            positives: 1
          },
          progressions: [
            {
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "3D7QjS1vjstdrf5Okl8r",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "7in34U2cPx5zxK42QcrG",
              name: "Negative Dips",
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "3D7QjS1vjstdrf5Okl8r"
                  }
                ]
              },
              isWeighted: false
            },
            {
              id: "ZvKkBWVgDpcixlUjxMEt",
              isDefault: true,
              name: "Dips",
              tutor: {
                contentItems: [
                  {
                    title: "Progression Information",
                    id: "lB9GZWlPszCPZXhlM27U",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT"
                  }
                ],
                media: {
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true
                }
              },
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "lB9GZWlPszCPZXhlM27U",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ]
            },
            {
              isWeighted: true,
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "PV3bUioiZaIvlCxvsZml",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ],
              id: "ohDzGhxuGmHdrywM6GAt",
              name: "Weighted Dips",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "PV3bUioiZaIvlCxvsZml"
                  }
                ]
              }
            }
          ],
          id: "zPdK3HRHJ59jSlJuYQfj",
          rpe: "90%",
          selectedProgressionId: "ZvKkBWVgDpcixlUjxMEt",
          media: {
            videoUrl:
              "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
            isVideo: true
          },
          contentItems: [
            {
              id: "Qddxz96opeNDsIWyzAR2",
              type: "TEXT",
              title: "Exercise From",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
              subtitle: "Explanation"
            }
          ]
        },
        {
          name: "Lower Body Block",
          exercises: [
            {
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              type: "BASE_EXERCISE",
              sets: 4,
              tutor: {},
              name: "Squats",
              id: "GgtOkQp7pBqJPoOd7dpN",
              exerciseVariation: "basic-exercise"
            },
            {
              name: "Lunges",
              tutor: {},
              type: "BASE_EXERCISE",
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              id: "HIhfG5h6ecd2K4sFaiYY",
              sets: 4
            }
          ],
          id: "zLSYjqTEv7ENnlWZoSe2",
          type: "SUPERSET_EXERCISE"
        }
      ]
    };
  }

  function createFullBodyWorkoutB() {
    return {
      description: "Full body workout part B focuses on strength and muscle building. Hit every muscle group in 50 min.",
      name: "Full Body Workout B",
      type: "sets",
      duration: "50 min.",
      subtitle: "Sets and Reps Workout",
      id: "woHMA9ZbQhMgxJcQuKkZ",
      contentItems: [
        {
          type: "TEXT",
          subtitle: "Explanation",
          body:
            "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\n- Straight arms\n- Full range of motion\n- Some other information",
          title: "Exercise From",
          id: "pYvtN0635xwPEwhyRnA2"
        }
      ],
      thumbnail: {
        imageUrl: bannerWorkoutB
      },
      availability: "public",
      contentType: "ROUTINE",
      exercises: [
        {
          contentItems: [
            {
              type: "TEXT",
              title: "Exercise From",
              subtitle: "Explanation",
              id: "bCfvZeWzpWgB8BDJnaJ2",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            }
          ],
          name: "Pull ups",
          startingExerciseStatus: "Positive",
          selectedProgressionId: "dEw7NjueheOAhlnzV0g5",
          rpe: "90%",
          type: "BASE_EXERCISE",
          tutor: {
            contentItems: [
              {
                type: "TEXT",
                subtitle: "Explanation",
                id: "bCfvZeWzpWgB8BDJnaJ2",
                title: "Exercise From",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
              }
            ],
            media: {
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true,
              isExplanation: true,
              isVideo: true
            },
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true,
            isVideo: true,
            isExplanation: true
          },
          id: "W5iCcX1RF7jZBywBb8JK",
          repRange: "8 - 15 Reps",
          media: {
            videoUrl: pushUpStockVideo,
            isVideo: true
          },
          exerciseVariation: "basic-exercise",
          sets: 4,
          exerciseTimings: {
            hold: 1,
            negatives: 1,
            rest: 90,
            positives: 1
          },
          progressions: [
            {
              name: "Negative Pull ups",
              id: "80RVokygBhSGSXbH4jFO",
              contentItems: [
                {
                  title: "Progression Information",
                  type: "TEXT",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "7wzO6hco9ZQssPVxFLms"
                }
              ],
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "7wzO6hco9ZQssPVxFLms",
                    title: "Progression Information"
                  }
                ]
              }
            },
            {
              isDefault: true,
              id: "dEw7NjueheOAhlnzV0g5",
              contentItems: [
                {
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "GAC3nPGiYvP5d4ZEqhYL",
                  type: "TEXT"
                }
              ],
              name: "Pull ups",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true,
                  isExplanation: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "GAC3nPGiYvP5d4ZEqhYL"
                  }
                ]
              }
            },
            {
              tutor: {
                contentItems: [
                  {
                    id: "sU6beJzauX5pGiFk6Lou",
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional."
                  }
                ],
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                }
              },
              name: "Archer Pull ups",
              contentItems: [
                {
                  id: "sU6beJzauX5pGiFk6Lou",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "Lbdpuz0tsIzokSLCMFiw"
            },
            {
              name: "Weighted Pull ups",
              id: "lfbVqzSWtgydETHlxxY5",
              isWeighted: true,
              contentItems: [
                {
                  type: "TEXT",
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "D3Rwlhc7HvWrP21Kvqf1"
                }
              ],
              tutor: {
                media: {
                  isVideo: true,
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "D3Rwlhc7HvWrP21Kvqf1",
                    type: "TEXT"
                  }
                ]
              }
            }
          ]
        },
        {
          tutor: {
            contentItems: [
              {
                subtitle: "Explanation",
                id: "Qddxz96opeNDsIWyzAR2",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                type: "TEXT",
                title: "Exercise From"
              }
            ],
            media: {
              isExplanation: true,
              isVideo: true,
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true
            }
          },
          type: "BASE_EXERCISE",
          startingExerciseStatus: "Negative",
          sets: 4,
          exerciseVariation: "basic-exercise",
          name: "Dips",
          repRange: "8 - 15 RM",
          exerciseTimings: {
            rest: 90,
            negatives: 1,
            positives: 1
          },
          progressions: [
            {
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "3D7QjS1vjstdrf5Okl8r",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "7in34U2cPx5zxK42QcrG",
              name: "Negative Dips",
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "3D7QjS1vjstdrf5Okl8r"
                  }
                ]
              },
              isWeighted: false
            },
            {
              id: "ZvKkBWVgDpcixlUjxMEt",
              isDefault: true,
              name: "Dips",
              tutor: {
                contentItems: [
                  {
                    title: "Progression Information",
                    id: "lB9GZWlPszCPZXhlM27U",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT"
                  }
                ],
                media: {
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true
                }
              },
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "lB9GZWlPszCPZXhlM27U",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ]
            },
            {
              isWeighted: true,
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "PV3bUioiZaIvlCxvsZml",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ],
              id: "ohDzGhxuGmHdrywM6GAt",
              name: "Weighted Dips",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "PV3bUioiZaIvlCxvsZml"
                  }
                ]
              }
            }
          ],
          id: "zPdK3HRHJ59jSlJuYQfj",
          rpe: "90%",
          selectedProgressionId: "ZvKkBWVgDpcixlUjxMEt",
          media: {
            videoUrl:
              "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
            isVideo: true
          },
          contentItems: [
            {
              id: "Qddxz96opeNDsIWyzAR2",
              type: "TEXT",
              title: "Exercise From",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
              subtitle: "Explanation"
            }
          ]
        },
        {
          name: "Lower Body Block",
          exercises: [
            {
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              type: "BASE_EXERCISE",
              sets: 4,
              tutor: {},
              name: "Squats",
              id: "GgtOkQp7pBqJPoOd7dpN",
              exerciseVariation: "basic-exercise"
            },
            {
              name: "Lunges",
              tutor: {},
              type: "BASE_EXERCISE",
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              id: "HIhfG5h6ecd2K4sFaiYY",
              sets: 4
            }
          ],
          id: "zLSYjqTEv7ENnlWZoSe2",
          type: "SUPERSET_EXERCISE"
        }
      ]
    };
  }
  function createHomeWorkout() {
    return {
      description: "Home Workout for building muscle and increasing strength at home.",
      name: "Home Workout",
      type: "sets",
      duration: "50 min.",
      subtitle: "Sets and Reps Workout",
      id: "PsHTMD0hqWpO7jX8hF1l",
      contentItems: [
        {
          type: "TEXT",
          subtitle: "Explanation",
          body:
            "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\n- Straight arms\n- Full range of motion\n- Some other information",
          title: "Exercise From",
          id: "pYvtN0635xwPEwhyRnA2"
        }
      ],
      thumbnail: {
        imageUrl: bannerHomeWorkout
      },
      availability: "public",
      contentType: "ROUTINE",
      exercises: [
        {
          contentItems: [
            {
              type: "TEXT",
              title: "Exercise From",
              subtitle: "Explanation",
              id: "bCfvZeWzpWgB8BDJnaJ2",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            }
          ],
          name: "Pull ups",
          startingExerciseStatus: "Positive",
          selectedProgressionId: "dEw7NjueheOAhlnzV0g5",
          rpe: "90%",
          type: "BASE_EXERCISE",
          tutor: {
            contentItems: [
              {
                type: "TEXT",
                subtitle: "Explanation",
                id: "bCfvZeWzpWgB8BDJnaJ2",
                title: "Exercise From",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
              }
            ],
            media: {
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true,
              isExplanation: true,
              isVideo: true
            },
            youtubeId: youttubeTutorId,
            isYoutubeVideo: true,
            isVideo: true,
            isExplanation: true
          },
          id: "W5iCcX1RF7jZBywBb8JK",
          repRange: "8 - 15 Reps",
          media: {
            videoUrl: pushUpStockVideo,
            isVideo: true
          },
          exerciseVariation: "basic-exercise",
          sets: 4,
          exerciseTimings: {
            hold: 1,
            negatives: 1,
            rest: 90,
            positives: 1
          },
          progressions: [
            {
              name: "Negative Pull ups",
              id: "80RVokygBhSGSXbH4jFO",
              contentItems: [
                {
                  title: "Progression Information",
                  type: "TEXT",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "7wzO6hco9ZQssPVxFLms"
                }
              ],
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "7wzO6hco9ZQssPVxFLms",
                    title: "Progression Information"
                  }
                ]
              }
            },
            {
              isDefault: true,
              id: "dEw7NjueheOAhlnzV0g5",
              contentItems: [
                {
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "GAC3nPGiYvP5d4ZEqhYL",
                  type: "TEXT"
                }
              ],
              name: "Pull ups",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true,
                  isExplanation: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "GAC3nPGiYvP5d4ZEqhYL"
                  }
                ]
              }
            },
            {
              tutor: {
                contentItems: [
                  {
                    id: "sU6beJzauX5pGiFk6Lou",
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional."
                  }
                ],
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                }
              },
              name: "Archer Pull ups",
              contentItems: [
                {
                  id: "sU6beJzauX5pGiFk6Lou",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "Lbdpuz0tsIzokSLCMFiw"
            },
            {
              name: "Weighted Pull ups",
              id: "lfbVqzSWtgydETHlxxY5",
              isWeighted: true,
              contentItems: [
                {
                  type: "TEXT",
                  title: "Progression Information",
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "D3Rwlhc7HvWrP21Kvqf1"
                }
              ],
              tutor: {
                media: {
                  isVideo: true,
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "D3Rwlhc7HvWrP21Kvqf1",
                    type: "TEXT"
                  }
                ]
              }
            }
          ]
        },
        {
          tutor: {
            contentItems: [
              {
                subtitle: "Explanation",
                id: "Qddxz96opeNDsIWyzAR2",
                body:
                  "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                type: "TEXT",
                title: "Exercise From"
              }
            ],
            media: {
              isExplanation: true,
              isVideo: true,
              youtubeId: youttubeTutorId,
              isYoutubeVideo: true
            }
          },
          type: "BASE_EXERCISE",
          startingExerciseStatus: "Negative",
          sets: 4,
          exerciseVariation: "basic-exercise",
          name: "Dips",
          repRange: "8 - 15 RM",
          exerciseTimings: {
            rest: 90,
            negatives: 1,
            positives: 1
          },
          progressions: [
            {
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "3D7QjS1vjstdrf5Okl8r",
                  type: "TEXT",
                  title: "Progression Information"
                }
              ],
              id: "7in34U2cPx5zxK42QcrG",
              name: "Negative Dips",
              tutor: {
                media: {
                  isExplanation: true,
                  isVideo: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true
                },
                contentItems: [
                  {
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT",
                    title: "Progression Information",
                    id: "3D7QjS1vjstdrf5Okl8r"
                  }
                ]
              },
              isWeighted: false
            },
            {
              id: "ZvKkBWVgDpcixlUjxMEt",
              isDefault: true,
              name: "Dips",
              tutor: {
                contentItems: [
                  {
                    title: "Progression Information",
                    id: "lB9GZWlPszCPZXhlM27U",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    type: "TEXT"
                  }
                ],
                media: {
                  isExplanation: true,
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isVideo: true
                }
              },
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "lB9GZWlPszCPZXhlM27U",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ]
            },
            {
              isWeighted: true,
              contentItems: [
                {
                  body:
                    "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                  id: "PV3bUioiZaIvlCxvsZml",
                  title: "Progression Information",
                  type: "TEXT"
                }
              ],
              id: "ohDzGhxuGmHdrywM6GAt",
              name: "Weighted Dips",
              tutor: {
                media: {
                  youtubeId: youttubeTutorId,
                  isYoutubeVideo: true,
                  isExplanation: true,
                  isVideo: true
                },
                contentItems: [
                  {
                    title: "Progression Information",
                    type: "TEXT",
                    body:
                      "Write something about the progression here, such as form explanation, difficulty or what ever you want. This is optional.",
                    id: "PV3bUioiZaIvlCxvsZml"
                  }
                ]
              }
            }
          ],
          id: "zPdK3HRHJ59jSlJuYQfj",
          rpe: "90%",
          selectedProgressionId: "ZvKkBWVgDpcixlUjxMEt",
          media: {
            videoUrl:
              "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
            isVideo: true
          },
          contentItems: [
            {
              id: "Qddxz96opeNDsIWyzAR2",
              type: "TEXT",
              title: "Exercise From",
              body:
                "This is text that explains the exercise. You can also provide Instruction videos above. Both are optional but useful for customers. \n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
              subtitle: "Explanation"
            }
          ]
        },
        {
          name: "Lower Body Block",
          exercises: [
            {
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              type: "BASE_EXERCISE",
              sets: 4,
              tutor: {},
              name: "Squats",
              id: "GgtOkQp7pBqJPoOd7dpN",
              exerciseVariation: "basic-exercise"
            },
            {
              name: "Lunges",
              tutor: {},
              type: "BASE_EXERCISE",
              exerciseTimings: {
                rest: 60
              },
              media: {
                videoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/fitnesshub-24b64.appspot.com/o/pushup-stock-compressed.mp4?alt=media&token=52ad3d2a-f526-4e27-b295-0c14d7a22400",
                isVideo: true
              },
              id: "HIhfG5h6ecd2K4sFaiYY",
              sets: 4
            }
          ],
          id: "zLSYjqTEv7ENnlWZoSe2",
          type: "SUPERSET_EXERCISE"
        }
      ]
    };
  }

  function addReview(programId) {
    firebase.default
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Programs")
      .doc(programId)
      .collection("Ratings")
      .doc("RatingId1")
      .set({
        date: new Date().getTime(),
        title: "Great Program",
        rating: 5,
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
        programId: "tALHGyfHzWsnHsy7RmVd",
        userName: "TylerTheCreator"
      });
    firebase.default
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Programs")
      .doc(programId)
      .collection("Ratings")
      .doc("RatingId2")
      .set({
        date: new Date().getTime(),
        title: "Love it!",
        rating: 4,
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
        programId: "tALHGyfHzWsnHsy7RmVd",
        userName: "Kid Cudi"
      });
    firebase.default
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Programs")
      .doc(programId)
      .collection("Ratings")
      .doc("RatingId3")
      .set({
        date: new Date().getTime(),
        title: "Awesome App!",
        rating: 5,
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
        programId: "tALHGyfHzWsnHsy7RmVd",
        userName: "Travis Scott"
      });
    firebase.default
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Programs")
      .doc(programId)
      .collection("Ratings")
      .doc("RatingId4")
      .set({
        date: new Date().getTime(),
        title: "Dope App mate",
        rating: 5,
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
        programId: "tALHGyfHzWsnHsy7RmVd",
        userName: "Kanye West"
      });
    firebase.default
      .firestore()
      .collection("Schools")
      .doc(schoolId)
      .collection("Programs")
      .doc(programId)
      .collection("Ratings")
      .doc("RatingId5")
      .set({
        date: new Date().getTime(),
        title: "Great Program",
        rating: 4,
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi quas repellat eius laborum hic veniam eos quisquam corporis delectus molestias maiores similique!",
        programId: "tALHGyfHzWsnHsy7RmVd",
        userName: "Denzel Curry"
      });
  }
}
