import { makeStyles } from "@material-ui/core";
import { HOLD, HOLD_DOWN, NEGATIVE, POSITIVE } from "../../../../utils/exercise-states";
import Row from "./ui-components/row";

const useStyle = makeStyles(theme => ({
    state: {
        margin: 4,
        filter: "invert(46%) sepia(30%) saturate(1554%) hue-rotate(185deg) brightness(104%) contrast(101%)"
    },
    icon: {
        position: "relative"
    },
    time: {
        color: "white",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        textAlign: "center",
        lineHeight: "40px"
    },
}))

export default function ExerciseStateTimings({ exercise }) {

    
    const classes = useStyle();
    
    if (!exercise.startingExerciseStatus) {
        return <div></div>
    }

    
    const up = <ExerciseStateIcon key={"up"} src="/assets/icons/arrow_up1.svg" duration={exercise.exerciseTimings?.positives ?? 0}></ExerciseStateIcon>
    const hold = <ExerciseStateIcon key={"hold"} src="/assets/icons/square.svg" duration={exercise.exerciseTimings?.hold ?? 0}></ExerciseStateIcon>
    const down = <ExerciseStateIcon key={"down"} src="/assets/icons/arrow_down1.svg" duration={exercise.exerciseTimings?.negatives ?? 0}></ExerciseStateIcon>
    const holdDown = <ExerciseStateIcon key={"holdDown"} src="/assets/icons/square.svg" duration={exercise.exerciseTimings?.holdDown ?? 0}></ExerciseStateIcon>

    let order;
    // console.log(exercise.startingExerciseStatus);
    if (exercise.startingExerciseStatus === HOLD_DOWN) {
        order = [ holdDown, up, hold, down ];
    } else if (exercise.startingExerciseStatus === POSITIVE) {
        order = [ up, hold, down, holdDown ];
    } else if (exercise.startingExerciseStatus === HOLD) {
        order = [ hold, down, holdDown, up ];
    } else if (exercise.startingExerciseStatus === NEGATIVE) {
        order = [ down, holdDown, up, hold ];
    } else {
        throw `INVALID STARTING EXERCISE STATE ${exercise.startingExerciseStatus}`;
    }

    return <Row justify="center">
        { order }
    </Row>
}

function ExerciseStateIcon({ src, duration }) {
    const classes = useStyle();
    if ((duration ?? 0) != 0) {
        return <div classes={ classes.icon } style={{ position: "relative" }}>
            <img className={ classes.state } src={src} width="30" height="30"></img>
            <h2 className={ classes.time }>{duration > 0 ? duration : "X"}</h2>
        </div>
    } else {
        return <div></div>
    }
}