import {
  Box,







  Checkbox, Divider,
  Icon,
  List,

  ListItemIcon, ListItemSecondaryAction,


  makeStyles, Typography,


  useMediaQuery
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useState } from "react";
import { ContentSection } from "../../../../../components/content-list/content-list";
import BannerCard from "../../../../../components/tailwind-components/banner-card";
import MyContainer from "../../../../../components/tailwind-components/my-container";
import ExerciseInfo from "../exercise-info";
import HelpItem from "../help-item";
import InfoItemList from "../info-item-list";
import MediaItem from "../media-item";
import Ratio from "../ratio";
import MyCard from "../ui-components/my-card";
import ResponsiveCard from "../ui-components/responsive-card";

const useStyle = makeStyles(theme => ({
  selection: {
    color: theme.palette.text.secondary,
    fontWeight: "w500"
  }
}));

export default function TutorOverview({ exercise, selectProgression, slideTo }) {
  const isLarge = useMediaQuery(theme => theme.breakpoints.up("lg"));

  console.log("ASDA", exercise.tutor);

  return (
    <MyContainer>
      <BannerCard isBanner={isLarge}>
        <Ratio ratio={16 / 9}>
          <MediaItem media={exercise.tutor.media}></MediaItem>
        </Ratio>
      </BannerCard>
      <Box p={2} className="mt-3">
        <Typography variant="h1" className="max-line-1" style={{ flexGrow: 1 }}>
          {exercise.name}
        </Typography>
        {/* <InfoItemList items={section.contentItems}></InfoItemList> */}
        <ProgressionsOverview
          className="mt-4"
          exercise={exercise}
          selectProgression={selectProgression}
          slideTo={slideTo}
        ></ProgressionsOverview>
        <ContentSection contentItems={exercise.tutor.contentItems} className="mt-4"></ContentSection>
      </Box>
    </MyContainer>
  );
}

function ProgressionsOverview({ exercise, selectProgression, slideTo, className }) {
  const classes = useStyle();
  const [selected, setSelected] = useState(0);

  return (
    <MyCard className={className}>
      {/* // TODO: Move to text values */}
      <Box p={2}>
        <span className={classes.selection}>
          <HelpItem
            title="Select A Progression"
            message={`Select a progression from the selection of which you can do a maximum of the displayed rep range (${exercise.repRange})`}
          >
            <Typography color="textSecondary">Select A Progression</Typography>
          </HelpItem>
        </span>
      </Box>
      <Divider></Divider>
      <List component="nav">
        {exercise.progressions.map((prog, i) => {
          return (
            <ListItem
              button
              key={i}
              onClick={() => slideTo(i + 1)}
              //   selected={selectedIndex == i + 1}
              className="rounded-md m-2"
              //   exact
              //   to={`${program.id}?page=${i + 1}`}
              style={{ width: "calc(100% - 1rem)" }}
              //   component={NavLink}
            >
              <ListItemIcon>
                <Checkbox
                  checked={prog.id === exercise.selectedProgressionId}
                  onClick={e => e.stopPropagation()}
                  onChange={() => {selectProgression(prog.id);}}
                />
              </ListItemIcon>
              <ListItemText primary={prog.name}></ListItemText>
              <ListItemSecondaryAction>
                <Icon>navigate_next</Icon>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </MyCard>
  );
}
