import { FormControlLabel, Typography } from "@material-ui/core";
import { Field } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import React, { useContext, useEffect, useState } from "react";
import MyButton from "../../../../components/my-button";
import { uploadFile } from "../../../../storage";
import { SchoolContext } from "../../school";
import Dropzone from "react-dropzone";

// TODO Apply parameters in all occurences of mediaiteminput
// Embed YouTube Video checkbox functionality
// Embed YouTube Video with url?
// Disbale explanation functionality
export default function MediaItemInput({ label, object, formik, isExplanation, imageOnly, videoOnly, disableYouTube }) {
  const school = useContext(SchoolContext);

  let types = "image/*, video/*";
  let allowedFormats = "PNG, JPG, MP4, MP4, WEBM or Ogg"; // TODO: Other formats?
  if (imageOnly) {
    allowedFormats = "PNG, JPG or JPEG"; // Other formats?
    types = "image/*";
  } else if (videoOnly) {
    types = "video/*";
    allowedFormats = "MP4, WEBM or Ogg"; // Other formats?
  }

  useState(() => {
    if (isExplanation && formik) {
      formik.setFieldValue(`${object}.isExplanation`, true);
    }
  }, []);

  async function onFileInput(files) {
    if (files.length < 1) {
      return;
    }

    const file = files[0];
    console.log(file);
    const url = await uploadFile(file, file.name, school.id);
    const type = file.type.split("/")[0];
    if (type === "image") {
      formik.setFieldValue(`${object}.imageUrl`, url);
      formik.setFieldValue(`${object}.isVideo`, false);
    } else if (type === "video") {
      formik.setFieldValue(`${object}.videoUrl`, url);
      formik.setFieldValue(`${object}.isVideo`, true);
    }
    console.log(formik.values.media);
  }

  if (!formik || !object || !getObjectKeyWithString(formik.values, object)) {
    console.log("ERROR", formik.values, object )
    return "Error: No Formik Object"
  }

  return (
    <div className="space-y-4">
      <Typography color="textSecondary">{label ?? "Media"}</Typography>
      { getObjectKeyWithString(formik.values, object).isExplanation && (
        <FormControlLabel
          label="Embed YouTube Video instead"
          control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name={`${object}.isYoutubeVideo`}></Field>}
        ></FormControlLabel>
      )}
      {(!getObjectKeyWithString(formik.values, object).isYoutubeVideo) && (
        <Dropzone onDrop={acceptedFiles => onFileInput(acceptedFiles)} accept={types}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>
                    <input {...getInputProps()} />
                    <Typography variant="subtitle2">Drag and drop or click to select file</Typography>
                  </div>
                  <Typography variant="caption" color="textSecondary">
                    {allowedFormats}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {getObjectKeyWithString(formik.values, object).imageUrl && <img style={{ display: "block", width: "100%", maxHeight: 500, objectFit: "contain" }} src={getObjectKeyWithString(formik.values, object).imageUrl}></img>}
      {getObjectKeyWithString(formik.values, object).videoUrl && (
        <video style={{ display: "block" }} width="100%" muted controls>
          <source src={getObjectKeyWithString(formik.values, object).videoUrl} type="video/mp4" />
          <source src={getObjectKeyWithString(formik.values, object).videoUrl} type="video/webm" />
          <source src={getObjectKeyWithString(formik.values, object).videoUrl} type="video/ogg" />
          Video Tag is not supported in your browser
        </video>
      )}
      {/* <FileInput></FileInput> */}
      {/* <input type="file" accept={types} onChange={onFileInput} /> */}
      {/* <Field component={TextField} label="Image Url" name={`${object}.imageUrl`} placeholder="Image Url" type="text" variant="outlined" fullWidth></Field>
        <Field component={TextField} label="Video Url" name={`${object}.videoUrl`} placeholder="Video Url" type="text" variant="outlined" fullWidth></Field> */}
      {!imageOnly && (
        <React.Fragment>
          {/* <FormControlLabel
            label="Is Explanation"
            control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name={`${object}.isExplaination`}></Field>}
          ></FormControlLabel>
          <br></br> */}
          {getObjectKeyWithString(formik.values, object).isYoutubeVideo && (
            <Field
              component={TextField}
              label="YouTube Video Id"
              name={`${object}.youtubeId`}
              placeholder="YouTube Video Id"
              type="text"
              variant="outlined"
              fullWidth
            ></Field>
          )}
        </React.Fragment>
      )}
      {/* <FormControlLabel
            label="Is Video"
            control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name={`${object}.isVideo`}></Field>}
        ></FormControlLabel> */}
      {/* <Typography>WIP: Will be replaced with File Upload Area</Typography> */}
    </div>
  );
}

function getObjectKeyWithString(object, string) {
  let value = object;
  const keys = string.split(".").forEach(key => value = value[key]);
  return value;
}
