import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  ViberShareButton,
  WeiboShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  EmailIcon,
  ViberIcon,
  WeiboIcon
} from "react-share";

const useStyle = makeStyles(theme => ({
  shareIcon: {
    padding: "4px !important"
  }
}));

export default function ShareDialog({ title, url, open, close }) {
  const size = 40;
  const classes = useStyle();

  return (
    <Dialog onClose={close} open={open} fullWidth>
      <DialogTitle>Share {title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <FacebookShareButton className={classes.shareIcon} title={title} link={url}>
            <FacebookIcon round size={size}></FacebookIcon>
          </FacebookShareButton>
          <FacebookMessengerShareButton className={classes.shareIcon} title={title} link={url}>
            <FacebookMessengerIcon round size={size}></FacebookMessengerIcon>
          </FacebookMessengerShareButton>
          <TwitterShareButton className={classes.shareIcon} title={title} link={url}>
            <TwitterIcon round size={size}></TwitterIcon>
          </TwitterShareButton>
          <WhatsappShareButton className={classes.shareIcon} title={title} link={url}>
            <WhatsappIcon round size={size}></WhatsappIcon>
          </WhatsappShareButton>
          <RedditShareButton className={classes.shareIcon} title={title} link={url}>
            <RedditIcon round size={size}></RedditIcon>
          </RedditShareButton>
          <EmailShareButton className={classes.shareIcon} title={title} link={url}>
            <EmailIcon round size={size}></EmailIcon>
          </EmailShareButton>
          <TelegramShareButton className={classes.shareIcon} title={title} link={url}>
            <TelegramIcon round size={size}></TelegramIcon>
          </TelegramShareButton>
          <LinkedinShareButton className={classes.shareIcon} title={title} link={url}>
            <LinkedinIcon round size={size}></LinkedinIcon>
          </LinkedinShareButton>
          <PinterestShareButton className={classes.shareIcon} title={title} link={url}>
            <PinterestIcon round size={size}></PinterestIcon>
          </PinterestShareButton>
          <VKShareButton className={classes.shareIcon} title={title} link={url}>
            <VKIcon round size={size}></VKIcon>
          </VKShareButton>
          <TumblrShareButton className={classes.shareIcon} title={title} link={url}>
            <TumblrIcon round size={size}></TumblrIcon>
          </TumblrShareButton>
          <ViberShareButton className={classes.shareIcon} title={title} link={url}>
            <ViberIcon round size={size}></ViberIcon>
          </ViberShareButton>
          <WeiboShareButton className={classes.shareIcon} title={title} link={url}>
            <WeiboIcon round size={size}></WeiboIcon>
          </WeiboShareButton>
          {/* // TODO More Icons */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
