import React from 'react';
import { Checkbox, Chip, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useBasePath } from "../../../../../utils/get-subdomain";

export function ProgramContentOverview({ program, isPurchased, status, goToPage, selectedIndex, showOverview = false }) {
  const basePath = useBasePath();

  return (
    <List component="nav">
      {showOverview && (
        <ListItem
          button
          onClick={() => goToPage(0)}
          selected={selectedIndex == 0}
          className="rounded-md m-2"
          style={{ width: "calc(100% - 1rem)" }}
        >
          <ListItemText primary="Overview"></ListItemText>
          <ListItemSecondaryAction>
            <Icon>navigate_next</Icon>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {program?.sections?.map((section, i) => (
        <ListItem
          button
          key={i}
          //   onClick={() => goToPage(i + 1)}
          selected={selectedIndex == i + 1}
          className="rounded-md m-2"
          exact
          to={`${program.id}?page=${i + 1}`}
          style={{ width: "calc(100% - 1rem)" }}
          component={NavLink}
        >
          {/* {section.type === "PHASE_PROGRAM_SECTION" && status && (
            <ListItemIcon>
              <Checkbox
                style={{ padding: 0 }}
                checked={i <= status.currentPhaseIndex} // TODO:
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </ListItemIcon>
          )} */}
          <ListItemText primary={section.name}></ListItemText>
          <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
            <div className="flex justify-center items-center">
              { !isPurchased && section.isFree == true ? <Chip size="small" color="primary" label="Preview"></Chip> : <React.Fragment></React.Fragment> }
              { !isPurchased && section.isFree !== true ? <Icon>lock_outline</Icon> : <React.Fragment></React.Fragment> }
              {/* {!isPurchased && section.isFree === true ? <Chip size="small" color="primary" label="Preview"></Chip> : <Icon>lock_outline</Icon>} */}
              <Icon>navigate_next</Icon>
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
