import { Field, FieldArray, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ContentRow } from "../../../../components/content-list/content-list";
import { PrimaryButton, SecondaryButton } from "../../../../components/tailwind-components/buttons";
import FormCard from "../../../../components/tailwind-components/form-card";
import { FormMarkdownEditor } from "../../../../components/tailwind-components/form-elements";
import { fetchPrograms, fetchRoutines } from "../../../../db";
import { PROGRAM_TYPE, ROUTINE_TYPE, SCHEDULE_TYPE, TEXT_TYPE } from "../../../../utils/content-types";
import { generateUniqueId } from "../../../../utils/utils";
import { SchoolContext } from "../../school";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";
import { ContentDnDList, reorder } from "../components/dashboard/dnd-list";
import ScheduleContentForm from "./schedule-content-form";
import ProgramSelectionDialog from "../components/program-selection-dialog";
import RoutineSelectionDialog from "../components/routine-selection-dialog";
import { Icon, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MediaItemInput from "../components/media-item-input";
import MyButton from "../../../../components/my-button";

const initContentText = () => {
  return {
    title: "Test",
    type: TEXT_TYPE,
    media: {
      isExplanation: true,
      isYoutubeVideo: false,
    },
    id: generateUniqueId()
  };
};

const initContentRoutine = () => {
  return {
    title: "Test",
    type: ROUTINE_TYPE,
    id: generateUniqueId()
  };
};

const initContentProgram = () => {
  return {
    title: "Test",
    type: PROGRAM_TYPE,
    id: generateUniqueId()
  };
};

const initProgramSchedule = () => {
  return {
    title: "",
    type: SCHEDULE_TYPE,
    id: generateUniqueId()
  };
};

export function ContentList({ formik, path, values, valuesName }) {
  const router = useHistory();

  return (
    <FieldArray name="contentItems">
      {({ insert, remove, push }) => (
        <div>
          <ContentDnDList
            formik={formik}
            name="contentItems"
            remove={remove}
            onEdit={item => router.push(`${path}/${item.id}`)}
          ></ContentDnDList>
          <div className="space-x-2 mt-4">
            <MyButton onClick={() => push(initContentText())}>Add Info Item</MyButton>
            <MyButton onClick={() => push(initContentRoutine())}>Add Workout Section</MyButton>
            <MyButton onClick={() => push(initContentProgram())}>Add Program Section</MyButton>
            <MyButton onClick={() => push(initProgramSchedule())}>Add Program Schedule</MyButton>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

export function TutorContentList({ formik, path }) {
  const router = useHistory();

  return (
    <React.Fragment>
      <FormCard>
        <MediaItemInput object="tutor.media" formik={formik} label="Progression Video Banner"></MediaItemInput>
      </FormCard>
      <FieldArray name="tutor.contentItems">
        {({ insert, remove, push }) => (
          <div>
            <ContentDnDList
              formik={formik}
              name="contentItems"
              values={formik.values.tutor}
              remove={remove}
              onEdit={item => router.push(`${path}/${item.id}`)}
            ></ContentDnDList>
            <div className="space-x-2 mt-4">
              <MyButton onClick={() => push(initContentText())}>Add Info Item</MyButton>
              <MyButton onClick={() => push(initContentRoutine())}>Add Workout Section</MyButton>
              <MyButton onClick={() => push(initContentProgram())}>Add Program Section</MyButton>
            </div>
          </div>
        )}
      </FieldArray>
    </React.Fragment>
  );
}

export function EditContentItemRoute({ values, formik, breadcrumbItems }) {
  const vals = values ?? formik.values;

  return (
    <EditContentItemPage
      breadcrumbItems={breadcrumbItems}
      items={vals.contentItems}
      applyEdit={newItem => {
        const index = vals.contentItems.findIndex(item => item.id === newItem.id);
        if ((index ?? -1) < 0) return;

        const newItems = vals.contentItems;
        newItems[index] = newItem;

        formik.setFieldValue("contentItems", newItems);
      }}
    ></EditContentItemPage>
  );
}

export function EditContentItemPage({ items, applyEdit, breadcrumbItems }) {
  const { itemId } = useParams();
  const item = items?.find(item => item.id === itemId);
  const school = useContext(SchoolContext);
  const router = useHistory();

  breadcrumbItems = [...breadcrumbItems, { title: item.title, link: `${breadcrumbItems[breadcrumbItems.length - 1].link}/item/${itemId}` }];

  return (
    <Formik
      initialValues={item}
      onSubmit={values => {
        applyEdit(values);
        router.goBack();
      }}
    >
      {formik => (
        <div>
          <DashboardPageHeader
            breadcrumbItems={breadcrumbItems}
            title="Edit Content"
            actions={<SecondaryButton onClick={formik.handleSubmit}>Done</SecondaryButton>}
          ></DashboardPageHeader>
          <DashboardPageContent>
            <FormCard>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                label="Section Name"
                name="title"
                placeholder="Section Name"
                type="text"
              ></Field>
              <Field component={Select} variant="outlined" fullWidth label="Type" name="type">
                <option value={ROUTINE_TYPE}>Workouts</option>
                <option value={PROGRAM_TYPE}>Programs</option>
                <option value={TEXT_TYPE}>Information</option>
                <option value={SCHEDULE_TYPE}>Schedule</option>
              </Field>
              {formik.values.type === ROUTINE_TYPE && <RoutineListContent content={formik.values} formik={formik}></RoutineListContent>}
              {formik.values.type === PROGRAM_TYPE && <ProgramListContent content={formik.values} formik={formik}></ProgramListContent>}
              {formik.values.type === TEXT_TYPE && <TextContent content={formik.values} formik={formik}></TextContent>}
              {formik.values.type === SCHEDULE_TYPE && <ScheduleContentForm formik={formik}></ScheduleContentForm>}
            </FormCard>
            Preview:
            {/* TODO: show preview for schedule (fix bug) */}
            {formik.values.type !== SCHEDULE_TYPE && <ContentRow row={formik.values} school={school}></ContentRow>}
          </DashboardPageContent>
        </div>
      )}
    </Formik>
  );
}

// export default function ContentInput({ formik }) {
//   return (
//     <React.Fragment>
//       <h3>Content</h3>
//       <FieldArray name="contentItems">
//         {({ insert, remove, push }) => (
//           <div>
//             {formik.values.contentItems?.map((value, i) => (
//               <ContentRowInput key={i} index={i} formik={formik} content={value}></ContentRowInput>
//             ))}
//             <PrimaryButton onClick={() => push(defaultContentRow)}>Add Content</PrimaryButton>
//           </div>
//         )}
//       </FieldArray>
//     </React.Fragment>
//   );
// }

// function ContentRowInput({ index, content, formik }) {
//   let preview;

//   const school = useContext(SchoolContext);

//   return (
//     <FormCard>
//       <FormInput label="Section Name" name={`contentItems.${index}.title`} placeholder="Section Name" type="text"></FormInput>
//       <FormSelect label="Type" name={`contentItems.${index}.type`}>
//         <option value={ROUTINE_TYPE}>Workouts</option>
//         <option value={PROGRAM_TYPE}>Programs</option>
//         <option value={TEXT_TYPE}>Information</option>
//       </FormSelect>
//       {content.type === ROUTINE_TYPE && <RoutineListContent index={index} content={content} formik={formik}></RoutineListContent>}
//       {content.type === PROGRAM_TYPE && <ProgramListContent index={index} content={content} formik={formik}></ProgramListContent>}
//       {content.type === TEXT_TYPE && <TextContent index={index} content={content} formik={formik}></TextContent>}
//       <ContentRow row={content} school={school}></ContentRow>
//     </FormCard>
//   );
// }

function RoutineListContent({ content, formik }) {

  const school = useContext(SchoolContext);
  const [routines, setRoutines] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(
    () =>
      fetchRoutines(school.id).then(res => {
        console.log("Fetched Routines");
        setRoutines(res);
      }),
    []
  );

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const newList = reorder(formik.values.contentIds, result.source.index, result.destination.index);

    formik.setFieldValue("contentIds", newList);
  }

  return (
    <div>
      {
        <FieldArray name={`contentIds`}>
          {({ insert, remove, push }) => (
            <div>
              <List>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div>
                          {content.contentIds
                            ?.filter(i => i)
                            .map((id, index) => {
                              const routine = routines.find(r => r.id === id);
                              return (
                                <Draggable key={id} draggableId={id} index={index}>
                                  {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                      <ListItem>
                                        <ListItemIcon {...provided.dragHandleProps}>
                                          <Icon>reorder</Icon>
                                        </ListItemIcon>
                                        <ListItemText>{routine?.name ?? "?"}</ListItemText>
                                        <ListItemSecondaryAction>
                                          <IconButton onClick={() => remove(index)}>
                                            <Icon>delete</Icon>
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </List>

              <SecondaryButton onClick={() => setOpen(true)}>Select Workouts</SecondaryButton>

              <RoutineSelectionDialog
                open={open}
                setOpen={setOpen}
                initialSelection={formik.values.contentIds}
                onSelect={ids => formik.setFieldValue("contentIds", ids)}
                routines={routines}
              ></RoutineSelectionDialog>
            </div>
          )}
        </FieldArray>
      }
    </div>
  ); 
}

function ProgramListContent({ content, formik }) {
  const school = useContext(SchoolContext);
  const [programs, setPrograms] = useState([]);

  useEffect(
    () =>
      fetchPrograms(school.id).then(res => {
        console.log("Fetched Programs");
        setPrograms(res);
      }),
    []
  );

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const newList = reorder(formik.values.contentIds, result.source.index, result.destination.index);

    formik.setFieldValue("contentIds", newList);
  }

  return (
    <div>
      {
        <FieldArray name={`contentIds`}>
          {({ insert, remove, push }) => (
            <div>
              <List>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div>
                          {content.contentIds
                            ?.filter(i => i)
                            .map((id, index) => {
                              console.log(id);
                              const program = programs.find(prog => prog.id === id);
                              return (
                                <Draggable key={id} draggableId={id} index={index}>
                                  {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                      <ListItem>
                                        <ListItemIcon {...provided.dragHandleProps}>
                                          <Icon>reorder</Icon>
                                        </ListItemIcon>
                                        <ListItemText>{program?.name ?? "?"}</ListItemText>
                                        <ListItemSecondaryAction>
                                          <IconButton onClick={() => remove(index)}>
                                            <Icon>delete</Icon>
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </List>

              <ProgramSelectionDialog
                initialSelection={formik.values.contentIds}
                onSelect={ids => formik.setFieldValue("contentIds", ids)}
                programs={programs}
              ></ProgramSelectionDialog>
            </div>
          )}
        </FieldArray>
      }
    </div>
  );
}

function TextContent({ content, formik }) {
  return (
    <div>
      <Field
        component={TextField}
        variant="outlined"
        fullWidth
        label="Subtitle"
        name={`subtitle`}
        placeholder="Subtitle"
        type="text"
      ></Field>
      <MediaItemInput object="media" formik={formik}></MediaItemInput>
      <FormMarkdownEditor onChange={value => formik.setFieldValue(`body`, value)} name={`body`} label="Markdown Test"></FormMarkdownEditor>

      {/* <TextContentItem item={content}></TextContentItem> */}
    </div>
  );
}
