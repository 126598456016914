import { AppBar, Icon, makeStyles, useMediaQuery } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBasePath } from "../../../../utils/get-subdomain";

const useStyle = makeStyles(theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.background.paper
  },
  link: {
    textDecoration: "none",
    color: "white"
  },
  navBarOffset: {
    height: 100
  }
}));

export default function MobileNavbar({ value, children }) {
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const classes = useStyle();
  const router = useHistory();
  const basePath = useBasePath();

  if (!matches) {
    return (
      <React.Fragment>
        <div>
          {children}
          <div className={classes.navBarOffset}></div>
        </div>
        <AppBar position="fixed" className={classes.appBar}>
          <BottomNavigation value={value} showLabels>
            <BottomNavigationAction label="Home" icon={<Icon>home</Icon>} onClick={() => router.push(`/${basePath}`)} />
            <BottomNavigationAction label="Workouts" icon={<Icon>search</Icon>} onClick={() => router.push(`/${basePath}workouts`)} />
            {/* <BottomNavigationAction label="Shop" icon={ <Icon>shopping_basket</Icon> } onClick={ () => router.push("/shop")} /> */}
            <BottomNavigationAction label="Profile" icon={<Icon>person</Icon>} onClick={() => router.push(`/${basePath}profile`)} />
          </BottomNavigation>
        </AppBar>
      </React.Fragment>
    );
  } else {
    return <div>{children}</div>;
  }
}
