import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { ContentSection } from "../../../../../components/content-list/content-list";
import BannerCard from "../../../../../components/tailwind-components/banner-card";
import MyContainer from "../../../../../components/tailwind-components/my-container";
import InfoItemList from "../info-item-list";
import MediaItem from "../media-item";
import Ratio from "../ratio";
import ResponsiveCard from "../ui-components/responsive-card";

export default function TutorProgression({ progression }) {
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("lg"));


  return (
    <MyContainer>
      <BannerCard isBanner={isLarge}>
        <Ratio ratio={16 / 9}>
          <MediaItem media={progression.tutor?.media}></MediaItem>
        </Ratio>
      </BannerCard>
      <Box p={2} className="mt-3">
        <Typography variant="h1" className="max-line-1" style={{ flexGrow: 1 }}>
          {progression.name}
        </Typography>
        <ContentSection contentItems={progression?.tutor?.contentItems} className="mt-4"></ContentSection>
      </Box>
    </MyContainer>
  );

  return (
    <ResponsiveCard>
      <Ratio ratio={16 / 9}>
        <MediaItem media={progression.tutor?.media}></MediaItem>
      </Ratio>
      <Box p={2}>
        <h1>{`${progression.name} Tutor`}</h1>
        <Box height={16}></Box>
        {progression?.tutor?.contentItems && <InfoItemList items={progression?.tutor.contentItems}></InfoItemList>}
      </Box>
      <Box height={70}></Box>
    </ResponsiveCard>
  );
}
