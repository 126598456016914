import { Field } from "formik";
import { TextField } from "formik-material-ui";
import FormCard from "../../../../components/tailwind-components/form-card";
import { FileInput } from "../../../../components/tailwind-components/form-elements";

export default function SchoolMeta({ values }) {
    return (
      <FormCard>
          <Field component={TextField} variant="outlined" fullWidth name="name" label="School Name" type="text" placeholder="School Name"></Field>
          <Field  component={TextField} variant="outlined" fullWidth multiline rows={3} name="description" label="Description" placeholder="Write a few sentences about your school" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="avatar" label="Avatar Icon" placeholder="Link to avatar icon" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="icon" label="Brand Icon" placeholder="Link to brand icon" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="iconDark" label="Brand Icon" placeholder="Link to brand icon for dark mode (optional)" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="favicon" label="Favicon" placeholder="Link to Favicon" type="text"></Field>
          <FileInput label="Avatar"></FileInput>
          <Field  component={TextField} variant="outlined" fullWidth name="youtube" label="YouTube Channel" placeholder="Url to YouTube Channel" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="twitter" label="Twitter Profile" placeholder="Url to Twitter profile" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="instagram" label="Instagram Profile" placeholder="Url to Instagram profile" type="text"></Field>
          <Field  component={TextField} variant="outlined" fullWidth name="facebook" label="Facebook Profile" placeholder="Url to Facebook profile" type="text"></Field>
      </FormCard>
    );
  }