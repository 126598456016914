import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { BASE_EXERCISE_TYPE, SUPERSET_EXERCISE_TYPE } from "../../../../../exercise-types";
import AlternatingExerciseForm from "./edit-alternating";
import BaseExerciseForm from "./edit-exercise";

export default function AdminExerciseEditPage({ path, routine, save, breadcrumbItems }) {
    const { exerciseId } = useParams();
    const router = useHistory();
    
    const index = routine.exercises.findIndex(exercise => exercise.id === exerciseId);

    const exercise = routine.exercises[index];
  
    if (exercise.type === BASE_EXERCISE_TYPE) {
      return <BaseExerciseForm applyEdit={save} exercise={exercise} path={path} breadcrumbItems={breadcrumbItems}></BaseExerciseForm>;
    }
  
    if (exercise.type === SUPERSET_EXERCISE_TYPE) {
      return (
        <AlternatingExerciseForm path={path} applyEdit={save} exercise={exercise} breadcrumbItems={breadcrumbItems}></AlternatingExerciseForm>
      );
    }
  }