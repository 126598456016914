import { Box, Icon, makeStyles } from "@material-ui/core";
import React from "react";
import { REP_CHALLENGE_TYPE, TIME_CHALLENGE_TYPE } from "../../../../exercise-types";
import { secondsToMMSS } from "../../../../utils/routine-utils";
import {
  EMOM_REST_EXPLANATION_MESSAGE,
  EMOM_REST_EXPLANATION_TITLE,
  EXERTION_SCALE_EXPLANATION_MESSAGE,
  EXERTION_SCALE_EXPLANATION_TITLE,
  getRestExplanation,
  NEXT_REST_EXPLANATION_TITLE,
  REP_CHALLENGE_GOAL_EXPLANATION_MESSAGE,
  REP_CHALLENGE_GOAL_EXPLANATION_TITLE,
  REP_RANGE_EXPLANATION_MESSAGE,
  REP_RANGE_EXPLANATION_TITLE,
  SECOND_GOAL_EXPLANATION_MESSAGE,
  SECOND_GOAL_EXPLANATION_TITLE,
  TIME_CHALLENGE_EXPLANATION_MESSAGE,
  TIME_CHALLENGE_EXPLANATION_TITLE
} from "../../../../utils/text-values";
import HelpItem, { HelpItemIcon } from "./help-item";
import Row from "./ui-components/row";

const useStyle = makeStyles(theme => ({
  chip: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 20,
    fontWeight: "500",
    marginLeft: 4,
    marginRight: 4,
    marginTop: 4
  },
  rpeChip: {
    backgroundColor: theme.palette.foreground.default,
    borderRadius: 20,
    fontWeight: "500",
    marginLeft: 4,
    marginRight: 4,
    marginTop: 4,
    overflow: "hidden"
  }
}));

export default function ExerciseInfo({ exercise }) {
  // TODO: Mobile overflow
  return (
    <Row justify="space-evenly">
      {exercise.rpe && <RPE exercise={exercise}></RPE>}
      {exercise.repRange && <RepRange exercise={exercise}></RepRange>}
      {exercise.type === REP_CHALLENGE_TYPE && <RepChallengeGoal exercise={exercise}></RepChallengeGoal>}
      {exercise.type === TIME_CHALLENGE_TYPE && <TimeChallengeGoal exercise={exercise}></TimeChallengeGoal>}
      { exercise.goalType === "repGoal" && exercise.repGoal != null && <RepGoal exercise={exercise}></RepGoal>}
      { exercise.goalType === "timeGoal" && exercise.secondsGoal != null && <SecondGoal exercise={exercise}></SecondGoal>}
      <Rest exercise={exercise}></Rest>
    </Row>
  );
}

function getColor(value) {
  //value from 0 to 1
  value = value / 10;
  var hue = ((1 - value) * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
}

function MyChip({ children, color }) {
  const classes = useStyle();
  return (
    <div className={classes.chip} style={color && { backgroundColor: color }}>
      {children}
    </div>
  );
}

function RPE({ exercise }) {
  const color = getColor(exercise.rpeValue);
  const classes = useStyle();

  if (!exercise.allOutLastSet) {
    return (
      <MyChip color={color}>
        <HelpItem title={EXERTION_SCALE_EXPLANATION_TITLE} message={EXERTION_SCALE_EXPLANATION_MESSAGE}>
          {exercise.rpe}
        </HelpItem>
      </MyChip>
    );
  } else {
    return (
      <div className={classes.rpeChip}>
        <HelpItem title={EXERTION_SCALE_EXPLANATION_TITLE} message={EXERTION_SCALE_EXPLANATION_MESSAGE} showIcon={false}>
          <div style={{ backgroundColor: color, paddingLeft: 8, paddingRight: 4, paddingTop: 2, paddingBottom: 2 }}>{exercise.rpe}</div>
          <Box width={4}></Box>
          <p style={{ color: "red", fontWeight: "bold" }}>F</p>
          <Box width={4}></Box>
          <HelpItemIcon></HelpItemIcon>
          <Box width={4}></Box>
        </HelpItem>
      </div>
    );
  }
}

function RepRange({ exercise }) {
  return (
    <MyChip size="small">
      <HelpItem title={REP_RANGE_EXPLANATION_TITLE} message={REP_RANGE_EXPLANATION_MESSAGE}>
        {exercise.repRange}
      </HelpItem>
    </MyChip>
  );
}

function Rest({ exercise }) {
  if (exercise.isEmom) {
    return (
      <MyChip>
        <HelpItem title={EMOM_REST_EXPLANATION_TITLE} message={EMOM_REST_EXPLANATION_MESSAGE}>
          <Icon fontSize="small">pause</Icon>
          EMOM
        </HelpItem>
      </MyChip>
    );
  } else {
    return (
      <MyChip>
        <HelpItem title={NEXT_REST_EXPLANATION_TITLE} message={getRestExplanation(exercise)}>
          <Icon fontSize="small">pause</Icon>
          {secondsToMMSS(exercise.exerciseTimings?.rest ?? "0")}
          {(exercise.exerciseTimings?.lastRest ?? 0) != 0 && ` / ${secondsToMMSS(exercise.exerciseTimings.lastRest)}`}
        </HelpItem>
      </MyChip>
    );
  }
}

function SecondGoal({ exercise }) {
  return (
    <MyChip>
      <HelpItem title={SECOND_GOAL_EXPLANATION_TITLE} message={SECOND_GOAL_EXPLANATION_MESSAGE}>
        {`${exercise.secondsGoal} Sec.`}
      </HelpItem>
    </MyChip>
  );
}

function TimeChallengeGoal({ exercise }) {
  return (
    <MyChip>
      <HelpItem title={TIME_CHALLENGE_EXPLANATION_TITLE} message={TIME_CHALLENGE_EXPLANATION_MESSAGE}>
        <Icon fontSize="small">flag</Icon>
        {`${secondsToMMSS(exercise.challengeTime)}`}
      </HelpItem>
    </MyChip>
  );
}

function RepChallengeGoal({ exercise }) {
  return (
    <MyChip>
      <HelpItem title={REP_CHALLENGE_GOAL_EXPLANATION_TITLE} message={REP_CHALLENGE_GOAL_EXPLANATION_MESSAGE}>
        <Icon fontSize="small">flag</Icon>
        {`${exercise.challengeGoal}R`}
      </HelpItem>
    </MyChip>
  );
}

function RepGoal({ exercise }) {
  return (
    <MyChip>
      <HelpItem>{`${exercise.repGoal} Reps`}</HelpItem>
    </MyChip>
  );
}

function MobilityInfos() {
  // TODO
}
