import FormCard from "../../../../../components/tailwind-components/form-card";
import { TextField, RadioGroup } from "formik-material-ui";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import UserSelectionDialog from "../../components/user-selection-dialog";
import { useContext, useEffect, useState } from "react";
import { SchoolContext } from "../../../school";
import { fetchUsers } from "../../../../../db";
import MyButton from "../../../../../components/my-button";

export default function AvailabilityForm({ formik, isPorgram: isProgram }) {
  const school = useContext(SchoolContext);
  const [users, setUsers] = useState();
  const [open, setOpen] = useState(false);

  // TODO: gloabel state management for this
  useEffect(() => {
    if (school) {
      fetchUsers(school.id).then(res => setUsers(res));
    }
  }, [school]);

  console.log(open)

  return (
    <FormCard title="Availability" className="pt-0">
      <Field component={RadioGroup} fullWidth name="availability">
        <FormControlLabel value="public" control={<Radio />} label="Public" />
        {/* TODO: Danger: user might not remember to set this availabilty when assigning routine to program */}
        <FormControlLabel value="unlisted" control={<Radio />} label="Unlisted" />
        { !isProgram && <FormControlLabel value="program-only" control={<Radio />} label="With Program Only" />}
        <FormControlLabel value="selected-users-only" control={<Radio />} label="Selected Users Exclusively" />
        <FormControlLabel value="private" control={<Radio />} label="Private (Only Owner & Admins)" />
      </Field>

      { formik.values.availability === "selected-users-only" && <MyButton onClick={() => setOpen(true)}>Edit Assigned Users</MyButton>}

      <UserSelectionDialog
        open={open}
        setOpen={setOpen}
        initialSelection={formik.values.assignedUsers}
        onSelect={ids => formik.setFieldValue("assignedUsers", ids)}
        users={users}
      ></UserSelectionDialog>
      {/* TODO: Show linked programs */}
      {/* <Typography variant="body1">Linked with Programs</Typography>
      <Typography variant="body2" color="textSecondary" className="mt-2">
        Workout is not part of any program. When you add this workout to a program, it will be listed here.
      </Typography> */}
    </FormCard>
  );
}
