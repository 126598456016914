import { Box, Button, Divider, Icon, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../auth";
import MyContainer from "../../../../components/tailwind-components/my-container";
import { fetchProgram, fetchWorkouts } from "../../../../db";
import { useBasePath } from "../../../../utils/get-subdomain";
import { SchoolContext, UserTenantContext } from "../../school";
import AuthForm from "../components/auth";
import ContentRow from "../components/content-row";
import MobileNavbar from "../components/mobile-navbar";
import MyCard from "../components/ui-components/my-card";
import Row from "../components/ui-components/row";

const useStyle = makeStyles(theme => ({
  warnBtn: {
    color: theme.palette.error.main
  }
}));

const historyBatchSize = 15;

export default function ProfilePage() {
  const user = useContext(UserTenantContext);
  const school = useContext(SchoolContext);
  const router = useHistory();
  const basePath = useBasePath();

  // const [programs, setPrograms] = useState();
  const [workouts, setWorkouts] = useState();
  const [loading, setLoading] = useState(false);
  const small = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const classes = useStyle();

  useEffect(() => {
    if (school && user) {
      // if (user.programIds) Promise.all(user.programIds.map(id => fetchProgram(school.id, id))).then(programs => setPrograms(programs));
      fetchWorkouts(school.id, user.uid, new Date().getTime()).then(workouts => {
        setWorkouts(workouts);
        if (workouts.length < historyBatchSize) setLoading(true);
      });
    }
  }, [school, user]);

  function loadNextWorkouts() {
    setLoading(true);
    fetchWorkouts(school.id, user.uid, workouts[workouts.length - 1].finishedAt).then(res => {
      if (res.length >= historyBatchSize) {
        setLoading(false);
      }
      setWorkouts([...workouts, ...res]);
    });
  }

  function workoutHistory() {
    return (
      <div>
        <Box paddingX="16px" paddingTop="16px">
          <Typography variant="h3" color="textSecondary">
            Workout History
          </Typography>
        </Box>
        <Timeline align="left" className="mt-4">
          {workouts &&
            workouts.map(w => (
              <TimelineItem key={w.id}>
                <TimelineOppositeContent style={{ display: "none" }}></TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <Icon style={{ color: "white" }}>library_books</Icon>
                  </TimelineDot>
                  <TimelineConnector></TimelineConnector>
                </TimelineSeparator>
                <TimelineContent>
                  <Box onClick={() => router.push(`/${basePath}log/${w.id}`)} style={{ cursor: "pointer" }}>
                    <Row>
                      <div style={{ flexGrow: 1 }}>
                        <Typography variant="h3">{w.name}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {new Date(w.finishedAt).toLocaleString()}
                        </Typography>
                      </div>
                      <Icon>navigate_next</Icon>
                    </Row>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
        {workouts && workouts.length === 0 && (
          <Typography variant="body1" className="ml-4">
            No Workouts completed yet.
          </Typography>
        )}
        {workouts && workouts.length > 0 && (
          <Box paddingX="16px" paddingBottom="16px">
            {workouts && !loading && <Button onClick={loadNextWorkouts}>Load More...</Button>}
          </Box>
        )}
      </div>
    );
  }

  function body() {
    return (
      <MyContainer className="p-4">
        <div className="flex items-center justify-center">
          <img
            className="h-20 w-20 rounded-full"
            src="https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg"
            alt=""
          />
          <div className="flex-1 ml-4">
            <Typography variant="h2">{user.userName ?? "Unknown"}</Typography>
            <Typography variant="body1" color="textSecondary">
              {user.email}
            </Typography>
          </div>
          <Button onClick={logout} variant="outlined" className={classes.warnBtn}>
            Logout
          </Button>
        </div>
        <Divider className="my-4"></Divider>
        {workoutHistory()}
      </MyContainer>
    );

    // const programMap = {};
    // if (programs) {
    //   programs.forEach(program => (programMap[program.id] = program));
    // }

    // return (
    //   <React.Fragment>
    //     <Box p={2}>
    //       <h2>{user.userName}</h2>
    //       <Typography variant="subtitle1" color="textSecondary">
    //         {user.email}
    //       </Typography>
    //       <Button onClick={logout}>Logout</Button>
    //     </Box>
    //     <Divider></Divider>
    //     <Box paddingX="16px" paddingTop="16px">
    //       <Button startIcon={<Icon>add</Icon>}>Unlock Program</Button>
    //     </Box>
    //     {programs && <ContentRow title="Your Programs" ids={user?.programIds ?? []} programs={programMap} type="PROGRAMS"></ContentRow>}
    //     <Divider></Divider>
    //     {workoutHistory()}
    //   </React.Fragment>
    // );
  }

  if (school) {
    return (
      <MobileNavbar>
        {user && body()}
        {user === null && (
          <div className="flex items-center justify-center sm:mt-24">
            {!small ? (
              <MyCard>
                <AuthForm school={school}></AuthForm>
              </MyCard>
            ) : (
              <AuthForm school={school}></AuthForm>
            )}
          </div>
        )}
      </MobileNavbar>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}
