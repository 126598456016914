export default function BuyNowLock({ buyButton }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "80vh"
      }}
    >
      <div style={{ maxWidth: 200 }}>{buyButton}</div>
    </div>
  );
}
