import { FormControlLabel } from "@material-ui/core";
import { Field, Formik } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import FormCard from "../../../../../components/tailwind-components/form-card";
import { FileInput } from "../../../../../components/tailwind-components/form-elements";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import MediaItemInput from "../../components/media-item-input";
import { ContentList, EditContentItemRoute } from "../../school/content-form";

export default function AdminEditSectionPage({ school, program, path, applyEdit, breadcrumbItems }) {
  const { sectionId } = useParams();
  const router = useHistory();

  const section = program.sections?.find(sec => sec.id === sectionId) ?? { id: sectionId };

  return (
    <Formik
      initialValues={section}
      onSubmit={async values => {
        applyEdit(values);
        router.goBack();
      }}
    >
      {formik => (
        <Switch>
          <Route path={`${path}/${sectionId}/item/:itemId`}>
            <EditContentItemRoute
              formik={formik}
              breadcrumbItems={[...breadcrumbItems, { title: "Content", link: `${path}/${sectionId}?tab=1` }]}
            ></EditContentItemRoute>
          </Route>
          <Route path={`${path}/${sectionId}`}>
            <DashboardPageTabView
              title="Edit Section"
              breadcrumbItems={breadcrumbItems}
              actions={<SecondaryButton onClick={formik.handleSubmit}>Done</SecondaryButton>}
              tabTitles={["General", "Content"]}
              tabChildren={[<GeneralForm formik={formik}></GeneralForm>, <ContentList formik={formik} path={`${path}/${sectionId}/item`}></ContentList>]}
            ></DashboardPageTabView>
          </Route>
        </Switch>
      )}
    </Formik>
  );
}

// function ContentForm({ formik }) {
//   return (
//     <div>
//       <h3>Content</h3>
//       <FormCard>
//         <Field component={TextField} fullWidth variant="outlined" name="title" label="Title" type="text"></Field>
//         {/* TODO: Markdown */}
//         <Field
//           component={TextField}
//           fullWidth
//           variant="outlined"
//           multiline
//           rows={3}
//           name="message"
//           label="Content"
//           type="text"
//           placeholder="Create Markdown Input"
//         ></Field>
//         <FileInput label="Banner Image"></FileInput>
//         {/* <WorkoutScheduleEdit formik={formik}></WorkoutScheduleEdit> */}
//       </FormCard>
//       <PrimaryButton>Add Content Item</PrimaryButton>
//     </div>
//   );
// }

function GeneralForm({ formik }) {
  return (
    <FormCard title="General">
      <Field component={TextField} variant="outlined" fullWidth name="name" label="Title" type="text"></Field>
      <FormControlLabel
        label="Include in Preview"
        control={<Field component={Checkbox} type="checkbox" fullWidth name="isFree"></Field>}
      ></FormControlLabel>
      <MediaItemInput object="media" formik={formik} isExplanation></MediaItemInput>
      {/* <FileInput label="Banner Image"></FileInput>
      <Field component={TextField} variant="outlined" fullWidth name="youtubeLink" label="Banner Video YouTube Link" type="text"></Field>
      <FileInput label="Banner Video"></FileInput> */}
    </FormCard>
  );
}
