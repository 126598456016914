import { Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  submit: {
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
    paddingLeft: 12,
    paddingR: 12,
    borderRadius: 10,
    fontWeight: 600
  }
}));

export default function MyButton({ onClick, children, className, color, props }) {
  const classes = useStyles();

  return <Button onClick={onClick} variant="contained" color={ color ?? "primary"} disableElevation className={ classNames(classes.submit, className)}>
    { children }
  </Button>;
}
