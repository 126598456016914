import { InputAdornment, ListItem, makeStyles, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { initializeSchoolApp, listenOwnerAuth, login, loginOwner } from "../auth";
import MyButton from "../components/my-button";
import { createSchool } from "../db";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  main: {
    padding: "5rem 0",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    margin: 0,
    lineHeight: 1.15,
    fontSize: "4rem"
  },
  description: {
    textAlign: "center",
    lineHeight: 1.5,
    fontSize: "1.5rem"
  }
}));

export default function SignUpPage() {
  const classes = useStyles();
  const router = useHistory();

  const [section, setSection] = useState(0);

  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = listenOwnerAuth(user => setUser(user));

    return () => {
      if (unsubscribe && typeof unsubscribe === "function") unsubscribe();
    };
  });

  function next() {
    if (section < 2) {
      setSection(section + 1);
    }
  }

  function prev() {
    if (section > 0) {
      setSection(section - 1);
    }
  }

  async function save(values, setSubmitting, setFieldError) {
    setError(undefined);
    try {
      // Create School & Accounts
      const school = await createSchool(values);
      // Sign in to created accounts
      initializeSchoolApp(school);
      login(values.email, values.password);
      loginOwner(values.email, values.password);
      router.push(`/${values.schoolId?.replace(/\s+/g, "-").toLowerCase()}`);
    } catch (error) {
      console.log("SchoolCreation Error", error.message, error.code); 
      if (error.code === "already-exists") {  // TODO: Handle other error cases (email in use,...)
        setFieldError("schoolId", "This School Subdomain is already in use.");
      } else {
        setError(error.message);
      }
      setSubmitting(false);
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 ">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
            <Link to="/"></Link>
            <Link to="/" className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                {/* <img
                    className="block lg:hidden h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  /> */}
                <h2 className="text-gray-900 text-xl leading-5 font-medium">Create School</h2>
                {/* <img
                    className="hidden lg:block h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                    alt="Workflow"
                  /> */}
              </div>
            </Link>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-12">
        <Formik
          initialValues={{
            email: "",
            billingPlan: "free",
            schoolName: "",
            schoolId: "",
            password: "",
            passwordConfirm: "",
            username: ""
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => save(values, setSubmitting, setFieldError)}
          validationSchema={Yup.object({
            schoolName: Yup.string().required("Required"),
            schoolId: Yup.string()
              .lowercase("Only lowercase letters")
              .matches("^[a-zA-Z0-9-]*$", 'Only lowercase letters, numbers and "-" allowed')
              .max(38, "max. 28 characters")
              .required("Required"), // TODO: Only lowercase letters
            email: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
            passwordConfirm: Yup.string()
              .required("Required")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
            username: Yup.string().required("Required")
          })}
        >
          {formik => (
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Create Account</h3>
                    <p className="mt-1 text-sm text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <SignUpForm></SignUpForm>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200"></div>
                </div>
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Create School</h3>
                    <p className="mt-1 text-sm text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <SchoolCreationForm values={formik.values}></SchoolCreationForm>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200"></div>
                </div>
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Select Plan & Billing</h3>
                    <p className="mt-1 text-sm text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <BillingForm values={formik.values}></BillingForm>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6 mt-12">
                <div className="md:col-span-1"></div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <Typography className="text-center" color="error" variant="subtitle1">{error}</Typography>
                </div>
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6 mt-12">
                <div className="md:col-span-1"></div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  {/* <Button onClick={formik.handleSubmit} variant="contained">Continue</Button> */}
                  <MyButton onClick={formik.handleSubmit} className="bg-indigo-500">
                    Create School
                  </MyButton>
                </div>
              </div>
            </div>
          )}
        </Formik>
        {/* <div className="mt-12">
          {user ? (
            <div>
              <h1>Logged in as {user.email}</h1>
              <PrimaryButton onClick={logoutOwner}>Logout</PrimaryButton>
            </div>
          ) : (
            <div>
              <Formik initialValues={{}} onSubmit={values => loginOwner(values.email, values.password)}>
                {formik => (
                  <form>
                    <FormInput name="email" label="email" type="email"></FormInput>
                    <FormInput name="password" label="password" type="password"></FormInput>
                    <PrimaryButton onClick={formik.handleSubmit}>Login</PrimaryButton>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

function SignUpForm() {
  return (
    <div className="space-y-6">
      <Field component={TextField} variant="outlined" fullWidth name="email" label="Email Address" type="text"></Field>
      <Field component={TextField} variant="outlined" fullWidth name="username" label="Username" type=""></Field>
      <Field component={TextField} variant="outlined" fullWidth name="password" label="Password" type="password"></Field>
      <Field component={TextField} variant="outlined" fullWidth name="passwordConfirm" label="Confirm Password" type="password"></Field>
    </div>
  );
}

function SignUpComponent() {
  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <img className="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <SignUpForm></SignUpForm>
        </div>
      </div>
    </div>
  );
}

function BillingForm() {
  return (
    <div className="space-y-6">
      <Field component={Select} fullWidth variant="outlined" name="billingPlan" label="Billing Plan">
        <ListItem value="free">Free Plan</ListItem>
        <ListItem value="business">Business Plan</ListItem>
        <ListItem value="custom">Custom Plan</ListItem>
      </Field>
      <Typography variant="caption">Billing is not available at this time. Please select any plan and continue.</Typography>
      {/* <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Billing Plan
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div> */}
    </div>
  );
}

function BillingComponent() {
  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <img className="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Select a billing plan</h2>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <BillingForm></BillingForm>
        </div>
      </div>
    </div>
  );
}

function SchoolCreationForm({ values }) {
  return (
    <div>
      <Field
        component={TextField}
        variant="outlined"
        fullWidth
        name="schoolId"
        label="School Subdomain"
        type="text"
        placeholder="e.g. my-school"
        InputProps={{
          startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          endAdornment: <InputAdornment position="end">.exercisable.net</InputAdornment>
        }}
      ></Field>
      {/* <FormInput
        name="schoolId"
        label="School Subdomain"
        type="text"
        placeholder="e.g. my-school"
        className="rounded-r-none rounded-l-none"
        leading={
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            http://
          </span>
        }
        trailing={
          <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            .fitness-hub.com
          </span>
        }
      ></FormInput> */}
      <Typography variant="caption" color="textSecondary">
        Your school will be available here. With the Business Plan you can also use your own domain.
      </Typography>

      <Field
        className="mt-8"
        component={TextField}
        variant="outlined"
        fullWidth
        name="schoolName"
        label="School Name"
        type="text"
        placeholder="e.g. My School"
      ></Field>
      <Typography variant="caption" color="textSecondary" className="mt-2">
        You can always change your school name later.
      </Typography>
    </div>
  );
}

function SchoolCreationComponent({ values }) {
  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <img className="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Select a billing plan</h2>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <SchoolCreationForm></SchoolCreationForm>
        </div>
      </div>
    </div>
  );
}
