import { AppBar, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { removeSlashSuffix } from "../../../../../utils/utils";
import DashboardPageContent from "../../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../../components/dashboard/dashboard-page-header";

export default function DashboardPageTabView({ title, actions, tabTitles, tabChildren, breadcrumbItems }) {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const tab = new URLSearchParams(location.search).get("tab");
    if (tab) {
      setTabIndex(parseInt(tab));
    } else {
      setTabIndex(0);
    }
  }, [location]);

  return (
    <React.Fragment>
      <DashboardPageHeader title={title} actions={actions} breadcrumbItems={breadcrumbItems}>
        <AppBar style={{ boxShadow: "none" }} position="static" color="inherit">
          <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
            {tabTitles?.map((title, index) => (
              <Tab
                key={index}
                className="focus:outline-none font-semibold"
                label={title}
                replace
                to={location => `${removeSlashSuffix(location.pathname)}?tab=${index}`}
                component={Link}
              ></Tab>
            ))}
          </Tabs>
        </AppBar>
      </DashboardPageHeader>
      <DashboardPageContent>
        {tabChildren.map((child, index) => (
          <div key={index} hidden={tabIndex !== index}>
            {child}
          </div>
        ))}
      </DashboardPageContent>
    </React.Fragment>
  );
}
