import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import DataFilterTable from "./data-filter-table";

export default function UserSelectionDialog({ onSelect, initialSelection, users, open, setOpen }) {
  let selection = [...(initialSelection ?? [])];

  const columns = [
    { id: "email", numeric: false, disablePadding: true, label: "Email", child: item => item.email },
    { id: "userName", numeric: false, disablePadding: true, label: "Username", child: item => item.userName },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Signed Up Since",
      child: item => new Date(item.createdAt).toLocaleString()
    }
  ];

  return (
    <React.Fragment>
      <Dialog fullWidth={ true } maxWidth={"lg"} open={open} onClose={() => setOpen(false)} >
        <DialogTitle>Select Users</DialogTitle>
        <DialogContent>
          <DataFilterTable
            searchableKey="email"
            selectMode
            items={users}
            columns={columns}
            initialSelection={initialSelection}
            selectionChange={res => (selection = res)}
          ></DataFilterTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={() => onSelect(selection)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
