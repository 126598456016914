import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SchoolContext } from '../../school';
import MediaItem from './media-item';
import PriceTag from './price-tag';
import Ratio from './ratio';
import MyCard from './ui-components/my-card';

const useStyles = makeStyles((theme) => ({
    // root: {
    //   width: 345,
    // },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    background: {
        position: "absolute",
        backgroundColor: "#00000080",
        left: 0,
        bottom: 0,
        right: 0,
        padding: 8
    },
    relative: {
        position: "relative"
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        margin: 8,
        lineHeight: 1.3
    }
}));


export default function RoutineCard({ routine }) {

    const school = useContext(SchoolContext);
    const router = useHistory();

    let workoutType = "Sets'n'Reps Workout";
    if (routine.isCircuit ?? false) {
      workoutType = "Circuit Workout";
    } else if (routine.isMobility ?? false) {
      workoutType = "Mobility Workout";
    }

    return <Box p={1} onClick={() => { router.push(`routine/${routine.id}`); }} style={{ cursor: "pointer" }}>
        <MyCard color="paper">
            <div style={{ position: "relative", flexGrow: 1 }}>
                <Ratio ratio={16/9}>
                    <MediaItem size="sm" media={ routine.media } onlyImage></MediaItem>
                </Ratio>
                <PriceTag style={{ position: "absolute", top: 8, right: 8 }} price="FREE"></PriceTag>
            </div>
        </MyCard>
        <Box p={1}>
            <Typography color="textPrimary" variant="h3">{routine.name}</Typography>
            <Box height={4}></Box>
            <Typography color="textSecondary" variant="body1">{ workoutType }</Typography>
        </Box>
    </Box>
}

// function RoutineCard({ routine }) {

//     const classes = useStyles();
//     const router = useRouter();

//     return (
//         <Box p={1} onClick={() => { router.push(`/routine/${routine.id}`); }} style={{ cursor: "pointer" }}>
//             <MyCard color="paper">
//                 { routine 
//                     ?   <div>
//                             <div className={classes.relative}>
//                                 <Ratio ratio={16/9}>
//                                     <MediaItem media={ routine.media } onlyImage></MediaItem>
//                                 </Ratio>
//                                 <PriceTag style={{ position: "absolute", top: 8, right: 8 }} price="FREE"></PriceTag>
//                                 <h3 className={classes.background}>{ routine.name }</h3>
//                             </div>
//                             <Typography  color="textSecondary" variant="body1" className={ classes.description }>{ routine.description }</Typography>
//                             {/* // TODO: Other Info */}
//                     </div>
//                     : <div style={{display: 'flex', justifyContent: 'center', minHeight: "150px"}}>
//                         <CircularProgress style={{ margin: "auto" }}/>
//                     </div>
//                 }
//             </MyCard>
//         </Box>
//     )
// }