import { Typography, useMediaQuery } from "@material-ui/core";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useBasePath } from "../../../../../utils/get-subdomain";
import { SchoolContext, UserTenantContext } from "../../../school";
import AuthForm from "../../components/auth";
import MobileNavbar from "../../components/mobile-navbar";
import MyCard from "../../components/ui-components/my-card";

export default function SigninPage() {
  const user = useContext(UserTenantContext);
  const school = useContext(SchoolContext);
  const router = useHistory();
  const basePath = useBasePath();

  const small = useMediaQuery(theme => theme.breakpoints.down("xs"));

  return (
    <MobileNavbar>
      {user === null && (
        <div className="flex items-center justify-center mt-24">
          {!small ? (
            <MyCard>
              <AuthForm school={school} onSuccess={() => router.replace(`/${basePath}workout`)}></AuthForm>
            </MyCard>
          ) : (
            <AuthForm school={school} onSuccess={() => router.replace(`/${basePath}workout`)}></AuthForm>
          )}
          {/* <MyCard>
            <AuthForm school={school} onSuccess={() => router.replace(`/${basePath}workout`)}></AuthForm>
          </MyCard> */}
        </div>
      )}
      {user && (
        <Typography variant="h2" color="textSecondary" className="mt-10 text-center">
          Already Logged In
        </Typography>
      )}
    </MobileNavbar>
  );
}
