import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import MediaItem from './media-item';
import PriceTag from './price-tag';
import Ratio from './ratio';
import MyCard from './ui-components/my-card';

const useStyles = makeStyles((theme) => ({
    // root: {
    //   width: 345,
    // },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    background: {
        position: "absolute",
        backgroundColor: "#00000080",
        left: 0,
        bottom: 0,
        right: 0,
        padding: 8
    },
    relative: {
        position: "relative"
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        margin: 8
    }
}));

export function ProgramCardLarge({ program }) {
    const router = useHistory();

    return <Box p={1} onClick={() => { router.push(`/program/${program.id}`); }} style={{ cursor: "pointer" }}>
        <MyCard color="paper">
            <div style={{ position: "relative", flexGrow: 1 }}>
                <Ratio ratio={3/3.5}>
                    <MediaItem size="sm" media={ program.media } onlyImage></MediaItem>
                </Ratio>
                <PriceTag style={{ position: "absolute", top: 8, right: 8 }} price="$79.99"></PriceTag>
                <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, padding: 16, paddingTop: 50, background: "linear-gradient(to top, rgba(14, 13, 13, 0.6), rgba(0, 0, 0, 0))" }}>
                    <h2>{ program.name }</h2>
                    <Box height={4}></Box>
                    <Typography color="textSecondary" variant="body1">{ program.description }</Typography>
                </div>
            </div>
        </MyCard>
    </Box>
}

export default function ProgramCard({ program }) {
    const router = useHistory();

    return <Box p={1} onClick={() => { router.push(`/program/${program.id}`); }} style={{ cursor: "pointer" }}>
        <MyCard color="paper">
            <div style={{ position: "relative", flexGrow: 1 }}>
                <Ratio ratio={16/9}>
                    <MediaItem size="sm" media={ program.media } onlyImage></MediaItem>
                </Ratio>
                <PriceTag style={{ position: "absolute", top: 8, right: 8 }} price="$79.99"></PriceTag>
            </div>
            <Box p={1}>
                <h3>{ program.name }</h3>
                <Box height={4}></Box>
                <Typography color="textSecondary" variant="body1">{ program.description }</Typography>
            </Box>
        </MyCard>
    </Box>
}
