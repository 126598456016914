import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { login, signupEmail } from "../../../../auth";
import { getLogo } from "../../../../utils/utils";
import { ThemeContext } from "../../school";

const useStyles = makeStyles(theme => ({
  submit: {
    marginTop: 8,
    marginBottom: 8,
    padding: 12,
    borderRadius: 10
  }
}));

export default function AuthForm({ school, onClose, onSuccess }) {
  const [isSignIn, setIsSignIn] = useState(true);

  const classes = useStyles();

  return (
    <div className="flex flex-col justify-center pb-12 pt-4 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {isSignIn ? (
          <LoginForm school={school} toSignUp={() => setIsSignIn(false)} close={onClose} onSuccess={onSuccess}></LoginForm>
        ) : (
          <SignUpForm school={school} toSignIn={() => setIsSignIn(true)} close={onClose} onSuccess={onSuccess}></SignUpForm>
        )}
        <div className="mt-2">
          {onClose && (
            <Button onClick={onClose} color="secondary" disableElevation fullWidth className={classes.submit}>
              Skip for now
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function SignUpForm({ toSignIn, school, close, onSuccess }) {
  const [error, setError] = useState();
  const classes = useStyles();
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  return (
    <div>
      {/* <MyCard className="py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
      <Box>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {school?.config?.icon && <img className="h-12 w-auto mx-auto m-8" src={getLogo(school, isDarkTheme)} alt="Logo" />}
          {/* <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}
          <Typography variant="h1" className="text-center mt-2">
            Sign Up
          </Typography>
          <a onClick={toSignIn}>
            <Typography variant="subtitle1" color="primary" className="text-center mt-2 cursor-pointer">
              Already have an account? Sign In
            </Typography>
          </a>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
            passwordConfirm: "",
            username: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email().required("Required"),
            password: Yup.string().min(6, "Must be at least 6 characters long").required("Required"),
            passwordConfirm: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match"),
            username: Yup.string().required("Required")
          })}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const res = await signupEmail({ schoolId: school.id, ...values });
              if (onSuccess) onSuccess();
              close();
            } catch (error) {
              if (error.code === "auth/email-already-in-use") {
                setFieldError("email", "The Email address is already in use");
              } else {
                setError("An error occurred, please try again");
              }
              setSubmitting(false);
            }
          }}
        >
          {formik => (
            <div className="space-y-6 mt-6">
              <Field component={TextField} variant="outlined" fullWidth name="username" label="Username" type="text"></Field>

              {/* <FormInput name="email" label="Email address" autoComplete="email" type="email"></FormInput> */}

              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                name="email"
                label="Email address"
                autoComplete="email"
                type="email"
              ></Field>

              <Field component={TextField} variant="outlined" fullWidth name="password" label="Password" type="password"></Field>

              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
              ></Field>

              {error && (
                <Typography variant="subtitle1" className="text-center text-red-500">
                  {error}
                </Typography>
              )}

              <div className="mt-4 ">
                <Button
                  onClick={formik.handleSubmit}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  fullWidth
                  className={classes.submit}
                >
                  Sign In
                </Button>
                {/* <button
                  onClick={formik.handleSubmit}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign Up
                </button>*/}
              </div>
            </div>
          )}
        </Formik>
      </Box>
      {/* </MyCard> */}
    </div>
  );
}

function LoginForm({ toSignUp, close, onSuccess, school }) {
  const [error, setError] = useState();
  const classes = useStyles();
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      {/* <MyCard className="py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
      <div className="sm:mx-auto sm:w-full sm:max-w-md mb-12">
        {/* <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}
        {school?.config?.icon && <img className="h-12 w-auto mx-auto m-8" src={getLogo(school, isDarkTheme)} alt="Logo" />}
        <Typography variant="h1" className="text-center mt-2">
          Sign In
        </Typography>
        <a onClick={toSignUp}>
          <Typography variant="subtitle1" color="primary" className="text-center mt-2 cursor-pointer">
            Don't have an account? Sign Up
          </Typography>
        </a>
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().email().required("Required"),
          password: Yup.string().required("Required")
        })}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            await login(values.email, values.password);
            if (onSuccess) onSuccess();
            close();
          } catch (error) {
            console.log(error);
            if (error.code === "auth/user-not-found") {
              setFieldError("email", "Email address not registered");
            } else if (error.code === "auth/wrong-password") {
              setFieldError("password", "Wrong password");
            } else {
              setError("An error occurred, please try again");
            }
            setSubmitting(false);
          }
        }}
      >
        {formik => (
          <div className="space-y-6 mt-6">
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              name="email"
              label="Email address"
              autoComplete="email"
              type="email"
            ></Field>
            <Field component={TextField} variant="outlined" fullWidth name="password" label="Password" type="password"></Field>

            <div className="flex items-end justify-end">
              <a href="#">
                <Typography className="text-sm font-medium text-right w-full" variant="body2" color="primary">
                  Forgot your password?
                </Typography>
              </a>
            </div>

            {error && (
              <Typography variant="subtitle1" className="text-center text-red-500">
                {error}
              </Typography>
            )}

            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <div>
              <button
                type="submit"
                onClick={formik.handleSubmit}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign In
              </button>
            </div> */}
          </div>
        )}
      </Formik>
      {/* </MyCard> */}
    </div>
  );
}
