import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles(theme => ({
    box: {
        borderRadius: 15,
        backgroundColor: theme.palette.secondary.main,
        padding: 2,
        paddingLeft: 8,
        paddingRight: 8
    },
    price: {
        fontWeight: 600,
        color: "white"
    }
}));

export default function PriceTag({ price, ...props }) {

    const classes = useStyle();

    const priceStr = `$${price / 100}`

    return <div className={ classNames(classes.box, "absolute right-2 top-2 z-10") } style={ props.style }>
        <Typography className={ classes.price }>{ priceStr }</Typography>
    </div>
}