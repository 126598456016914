import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import { assignRoutine, editRoutine, fetchRoutine, removeAssignedRoutine } from "../../../../../db";
import { SchoolContext } from "../../../school";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import { ContentList, EditContentItemPage } from "../../school/content-form";
import AvailabilityForm from "./availability-form";
import AdminExerciseEditPage from "./edit-exercise-page";
import ExerciseForm from "./exercise-form";
import GeneralForm from "./general-form";

const routineDefaultValues = {
  type: "sets",
  thumbnail: {
    isExplanation: false,
  },
  media: {
    isExplanation: true,
    isYoutubeVideo: false
  },
  tutor: {
    media: {}
  },
  exercises: [],
  isCircuit: false
};

export default function AdminEditRoutinePage({ basePath }) {
  const [routine, setRoutine] = useState();
  const school = useContext(SchoolContext);
  const router = useHistory();

  const { id } = useParams();

  const formikRef = useRef();

  let isPreview;

  const breadcrumbItems = [
    { title: "Workouts", link: `${basePath}admin/workouts/` },
    { title: routine?.name, link: `${basePath}admin/workouts/${id}` }
  ];

  useEffect(() => {
    if (school) {
      if (id === "new") {
        setRoutine(routineDefaultValues);
      } else {
        fetchRoutine(school.id, id).then(res => {
          if (!res.thumbnail) {
            res.thumbnail = {}
          }
          setRoutine(res)
        });
      }
    }
  }, [id, school]);

  function onSave() {
    isPreview = false;
    formikRef.current.handleSubmit();
  }

  function onPreview() {
    isPreview = true;
    formikRef.current.handleSubmit();
  }

  async function save(values) {
    console.log("Saving Routine", values);
    console.log("Apply Users", routine.assignedUsers, values.assignedUsers)
    try {
      const res = await editRoutine(school.id, values);
      const deleteUser = routine.assignedUsers?.filter(x => !(values.assignedUsers?.includes(x)));
      const addUsers = values.assignedUsers?.filter(x => !(routine.assignedUsers?.includes(x)));
      if (!res?.id) {
        throw "No ID";
      }
      await Promise.all([
        ...addUsers?.map(uid => assignRoutine(school.id, uid, res.id)),
        ...deleteUser?.map(uid => removeAssignedRoutine(school.id, uid, res.id)),
      ])
      router.push(`/${school.id}/admin/workouts`); // TODO:
    } catch (error) {
      console.error(error);
    }
  }

  async function preview(values) {
    const preview = Object.assign({}, values, {
      id: "Preview",
      visibility: "private" // TODO: is this correct after implementing visibility?
    });
    await editRoutine(school.id, preview);
    window.open(`${window.location.origin}/${school.id}/routine/Preview`, "_blank"); // TODO:
  }

  if (routine) {
    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={routine}
          validationSchema={Yup.object({
            name: Yup.string().max(38, "max. 28 characters").required("Required")
          })}
          onSubmit={values => {
            console.log("Submitting Form", values);
            if (isPreview) {
              preview(values);
            } else {
              save(values);
            }
          }}
        >
          {formik => {
            function applyExerciseEdit(newExercise) {
              const index = formik.values.exercises.findIndex(exercise => exercise.id === newExercise.id);
              if ((index ?? -1) < 0) return;

              const newExercises = formik.values.exercises;
              newExercises[index] = newExercise;

              formik.setFieldValue("exercises", newExercises);
            }

            return (
              <Switch>
                <Route path={`${basePath}admin/workouts/${id}/item/:itemId`}>
                  <EditContentItemPage
                    items={formik.values.contentItems}
                    breadcrumbItems={[...breadcrumbItems, { title: "Instructions", link: `${basePath}admin/workouts/${id}?tab=2` }]}
                    applyEdit={newItem => {
                      const index = formik.values.contentItems.findIndex(item => item.id === newItem.id);
                      if ((index ?? -1) < 0) return;

                      const newItems = formik.values.contentItems;
                      newItems[index] = newItem;

                      formik.setFieldValue("contentItems", newItems);
                    }}
                  ></EditContentItemPage>
                </Route>
                <Route path={`${basePath}admin/workouts/${id}/exercise/:exerciseId`}>
                  <AdminExerciseEditPage
                    path={`${basePath}admin/workouts/${id}/exercise`}
                    routine={formik.values}
                    save={applyExerciseEdit}
                    breadcrumbItems={[...breadcrumbItems, { title: "Exercises", link: `${basePath}admin/workouts/${id}?tab=1` }]}
                  ></AdminExerciseEditPage>
                </Route>
                <Route path={`${basePath}admin/workouts/${id}`}>
                  <DashboardPageTabView
                    breadcrumbItems={breadcrumbItems}
                    title={routine ? `Edit ${routine?.name}` : "Create New Workout"}
                    actions={
                      <div>
                        <SecondaryButton className="mr-4" onClick={onPreview}>
                          Preview
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={() => {
                            isPreview = false;
                            formik.handleSubmit();
                          }}
                        >
                          Save
                        </PrimaryButton>
                      </div>
                    }
                    tabTitles={["General", "Exercises"]}
                    // tabTitles={["General", "Exercises", "Instructions"]}
                    tabChildren={[
                      <div>
                        <GeneralForm formik={formik}></GeneralForm>
                        <AvailabilityForm formik={formik}></AvailabilityForm>
                      </div>,
                      <div>
                        <ExerciseForm formik={formik}></ExerciseForm>
                      </div>
                      // <div>
                      //   <ContentList formik={formik} path={`${basePath}admin/workouts/${id}/item`}></ContentList>
                      // </div>
                    ]}
                  ></DashboardPageTabView>
                </Route>
              </Switch>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
  return <React.Fragment></React.Fragment>;
}
