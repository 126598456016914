import { Box, Icon, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchProgram, fetchRoutine } from "../../../../db";
import { useBasePath } from "../../../../utils/get-subdomain";
import { SchoolContext } from "../../school";
import { PhaseCalendarWeek, statusToCalendarWeeks } from "./program-page/program-phase-section";
import MyCard from "./ui-components/my-card";
import Row from "./ui-components/row";

const useStyle = makeStyles(theme => ({
  progressRoot: {
    position: "relative"
  },
  progress: {
    height: 20,
    borderRadius: 5,
  },
  phaseCount: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 8,
    lineHeight: "20px",
    zIndex: 1,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  dayCount: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 8,
    lineHeight: "20px",
    zIndex: 1,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  title: {
    flexGrow: 1,
    cursor: "pointer"
  }
}));

export default function ProgramStatus({ status, initialProgram, showLink, ...props }) {
  const classes = useStyle();
  const router = useHistory();

  const [program, setProgram] = useState(initialProgram);
  const school = useContext(SchoolContext);
  const basePath = useBasePath();
  const [routineMap, setRoutineMap] = useState();


  useEffect(() => {
    if (!program && status) {
      fetchProgram(school.id, status.programId).then(res => setProgram(res));
    }
  }, [school]);

  let currentWeek;
  if (status) {
    const weeks = statusToCalendarWeeks(status, status.currentPhaseIndex);
    currentWeek = weeks.find(week => week.some(day => day.index == status.currentDay));
  }

  useEffect(() => {
    if (!school) return;
    if (!currentWeek) return;

    const routinesSet = new Set();
    const routineMap = {};
    currentWeek.forEach(day => {
      if (day?.routineId) {
        routinesSet.add(day.routineId);
      }
    });
    Promise.all([...routinesSet].map(id => fetchRoutine(school.id, id))).then(routines => {
      routines.forEach(routine => (routineMap[routine.id] = routine));
      setRoutineMap(routineMap);
    });
  }, [school, status]);


  return (
    <MyCard style={props.style}>
      <Box className="px-3 pt-3">
        <Box
            className="pb-3"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (showLink) router.push(`/${basePath}program/${status.programId}`);
          }}
        >
          <Row>
            <Typography variant="h2" className={showLink ? classes.title : ""}>
              {program ? program.name : ""}
            </Typography>
            {showLink && <Icon>navigate_next</Icon>}
          </Row>
        </Box>
        <div className={classes.progressRoot}>
          <p className={classes.phaseCount}>{status.phases[status.currentPhaseIndex].name}</p>
          <p className={classes.dayCount}>
            Day {status.currentDay + 1}/{status.phases[status.currentPhaseIndex].schedule.length}
          </p>
          <LinearProgress className={classes.progress} variant={"determinate"} value={(status.currentDay / status.phases[status.currentPhaseIndex].schedule.length) * 100} ></LinearProgress>
        </div>
      </Box>
      <PhaseCalendarWeek showWeekDays week={currentWeek} selected={true} onSelected={() => {}} routineMap={routineMap} align="justify-between" phaseIndex={status.currentPhaseIndex} programId={status.programId}></PhaseCalendarWeek>
    </MyCard>
  );
}
