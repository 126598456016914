import React from 'react';
import YouTube from 'react-youtube';


export const MISSING_ROUTINE_THUMBNAIL_URL = "/images/jump.webp";    // TODO:
// export const MISSING_ROUTINE_THUMBNAIL_URL = "assets/images/pullup/pullup-md.webp";    // TODO:
// export const MISSING_ROUTINE_THUMBNAIL_URL = '/assets/lvl2.jpg';    // TODO:

export default function MediaItem({ media, showFallback = true, onlyImage  = false, size, ...props }) {

    const opts = {
        playerVars: {
            width: "100%",
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    if (!media) {
        return fallback(showFallback) 
    }

    if ((onlyImage || !media.isVideo) && media.imageUrl) {
        return <img style={{ display: "block", width: "100%", height: "100%", objectFit: "cover" }} src={media.imageUrl}></img>
    } else {
        if (media.isYoutubeVideo && media.youtubeId) {
            return <YouTube videoId={ media.youtubeId } opts={opts} containerClassName={"youtubeContainer"} />;
        } else if (media.videoUrl) {
            return <video style={{ display: "block" }} width="100%" autoPlay loop muted controls={media.isExplanation}>
                <source src={media.videoUrl} type="video/mp4"/>
                <source src={media.videoUrl} type="video/webm"/>
                <source src={media.videoUrl} type="video/ogg"/>
                Video Tag is not supported in your browser
            </video>
        } else {
            // Hack: Remove this:
            if (media.isExplaination && media.isVideo && media.youtubeId) {
                return <YouTube videoId={ media.youtubeId } opts={opts} containerClassName={"youtubeContainer"} />;
            }
        }
    }

    // TODO: Default Item
    return fallback(showFallback);
    // if (media.isExplanation && !onlyImage) {
    //     if (media.youtubeId) {
    //         return <YouTube videoId={ media.youtubeId } opts={opts} containerClassName={"youtubeContainer"} />;
    //     }
    // } else if (!media.isVideo || onlyImage) {
    //     if (media.imageUrl) {
    //         return <img style={{ display: "block", width: "100%", height: "100%", objectFit: "cover" }} src={media.imageUrl}></img>
    //     }
    //     return fallback(showFallback);
    // } else {
    //     if (media.videoUrl) {
    //         if (!media.isExplanation) {
    //             return <video style={{ display: "block" }} width="100%" autoPlay loop muted>
    //                 <source src={media.videoUrl} type="video/mp4"/>
    //                 <source src={media.videoUrl} type="video/ogg"/>
    //                 Video Tag is not supported in your browser
    //             </video>
    //         } else {
    //             if (media.youtubeId) {
                    
    //                 return <YouTube videoId={ media.youtubeId } opts={opts} containerClassName={"youtubeContainer"} />;
    //             } else {
    //                 // TOOD: Vimeo Player?
    //                 return <video style={{ display: "block" }} width="100%" controls loop>
    //                     <source src={media.videoUrl} type="video/mp4"/>
    //                     <source src={media.videoUrl} type="video/ogg"/>
    //                     Video Tag is not supported in your browser
    //                 </video>
    //             }
    //         }
    //     } else {
    //         return fallback(showFallback);
    //     }
    // }
}

function fallback(showFallback) {
    if (showFallback === true) {
        // return <Image size="sm" name="pullup"></Image>
        return <img style={{ display: "block", objectFit: "cover", height: "100%" }} width="100%" src={MISSING_ROUTINE_THUMBNAIL_URL}></img>
    } else {
        return <div></div>
    }
}