import { Field, FieldArray, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import { removeSlashSuffix } from "../../../../../utils/utils";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import { ExerciseDnDList } from "../../components/dashboard/dnd-list";
import { EditContentItemRoute } from "../../school/content-form";
import BaseExerciseForm from "./edit-exercise";
import { initExercise } from "./exercise-form";

function NestedExercise({ alternatingExercise, applyEdit, path, breadcrumbItems }) {
  const { nestedExerciseId } = useParams();
  const exercise = alternatingExercise.exercises.find(exercise => exercise.id === nestedExerciseId);

  return <BaseExerciseForm exercise={exercise} applyEdit={applyEdit} path={path} breadcrumbItems={breadcrumbItems}></BaseExerciseForm>;
}

export default function AlternatingExerciseForm({ exercise, applyEdit, path, breadcrumbItems }) {
  const router = useHistory();

  breadcrumbItems = [ ...breadcrumbItems, { title: exercise.name, link: `${path}/${exercise.id}` } ]


  return (
    <Formik
      initialValues={exercise}
      validationSchema={Yup.object({
        name: Yup.string().required("Required")
      })}
      onSubmit={values => {
        applyEdit(values);
        router.goBack();
      }}
    >
      {formik => {
        return (
          <Switch>
            <Route path={`${path}/${exercise.id}/item/:itemId`}>
              <EditContentItemRoute path={`${path}/${exercise.id}`} formik={formik} breadcrumbItems={breadcrumbItems}></EditContentItemRoute>
            </Route>
            <Route path={`${path}/${exercise.id}/exercise/:nestedExerciseId`}>
              <NestedExercise
                alternatingExercise={formik.values}
                path={`${path}/${exercise.id}/exercise`}
                breadcrumbItems={[ ...breadcrumbItems, { title: "Exercises", link: `${path}/${exercise.id}?tab=1` } ]}
                applyEdit={newExercise => {
                  const index = formik.values.exercises.findIndex(exercise => exercise.id === newExercise.id);
                  if ((index ?? -1) < 0) return;

                  const newExercises = formik.values.exercises;
                  newExercises[index] = newExercise;

                  formik.setFieldValue("exercises", newExercises);
                }}
              ></NestedExercise>
            </Route>
            <Route path={`${path}/${exercise.id}`}>
              <div>
                <DashboardPageTabView
                  title="Edit Alternating Exercise Group"
                  actions={<SecondaryButton onClick={formik.handleSubmit}>Done</SecondaryButton>}
                  tabTitles={["General", "Exercises",]}
                  breadcrumbItems={breadcrumbItems}
                  tabChildren={[
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label="Exercise Name"
                      name="name"
                      type="text"
                      placeholder="Exercise name"
                    ></Field>,
                    <FieldArray name="exercises">
                      {({ remove, push }) => (
                        <div>
                          <ExerciseDnDList
                            formik={formik}
                            name="exercises"
                            remove={remove}
                            onEdit={item => router.push(`${removeSlashSuffix(router.location.pathname)}/exercise/${item.id}`)}
                          ></ExerciseDnDList>
                          <PrimaryButton onClick={() => push(initExercise())}>Add Exercise</PrimaryButton>
                        </div>
                      )}
                    </FieldArray>,
                    // <ContentList formik={formik} path={`${path}/${exercise.id}/item`}></ContentList>
                  ]}
                ></DashboardPageTabView>
              </div>
            </Route>
          </Switch>
        );
      }}
    </Formik>
  );
}
