import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../../../../components/tailwind-components/buttons";
import { editProgram, fetchProgram, removeAssignedProgram, assignProgram } from "../../../../../db";
import { generateUniqueId } from "../../../../../utils/utils";
import { SchoolContext } from "../../../school";
import DashboardPageTabView from "../../components/dashboard/dashboard-page-tab-view";
import AvailabilityForm from "../../routines/edit-routine/availability-form";
import { ContentList, EditContentItemRoute } from "../../school/content-form";
// import ProgramAvailabilityForm from "./availability-form";
import AdminEditSectionPage from "./edit-section";
import ProgramGeneralForm from "./general-form";
import AdminEditSectionList from "./section-list";

const createProgramModel = () => {
  return {
    id: generateUniqueId(),
    createdAt: new Date().getTime(),
    media: {
      isExplanation: false,
      isYoutubeVideo: false,
    },
    thumbnail: {
      
    }
  }
}

export default function AdminEditProgramPage({ basePath }) {
  const [program, setProgram] = useState();
  const school = useContext(SchoolContext);
  const router = useHistory();

  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const formikRef = useRef();

  let isPreview;

  const breadcrumbItems = [
    { title: "Programs", link: `${basePath}admin/programs/` },
    { title: program?.name ?? "New Program", link: `${basePath}admin/programs/${id}` }
  ];

  useEffect(() => {
    if (school) {
      if (id === "new") {
        setProgram(createProgramModel());
      } else {
        fetchProgram(school.id, id).then(res => setProgram(res));
      }
    }
  }, [id, school]);

  function onSave() {
    isPreview = false;
    formikRef.current.handleSubmit();
  }

  function onPreview() {
    isPreview = true;
    formikRef.current.handleSubmit();
  }

  async function save(values) {
    try {
      await editProgram(school.id, values);
      const deleteUser = program.assignedUsers?.filter(x => !(values.assignedUsers?.includes(x)));
      const addUsers = values.assignedUsers?.filter(x => !(program.assignedUsers?.includes(x)));
      await Promise.all([
        ...addUsers.map(uid => assignProgram(school.id, uid, program.id)),
        ...deleteUser.map(uid => removeAssignedProgram(school.id, uid, program.id)),
      ])
      router.push(`/${school.id}/admin/programs`); // TODO:
    } catch (error) {
      console.log("error", error);
    }
  }

  async function preview(values) {
    const preview = Object.assign({}, values, {
      id: "Preview",
      visibility: "private" // TODO: is this correct after implementing visibility?
    });
    await editProgram(school.id, preview);
    window.open(`${window.location.origin}/${school.id}/program/Preview`, "_blank"); // Todo:
  }

  if (!school || !program) return <div>loading...</div>;

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={program}
      validationSchema={Yup.object({
        name: Yup.string().max(38, "max. 28 characters").required("Required")
      })}
      onSubmit={values => {
        console.log("Submitting Form", values);
        if (isPreview) {
          preview(values);
        } else {
          save(values);
        }
      }}
    >
      {formik => (
        <Switch>
          <Route path={`${basePath}admin/programs/${id}/item/:itemId`}>
            <EditContentItemRoute
              formik={formik}
              breadcrumbItems={[...breadcrumbItems, { title: "Overview", link: `${basePath}admin/programs/${id}` }]}
            ></EditContentItemRoute>
          </Route>
          <Route path={`${basePath}admin/programs/${id}/section/:sectionId`}>
            <AdminEditSectionPage
              school={school}
              program={formik.values}
              path={`${basePath}admin/programs/${id}/section`}
              breadcrumbItems={[...breadcrumbItems, { title: "Sections", link: `${basePath}admin/programs/${id}?tab=1` }]}
              applyEdit={section => {
                const sections = formik.values.sections ?? [];
                const index = sections.findIndex(s => s.id === section.id);
                if (index >= 0) {
                  sections[index] = section;
                } else {
                  sections.push(section);
                }
                formik.setFieldValue("sections", sections);
              }}
            ></AdminEditSectionPage>
          </Route>
          <Route path={`${basePath}admin/programs/${id}`}>
            <DashboardPageTabView
              breadcrumbItems={breadcrumbItems}
              title={program ? `Edit ${program?.name}` : "Create New Program"}
              actions={
                <div>
                  <SecondaryButton className="mr-4" onClick={onPreview}>
                    Preview
                  </SecondaryButton>
                  <PrimaryButton onClick={onSave}>Save</PrimaryButton>
                </div>
              }
              tabTitles={["General", "Sections"]}
              tabChildren={[
                <div>
                  <ProgramGeneralForm formik={formik}></ProgramGeneralForm>
                  <AvailabilityForm formik={formik} isProgram></AvailabilityForm>
                  <ContentList formik={formik} path={`${basePath}admin/programs/${id}/item`}></ContentList>
                </div>,
                <div>
                  {/* <MyCard className="p-4 my-2">
                    <div className="flex justify-between items-start flex-wrap">
                      <div className="flex items-center">
                        <div className="ml-4 mt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Overview</h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Overview Section (Cannot be moved or deleted)
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="ml-4 mt-4 flex-shrink-0">
                          <PrimaryButton onClick={() => console.log("ASdasd")}>Edit</PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </MyCard> */}
                  <AdminEditSectionList formik={formik}></AdminEditSectionList>
                </div>
              ]}
            ></DashboardPageTabView>
          </Route>
        </Switch>
      )}
    </Formik>
  );
}
