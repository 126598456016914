import { Box, Collapse, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { REP_CHALLENGE_TYPE, TIME_CHALLENGE_TYPE } from '../../../../../exercise-types';
import { getSelectedProgression } from '../../../../../utils/routine-utils';
import { EMOM_REST_EXPLANATION_MESSAGE, EMOM_REST_EXPLANATION_TITLE, REP_CHALLENGE_EXPLANATION_MESSAGE, REP_CHALLENGE_EXPLANATION_TITLE, TIME_CHALLENGE_EXPLANATION_MESSAGE, TIME_CHALLENGE_EXPLANATION_TITLE } from '../../../../../utils/text-values';
import { ExerciseCardTitle, ExerciseTitle } from "../exercise-card";
import ExerciseInfo from "../exercise-info";
import ExerciseStateTimings from '../exercise-state-timings';
import HelpItem from '../help-item';
import MediaItem from '../media-item';
import Ratio from '../ratio';
import TutorButton from '../tutor/tutor';
import MyCard from "../ui-components/my-card";

const useStyle = makeStyles(theme => ({
    subtitle: {
        fontSize: "14px",
        color: theme.palette.text.secondary
    },
    
}))

export default function BaseExercisePreview({ exercise, expanded, color, selectProgression }) {

    const classes = useStyle();

    const [progression, setProgression] = useState();

    const sets = exercise.sets;
    let exerciseExplanation;
    if (exercise.type === REP_CHALLENGE_TYPE) {
        exerciseExplanation = <HelpItem title={ REP_CHALLENGE_EXPLANATION_TITLE } message={ REP_CHALLENGE_EXPLANATION_MESSAGE }>
            <p className="oneLineMax">Rep Challenge</p>
        </HelpItem>
    } else if (exercise.type === TIME_CHALLENGE_TYPE) {
        exerciseExplanation = <HelpItem  title={ TIME_CHALLENGE_EXPLANATION_TITLE } message={  TIME_CHALLENGE_EXPLANATION_MESSAGE}>
            <p className="oneLineMax">Time Challenge</p>
        </HelpItem>
    } else if (exercise.isEMOM === true) {
        exerciseExplanation = <HelpItem title={ EMOM_REST_EXPLANATION_TITLE } message={ EMOM_REST_EXPLANATION_MESSAGE }>
            <p className="oneLineMax">EMOM Exercise</p>   
        </HelpItem>
    }

    return (
        <MyCard color="paper">
            <ExerciseCardTitle
                setCount={sets}
                exerciseColor={color}
                exerciseTitle={
                    <ExerciseTitle
                        title={ getSelectedProgression(exercise) ? getSelectedProgression(exercise).name : exercise.name }
                        subtitle={ getSelectedProgression(exercise) && !(getSelectedProgression(exercise).isDefault) ? exercise.name : undefined }
                        exerciseExplanation={exerciseExplanation}
                    ></ExerciseTitle>
                }
                trailing={ <TutorButton exercise={ exercise } selectProgression={ (progId) => { setProgression(progId); selectProgression(progId); } }></TutorButton> }
            ></ExerciseCardTitle>
            <Collapse in={ expanded } collapsedHeight={0}>
                { body() }
            </Collapse>
        </MyCard>
    )


    function body() {
        return (
            // TODO: Check / Optimize -> Are videos that are currently collapsed still loading?
            <div>
                <div style={{ position: "relative" }}>
                    <Ratio ratio={16/9}>
                        <MediaItem media={exercise.media}></MediaItem>
                    </Ratio>
                    <div style={{ position: "absolute", top: 8, left: 0, right: 0 }}>
                        <ExerciseStateTimings exercise={exercise}></ExerciseStateTimings>
                    </div>
                </div>
                <Box paddingX={1} paddingBottom={1} paddingTop={0.5}>
                    <ExerciseInfo exercise={exercise}></ExerciseInfo>
                </Box>
            </div>
        )
    }
}



