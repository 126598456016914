import { Checkbox, Select, TextField } from "formik-material-ui";
import { Field, Formik } from "formik";
import FormCard from "../../../../../components/tailwind-components/form-card";
import { FormControlLabel, MenuItem } from "@material-ui/core";
import MediaItemInput from "../../components/media-item-input";

export default function GeneralForm({ formik }) {
  return (
    <FormCard title="Meta Information">
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Workout Name"
        name="name"
        type="text"
        placeholder="Workout Name"
      ></Field>

      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Subtitle"
        name="subtitle"
        type="text"
        placeholder="E.g. Circuit Workout, Yoga Routine, ..."
      ></Field>

      <Field component={Select} fullWidth variant="outlined" label="Workout Type" name="type">
        <MenuItem value="sets">Sets and Reps</MenuItem>
        <MenuItem value="circuit">Circuit</MenuItem>
      </Field>

      {/* REPLACE */}
      <FormControlLabel
        label="isCircuit"
        control={<Field component={Checkbox} type="checkbox" fullWidth variant="outlined" name="isCircuit"></Field>}
      ></FormControlLabel>

      { formik.values.type === "circuit" && <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Circuit Rounds"
        name="rounds"
        type="number"
        placeholder="Circuit Rounds"
      ></Field>}

      {/* TODO: Duration Input */}
      <Field component={TextField} fullWidth variant="outlined" label="Duration" name="duration" type="text" placeholder="45:00"></Field>

      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        label="Description"
        type="text"
        name="description"
        multiline
        rows="3"
        placeholder="Brief description of your workout"
      ></Field>

      {/* TODO: Multiselect and option to create custom categories */}
      {/* <Field component={Select} fullWidth variant="outlined" name="categories" label="Categories">
        <option>Full Body</option>
        <option>Upper Body</option>
        <option>Lower Body</option>
      </Field> */}

      <MediaItemInput object={"thumbnail"} formik={formik} label="Thumbnail" imageOnly></MediaItemInput>
      <MediaItemInput object={"media"} formik={formik} label="Video Banner (Optional)" videoOnly isExplanation></MediaItemInput>
    </FormCard>
  );
}
