import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { createContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { initializeSchoolApp, listenUserTenantChanges } from "../../auth";
import { fetchSchool } from "../../db";
import { getSubdomain } from "../../utils/get-subdomain";
import AdminPage from "./admin/admin";
import MobileNavbar from "./main/components/mobile-navbar";
import SchoolNavBar from "./main/components/school-navbar";
import { darkTheme, lightTheme } from "./main/components/theme";
import HomePage from "./main/pages/home";
import ProfilePage from "./main/pages/profile";
import ProgramPage from "./main/pages/program/program";
import WorkoutPreview from "./main/pages/routine/routine";
import ShopPage from "./main/shop";
import WorkoutPage from "./main/pages/workout/workout-page";
import WorkoutsPage from "./main/workouts";
import WorkoutLogPage from "./main/pages/workout-log/workout-log";
import SigninPage from "./main/pages/signin/signin";
import LoginDialog from "./main/components/login-dialog";
import {Helmet} from "react-helmet";

export const SchoolContext = createContext();
export const ThemeContext = createContext();
export const UserTenantContext = createContext();
export const LoginDialogContext = createContext();

export default function SchoolPage() {
  const subdomain = getSubdomain();

  let { id } = useParams();
  id = id?.replace(/\s+/g, "-").toLowerCase();

  const router = useHistory();

  const [school, setSchool] = useState();
  const [user, setUser] = useState();
  const darkModeStorage =
    typeof JSON.parse(localStorage.getItem("flutter.USE_DARK_MODE")) === "boolean"
      ? JSON.parse(localStorage.getItem("flutter.USE_DARK_MODE"))
      : undefined;
  const [isDarkTheme, setDarkTheme] = useState(darkModeStorage ?? window.matchMedia("(prefers-color-scheme: dark)").matches);
  const [isLoginOpen, setLoginOpen] = useState(false);

  const schoolId = subdomain ? subdomain : id;

  useEffect(() => {
    let unsubscribe;
    fetchSchool(schoolId).then(school => {
      console.log("School fetched", school);
      setSchool(school);

      if (school?.tenantId) {
        initializeSchoolApp(school);
        unsubscribe = listenUserTenantChanges(school.id, userData => setUser(userData));
      }
    });

    return () => {
      if (unsubscribe && typeof unsubscribe === "function") unsubscribe();
    };
  }, [schoolId]);

  const prefix = subdomain ? "/" : `/${id}/`;

  return (
    <ThemeContext.Provider value={{ setDarkTheme: setDarkTheme, isDarkTheme: isDarkTheme }}>
      <SchoolContext.Provider value={school}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{school?.config?.name ?? "Exercisable School"}</title>
          <meta name="description" content={school?.config?.description ?? "Workout Programs to the next Level. Follow Workouts with our virtual personal trainer and track your progress"} />
          <link rel="icon" href={school?.config?.favicon ?? "/assets/icons/icon-border.svg"}/>
          {/* <link rel="icon" href={school?.config?.icon ?? "/assets/icons/icon-border.svg"}/> */}
          <meta property="og:title" content={school?.config?.name ?? "Exercisable School"}></meta>
          <meta
            property="og:description"
            content={
              school?.config?.description ??
              "Workout Programs to the next Level. Follow Workouts with our virtual personal trainer and track your progress"
            }
          ></meta>
          <meta property="og:image" content={school?.config?.avatar ?? "/assets/icons/icon-border.svg"}></meta>
          <meta property="og:url" content={window.location.pathname}></meta>
        </Helmet>
        <UserTenantContext.Provider value={user}>
          <ThemeProvider theme={isDarkTheme ? createMuiTheme(darkTheme) : createMuiTheme(lightTheme)}>
            <LoginDialogContext.Provider value={value => setLoginOpen(value)}>
              <LoginDialog opened={isLoginOpen} close={() => setLoginOpen(false)}></LoginDialog>
              <CssBaseline></CssBaseline>
              {/* <HomePage></HomePage> */}
              <Switch>
                <Route path={`${prefix}workout`}>
                  <WorkoutPage></WorkoutPage>
                </Route>
                <Route path={`${prefix}program/:programId`}>
                  <ProgramPage></ProgramPage>
                </Route>
                <Route path={`${prefix}admin/`}>
                  <AdminPage basePath={prefix}></AdminPage>
                </Route>
                <Route
                  // exact
                  path={[
                    `${prefix}program/:programId`,
                    `${prefix}routine/:routineId`,
                    `${prefix}workouts`,
                    `${prefix}shop`,
                    `${prefix}profile`,
                    prefix
                  ]}
                >
                  <SchoolNavBar>
                    <Route path={`${prefix}signin`}>
                      <SigninPage></SigninPage>
                    </Route>
                    <Route path={`${prefix}log/:logId`}>
                      <WorkoutLogPage></WorkoutLogPage>
                    </Route>
                    <Route path={`${prefix}routine/:routineId`}>
                      <WorkoutPreview path={prefix}></WorkoutPreview>
                    </Route>
                    <Route path={`${prefix}workouts`}>
                      <MobileNavbar value={1}>
                        <WorkoutsPage></WorkoutsPage>
                      </MobileNavbar>
                    </Route>
                    {/* <Route path={`${prefix}shop`}>
                          <ShopPage></ShopPage>
                        </Route> */}
                    <Route path={`${prefix}profile`}>
                      <MobileNavbar value={2}>
                        <ProfilePage></ProfilePage>
                      </MobileNavbar>
                    </Route>
                    <Route exact path={prefix}>
                      <MobileNavbar value={0}>
                        <HomePage></HomePage>
                      </MobileNavbar>
                    </Route>
                  </SchoolNavBar>
                </Route>
              </Switch>
            </LoginDialogContext.Provider>
          </ThemeProvider>
        </UserTenantContext.Provider>
      </SchoolContext.Provider>
    </ThemeContext.Provider>
  );
}
