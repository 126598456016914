import { Dialog, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useContext, useState } from "react";
import { SchoolContext } from "../../school";
import AuthForm from "./auth";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "650px"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 22,
      paddingTop: 16
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%"
  },
  submit: {
    marginTop: 8,
    marginBottom: 8,
    padding: 12,
    borderRadius: 10
    // margin: theme.spacing(3, 0, 2),
  },
  pagination: {
    // position: "-webkit-sticky", /* Safari */
    // position: "fixed",
    position: "absolute",
    bottom: 8,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      bottom: 22
    }
  },
  item: {
    backgroundColor: theme.palette.foreground.default,
    border: "none",
    margin: 4
  },
  active: {
    margin: 4,
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: "none !important"
  },
  backgroundImg: {
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(/assets/images/jump.webp)",
    backgroundSize: "cover",
    backgroundPositionX: "70%"
  },
  backgroundImg1: {
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(/assets/images/ball.webp)",
    backgroundSize: "cover",
    backgroundPositionX: "70%"
  }
}));

export default function LoginDialog({ opened, close }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [page, setPage] = useState(1);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const classes = useStyles();
  const school = useContext(SchoolContext);

  function slideTo(page) {
    controlledSwiper.slideTo(page);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={opened}
      PaperProps={{ style: { borderRadius: fullScreen ? 0 : 10 } }}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AuthForm school={school} onClose={close}></AuthForm>
      {/* <Swiper
                    style={{ height: "100%" }}
                    slidesPerView={1}
                    onSwiper={setControlledSwiper}
                    initialSlide={page}
                    threshold={10}
                    onSlideChange={ (swiper) => {
                        setPage(swiper.realIndex);
                    }}
                    >
                    <SwiperSlide className={ classes.backgroundImg1 }><LoginPage close={close}></LoginPage></SwiperSlide>
                    <SwiperSlide className={ classes.backgroundImg }>
                            <AuthPage close={ close } scrollTo={ slideTo }></AuthPage>
                    </SwiperSlide>
                    <SwiperSlide className={ classes.backgroundImg1 }><SignupPage school={school} close={close}></SignupPage></SwiperSlide>
                </Swiper>
                <Pagination 
                    className={ classes.pagination }
                    color="primary"
                    page={ page + 1 }
                    size="small"
                    count={ 3 }
                    onChange={ (event, value) => {
                        slideTo((value ?? 0) - 1);
                    }}  
                    variant="outlined"
                    hideNextButton
                    hidePrevButton
                    renderItem={ (item, i) => <PaginationItem className={ item.page === (page + 1) ? classes.active : classes.item } {...item}/>}
                ></Pagination> */}
    </Dialog>
  );
}

// function AuthPage({ close, scrollTo }) {
//     const classes = useStyles();

//     return (
//         <Container component="main" maxWidth="xs" style={{ height: "100%" }}>
//             <CssBaseline />
//             <div className={classes.paper}>
//                 <Avatar className={classes.avatar}>
//                     <LockOutlinedIcon />
//                 </Avatar>
//                 <Typography component="h1" variant="h5">
//                     Fitness Platform
//                 </Typography>
//                 <div style={{ flexGrow: 1 }}></div>
//                 <Button
//                     fullWidth
//                     variant="outlined"
//                     className={classes.submit}
//                     onClick={ () => scrollTo(0) }
//                 >
//                     Login
//                 </Button>
//                 <Button
//                     fullWidth
//                     variant="contained"
//                     className={classes.submit}
//                     onClick={ () => scrollTo(2) }
//                 >
//                     Sign In
//                 </Button>
//                 <Button
//                     fullWidth
//                     onClick={ close }
//                     className={classes.submit}
//                 >
//                     Skip for now
//                 </Button>
//                 <Box height={60}></Box>
//             </div>
//         </Container>
//     );
// }

// function SignupPage({ close, school }) {

//     const classes = useStyles();

//     const theme = useTheme();
//     const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

//     const [error, setError] = useState();

//     let email;
//     let password;
//     let username;
//     // TODO: confirm password functionaility

//     async function signup() {
//         console.log("adads")
//         const res = await signupEmail({email, password, username, schoolId: school.id});
//         if (res !== "SUCCESS") {
//             setError(res);
//         } else {
//             close();
//         }
//     }

//     return (
//         <Container component="main" maxWidth="xs"  style={{ height: "100%" }}>
//             <CssBaseline />
//             <div className={classes.paper}>
//                 {
//                     isSmall === false
//                     ?   <React.Fragment>
//                             <Avatar className={classes.avatar}>
//                                 <LockOutlinedIcon />
//                             </Avatar>
//                             <Typography component="h1" variant="h5">
//                                 Sign Up
//                             </Typography>
//                         </React.Fragment>
//                     :   <Typography component="h1" variant="h5">
//                             Sign Up
//                         </Typography>
//                 }
//                 <form className={classes.form} noValidate>
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         id="name"
//                         label="User Name"
//                         name="username"
//                         autoComplete="username"
//                         onChange={ (event) => username = event.target.value }
//                     />
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         id="confirmEmailSignup"
//                         label="Email Address"
//                         name="email"
//                         autoComplete="email"
//                         onChange={ (event) => email = event.target.value }
//                     />
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="password"
//                         label="Password"
//                         type="password"
//                         id="passwordSignup"
//                         autoComplete="current-password"
//                         onChange={ (event) => password = event.target.value }
//                     />
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="confirmPassword"
//                         label="Confirm Password"
//                         type="password"
//                         id="confirmPassword"
//                         autoComplete="current-password"
//                         // TODO: Functionality
//                     />
//                     <Typography variant="subtitle1" color="secondary" align="center">{ error }</Typography>
//                     {/* <Grid item xs>
//                         <Link href="#" variant="body2">
//                             Forgot password?
//                         </Link>
//                     </Grid> */}
//                 </form>
//                 <div style={{ flexGrow: 1 }}></div>
//                 <Button
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     className={classes.submit}
//                     onClick={ signup }
//                 >
//                     Sign In
//                 </Button>
//                 {/* <Grid container>
//                     <Grid item>
//                         <Link href="#" variant="body2">
//                             {"Don't have an account? Sign Up"}
//                         </Link>
//                     </Grid>
//                 </Grid> */}
//                 <Box height={100}></Box>
//             </div>
//         </Container>
//     );
// }

// function LoginPage({ close }) {
//     const classes = useStyles();

//     const theme = useTheme();
//     const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

//     let email;
//     let password;

//     return (
//         <Container component="main" maxWidth="xs" style={{ height: "100%" }}>
//             <CssBaseline />
//             <div className={classes.paper}>
//                 {
//                     isSmall === false
//                     ?   <React.Fragment>
//                             <Avatar className={classes.avatar}>
//                                 <LockOutlinedIcon />
//                             </Avatar>
//                             <Typography component="h1" variant="h5">
//                                 Login
//                             </Typography>
//                         </React.Fragment>
//                     :   <Typography component="h1" variant="h5">
//                             Login
//                         </Typography>
//                 }
//                 <form className={classes.form} noValidate>
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         id="email"
//                         label="Email Address"
//                         name="email"
//                         onChange={ (event) => email = event.target.value }
//                         autoComplete="email"
//                     />
//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="password"
//                         label="Password"
//                         type="password"
//                         id="password"
//                         onChange={ (event) => password = event.target.value }
//                         autoComplete="current-password"
//                     />
//                     <Grid item xs>
//                         <Link href="#" variant="body2" color="secondary">
//                             Forgot password?
//                         </Link>
//                     </Grid>
//                 </form>
//                 <div style={{ flexGrow: 1 }}></div>
//                 <Button
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     className={classes.submit}
//                     onClick={ () => login(email, password).then(res => close()) }
//                 >
//                     Login
//                 </Button>
//                 {/* <Link href="#" variant="body2">
//                     {"Don't have an account? Sign Up"}
//                 </Link> */}
//                 <Box height={100}></Box>
//             </div>
//         </Container>
//     );
// }
