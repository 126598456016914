import React, { useContext, useEffect, useState } from "react";
import { PrimaryButton } from "../../../../components/tailwind-components/buttons";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Box,
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import MyCard from "../../main/components/ui-components/my-card";
import { useHistory } from "react-router-dom";
import { removeSlashSuffix } from "../../../../utils/utils";
import { SchoolContext } from "../../school";
import { fetchUsers } from "../../../../db";
import DataFilterTable from "../components/data-filter-table";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

export default function AdminUsersPage({ basePath }) {
  const classes = useStyles();
  const router = useHistory();
  const school = useContext(SchoolContext);
  const [users, setUsers] = useState();

  const columns = [
    { id: "email", numeric: false, disablePadding: true, label: "Email", child: item => item.email },
    { id: "userName", numeric: false, disablePadding: true, label: "Username", child: item => item.userName },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Signed Up Since",
      child: item => new Date(item.createdAt).toLocaleString()
    }
  ];

  useEffect(() => {
    if (school) {
      fetchUsers(school.id).then(res => setUsers(res));
    }
  }, [school]);

  return (
    <React.Fragment>
      <DashboardPageHeader title="Users" actions={<PrimaryButton>Add User</PrimaryButton>}></DashboardPageHeader>
      <DashboardPageContent>
        {users && <DataFilterTable searchableKey="email" items={users} columns={columns}></DataFilterTable>}
      </DashboardPageContent>
    </React.Fragment>
  );
}
