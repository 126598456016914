import { Icon, IconButton, Typography } from "@material-ui/core";
import { FieldArray } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { SecondaryButton } from "../../../../components/tailwind-components/buttons";
import { fetchRoutines } from "../../../../db";
import { SchoolContext } from "../../school";
import RoutineSelectionDialog from "../components/routine-selection-dialog";
import { HorizontalContentCard } from "../../../../components/content-list/content-list";

const phase = {
  name: "",
  schedule: [null, null, null, null, null, null, null]
};

function splitInWeeks(schedule) {
  const weeks = [];
  if (schedule) {
    for (let i = 0; i < schedule.length; i += 7) {
      weeks.push(schedule.slice(i, i + 7));
    }
  }
  return weeks;
}

export default function ScheduleContentForm({ formik }) {
  const school = useContext(SchoolContext);
  const [selectedDays, setSelectedDays] = useState([]);
  const [routines, setRoutines] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(
    () =>
      fetchRoutines(school.id).then(res => {
        console.log("Fetched Routines");
        setRoutines(res);
      }),
    []
  );

  function selectDay(index) {
    if (selectedDays.includes(index)) {
      const days = selectedDays;
      days.splice(days.indexOf(index), 1);
      setSelectedDays([...days]);
    } else {
      setSelectedDays([...selectedDays, index]);
    }
  }

  let selectedValue;
  if (selectedDays.length > 0) {
    selectedValue = formik.values.schedule[selectedDays[0]];
    selectedDays.forEach(dayIndex => {
      if (formik.values.schedule[dayIndex] !== selectedValue) {
        selectedValue = undefined;
      }
    });
  }

  const selectedRoutine = routines.find(r => r.id === selectedValue);

  function setWorkoutId(id) {
    const schedule = formik.values.schedule;
    selectedDays.forEach(dayIndex => (schedule[dayIndex] = id));
    formik.setFieldValue("schedule", schedule);
  }

  return (
    <React.Fragment>
      <FieldArray name="schedule">
        {({ insert, remove, push }) => (
          <div>
            <label className="block text-sm font-medium text-gray-700">Workout Schedule</label>
            {splitInWeeks(formik.values.schedule).map((week, weekIndex) => (
              <div key={weekIndex} className="flex">
                <div className="flex-1 grid grid-cols-7 space-x-2 space-y-2 h-16">
                  {week.map((day, dayIndex) => (
                    <div
                      className={`${day ? "bg-indigo-600 text-white" : "bg-gray-300 text-black"} ${
                        selectedDays.includes(weekIndex * 7 + dayIndex) ? "border-solid border-4 border-yellow-300" : "border-none"
                      } rounded-md shadow-sm`}
                      onClick={() => selectDay(weekIndex * 7 + dayIndex)}
                    >
                      Day {weekIndex * 7 + dayIndex + 1}
                    </div>
                  ))}
                </div>
                <IconButton onClick={() => [null, null, null, null, null, null, null].forEach(() => remove(weekIndex * 7))}>
                  <Icon>remove</Icon>
                </IconButton>
              </div>
            ))}
            <SecondaryButton
              className="mt-2"
              onClick={() => {
                [null, null, null, null, null, null, null].map(id => push(id));
              }}
            >
              Add Schedule Week
            </SecondaryButton>

            <div className="flex mt-8">
              <div className="flex-1">
                <Typography variation="subtitle" color="textSecondary">Days {selectedDays.join(", ")}</Typography>
                {selectedDays.length > 0 ? (
                  <Typography variation="h2">
                    {selectedValue === undefined ? "Mixed workouts selected" : selectedValue === null ? "Rest Day" : selectedRoutine?.name ?? selectedValue }
                  </Typography>
                ) : (
                  <Typography variation="h2">Select one or multiple days to edit</Typography>
                )}
              </div>
              <div>
                <IconButton onClick={() => setOpen(true)}>
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => setWorkoutId(null)}>
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            </div>
            <HorizontalContentCard item={selectedRoutine}></HorizontalContentCard>
            {/* <input onChange={event => setWorkoutId(event.target.value)}></input> */}
          </div>
        )}
      </FieldArray>
      <RoutineSelectionDialog single open={open} setOpen={setOpen} onSelect={ (ids) => setWorkoutId(ids[0]) } routines={routines}>

      </RoutineSelectionDialog>
    </React.Fragment>
  );
}
