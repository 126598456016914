import {
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import React from 'react';
import Row from "./row";

export default function ResponsiveGrid({ children, xsCount, smCount, mdCount, lgCount, spacingX, spacingY, maxRows }) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    // if (!children) return <React.Fragment></React.Fragment>

    let rowCount;

    if (xs) {
        rowCount = xsCount ?? 1;
    } else if (sm) {
        rowCount = smCount ?? 2;
    } else if (md) {
        rowCount = mdCount ?? 3;
    } else if (lg) {
        rowCount = lgCount ?? 4;
    };
    
    const rows = [];

    const fill = children.length % rowCount != 0 ? Array.from({ length: rowCount - (children.length % rowCount) }, (v, k) => <div style={{ flexGrow: 1, flexBasis: 0, visibility: "hidden" }}>{ children[children.length - 1] }</div>) : [];
    const items = [...children, ...fill ];
    

    for (let i = 0; i < items.length; i += rowCount) {
        if (!maxRows || rows.length < maxRows)
        rows.push(items.slice(i, i + rowCount));
    }

    return <React.Fragment>
        { rows.map((row, i) => <Row key={i} align="start" style={{ paddingBottom: i !== rows.length - 1 ? spacingY : 0 }}>
            { row.map((child, i) => <div key={i} style={{ flexGrow: 1, flexBasis: 0, paddingRight: i !== row.length - 1 ? spacingX : 0, }}>{ child }</div>) }
        </Row>) }
    </React.Fragment>
}