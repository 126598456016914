import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PrimaryButton
} from "../../../../components/tailwind-components/buttons";
import { listenRoutines } from "../../../../db";
import { getWorkoutType } from "../../../../utils/utils";
import { SchoolContext } from "../../school";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";
import DataFilterTable from "../components/data-filter-table";

export default function AdminRoutinePage() {
  const school = useContext(SchoolContext);
  const [routines, setRoutines] = useState();

  useEffect(() => {
    let routinesListener;
    if (school) {
      routinesListener = listenRoutines(school.id, (routines) =>
        setRoutines(routines)
      );
    }

    return () => {
      if (routinesListener) {
        routinesListener();
      }
    };
  }, [school]);

  const columns = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", child: item => item.name },
    { id: "type", numeric: true, disablePadding: false, label: "Type", child: item => getWorkoutType(item.type) }
  ];

  return (
    <div>
      <DashboardPageHeader
        title="Workouts"
        actions={
          <div>
            <Link to={(location) => `${location.pathname}/new`} type="button">
              <PrimaryButton className="ml-4">Create</PrimaryButton>
            </Link>
          </div>
        }
      />
      <DashboardPageContent>
        {routines && <DataFilterTable searchableKey="name" items={routines} columns={columns}></DataFilterTable>}
      </DashboardPageContent>

    </div>
  );
}


