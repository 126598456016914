import { Box, Button, Chip, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { School } from "@material-ui/icons";
import Rating from "@material-ui/lab/Rating";
import React, { useContext, useEffect, useState } from "react";
import { ContentSection } from '../../../../../components/content-list/content-list';
import BannerCard from "../../../../../components/tailwind-components/banner-card";
import { fetchProgramRatings } from "../../../../../db";
import { UserTenantContext } from "../../../school";
import MediaItem from "../media-item";
import ProgramStatus from "../program-status";
import Ratio from "../ratio";
import ShareDialog from "../share-dialog";
import MyCard from "../ui-components/my-card";
import ResponsiveGrid from "../ui-components/responsive-grid";
import Row from "../ui-components/row";
import { ProgramContentOverview } from "./program-content-overview";


const useStyle = makeStyles(theme => ({
  startButton: {
    width: "100%",
    margin: "auto",
    borderRadius: 10,
    fontWeight: "bold",
    padding: 8
  },
  sectionTitle: {
    fontSize: 18,
    color: theme.palette.text.secondary,
    flexGrow: 1
  },
  section: {
    alignItems: "center",
    height: 50,
    cursor: "pointer"
  },
  secondaryButton: {}
}));

export default function ProgramOverview({
  program,
  slideTo,
  status,
  startProgram,
  pauseProgram,
  continueProgram,
  buyButton,
  isPurchased,
  isLarge,
  school
}) {
  const classes = useStyle();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const user = useContext(UserTenantContext);

  function programInfo() {
    return (
      <div className="mt-3">
        {program.subtitle && (
          <Typography variant="subtitle1" color="primary" className="max-line-1 uppercase">
            {program.subtitle}
          </Typography>
        )}
        <Row>
          <Typography variant="h1" className="max-line-1" style={{ flexGrow: 1 }}>
            {program.name}
          </Typography>
          <IconButton onClick={() => setShowShareDialog(true)}>
            <Icon>reply</Icon>
          </IconButton>
        </Row>
        <Box>
          <Grid container alignItems="center">
            {program.totalRatings > 0 && avrgRatingsAndCount(program)}
            {program.categories &&
              program.categories.map(categorie => (
                <Box paddingX="4px" key={categorie}>
                  <Chip size="small" color="primary" label={categorie}></Chip>
                </Box>
              ))}
          </Grid>
        </Box>
       { program.description && <Typography variant="body1" color="textSecondary" className="mt-4">
          {program.description}
        </Typography>}
        <br />
        {/* // TODO: Statisitcs Button */}
      </div>
    );
  }

  function buildCurrentButton(program, status) {
    if ((user === undefined) | (status === undefined)) {
      // undefined means not loaded, if not logged in/ no status then it is null, when not loaded, don't render a (possibly) wrong button
      return <React.Fragment></React.Fragment>;
    }

    let button;
    if (!(user?.programIds?.includes(program.id) ?? false)) {
      // button = <Button className={classes.startButton} color="secondary" variant="contained" onClick={ onBuyProgram }>Buy Program</Button>
      button = buyButton;
    } else if (!status) {
      button = (
        <Button className={classes.startButton} color="primary" variant="contained" onClick={startProgram}>
          Start Program
        </Button>
      );
    } else if (status.isPaused) {
      button = (
        <Button className={`${classes.startButton} ${classes.secondaryButton}`} variant="contained" onClick={continueProgram}>
          Continue Program
        </Button>
      );
    } else {
      button = (
        <Button className={`${classes.startButton} ${classes.secondaryButton}`} variant="contained" onClick={pauseProgram}>
          Pause Program
        </Button>
      );
    }

    return <Box>{button}</Box>;
  }

  return (
    <React.Fragment>
      <BannerCard isBanner={isLarge}>
        <Ratio ratio={16 / 9}>
          <MediaItem media={program.media}></MediaItem>
        </Ratio>
      </BannerCard>
      <Box m={2}>
        {programInfo()}
        {/* { writeReview() } */}
        {/* // TODO: Program Done Indicator */}
        {buildCurrentButton(program, status)}
        {status && <ProgramStatus style={{ marginTop: 16 }} status={status} initialProgram={program}></ProgramStatus>}
        {!isPurchased && <ProgramGoalsSection></ProgramGoalsSection>}
        <Overview program={program} slideTo={slideTo} status={status} isPurchased={isPurchased}></Overview>
        { program.totalRatings > 0 && <RatingsView school={school} program={program}></RatingsView>}
        <ContentSection contentItems={program.contentItems} className="mt-4"></ContentSection>
        <Box height={100}></Box>
      </Box>
      <ShareDialog
        open={showShareDialog}
        close={() => setShowShareDialog(false)}
        title={program?.name}
        url={`https://fitness-platform.vercel.app/program/${program?.id}`}
      ></ShareDialog>
    </React.Fragment>
  );
}

// UI COMPONENTS

function writeReview() {
  return <div>TODO: Write Review</div>;
}

function Overview({ program, slideTo, status, isPurchased }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  if (!md) {
    return (
      <Box paddingTop={2}>
        <MyCard>
          <Box p={2}>
            <Typography color="textSecondary">Program Content</Typography>
          </Box>
          <Divider></Divider>
          <ProgramContentOverview program={program} isPurchased={isPurchased} goToPage={slideTo} status={status}></ProgramContentOverview>
        </MyCard>
      </Box>
    );
  }
  return <React.Fragment></React.Fragment>;
}

function RatingsView({ school, program }) {
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProgramRatings(school.id, program.id, new Date().getTime()).then(res => setRatings(res));
  }, []);

  function loadNextRatings() {
    setLoading(true);
    console.log("fetching", ratings[ratings.length - 1].title, ratings[ratings.length - 1].date);
    fetchProgramRatings(school.id, program.id, ratings[ratings.length - 1].date).then(res => {
      if (res.length >= 15) {
        setLoading(false);
      }
      setRatings([...ratings, ...res]);
    });
  }

  return (
    <Box paddingTop={2}>
      <MyCard>
        <Box padding={2}>
          {/* <h3>Ratings</h3> */}
          <Row>
            <Typography variant="body1" color="textSecondary">
              Ratings
            </Typography>
            <Box width={16}></Box>
            { program.totalRatings > 0 && avrgRatingsAndCount(program)}
          </Row>
        </Box>
        <Divider></Divider>
        <div style={{ maxHeight: 400, overflowY: "scroll" }}>
          {ratings.map((rating, i) => (
            <div key={i}>
              <Box p={1.5}>
                <Typography variant="h3" className="mb-1">{rating.title}</Typography>
                <div className="flex items-center">
                  <Rating size="small" name="size-medium" style={{ pointerEvents: "none" }} value={rating.rating} precision={0.5}></Rating>
                  <Box width={8}></Box>
                  <Icon>person</Icon>
                  <Box width={4}></Box>
                  <Typography variant="subtitle1" >
                    {rating.userName}
                  </Typography>
                </div>
                <Typography variant="body2" color="textSecondary">
                  {rating.message}
                </Typography>
              </Box>
              <Box paddingX={1}>
                <Divider></Divider>
              </Box>
            </div>
          ))}
          {!loading && (
            <Box p={2}>
              <Button onClick={loadNextRatings}>Load More...</Button>
            </Box>
          )}
        </div>
      </MyCard>
    </Box>
  );
}

function avrgRatingsAndCount(program) {
  return (
    <div className="flex justify-center">
      <Rating name="size-medium" style={{ pointerEvents: "none" }} value={program.avrgRating} precision={0.5}></Rating>
      <Box width={8}></Box>
      <Icon>person</Icon>
      <Box width={4}></Box>
      <Typography variant="body1">{program.totalRatings}</Typography>
    </div>
  );
}

const useFeatureClasses = makeStyles(theme => ({
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flexBasis: 0,
    flexGrow: 1
  },
  iconWrapper: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 40,
    padding: 8,
    borderRadius: "100%"
  },
  item: {
    textAlign: "start",
    padding: 12
  }
}));

function ProgramGoalsSection() {
  const featureClasses = useFeatureClasses();

  function FeatureItem({ message, icon }) {
    return (
      <Box style={{ flexBasis: 0 }}>
        <MyCard className={featureClasses.item}>
          <Row>
            <div className={featureClasses.iconWrapper}>
              <Icon className="text-white">{icon}</Icon>
            </div>
            <Box minWidth={12}></Box>
            <Typography variant="body2">{message}</Typography>
          </Row>
        </MyCard>
      </Box>
    );
  }

  return (
    <Box paddingTop={2}>
      <ResponsiveGrid spacingX={16} spacingY={16} lgCount={3}>
        <FeatureItem
          message={
            <span>
              <b>Learn</b> the basic exercises of fitness
            </span>
          }
          icon="emoji_objects"
        ></FeatureItem>
        <FeatureItem
          message={
            <span>
              <b>Gain</b> muscle mass
            </span>
          }
          icon="person"
        ></FeatureItem>
        <FeatureItem
          message={
            <span>
              <b>Enhance</b> your physical fitness
            </span>
          }
          icon="favorite"
        ></FeatureItem>
        <FeatureItem
          message={
            <span>
              <b>Increase</b> strength and stability
            </span>
          }
          icon="fitness_center"
        ></FeatureItem>
        <FeatureItem
          message={
            <span>
              <b>Improve</b> coordination and control
            </span>
          }
          icon="directions_run"
        ></FeatureItem>
        <FeatureItem
          message={
            <span>
              <b>Tighten</b> your connective tissue
            </span>
          }
          icon="local_fire_department"
        ></FeatureItem>
      </ResponsiveGrid>
    </Box>
  );
}
