import React from "react";
import { DialogTitle, DialogContent, DialogActions, Button, Dialog } from "@material-ui/core";
import { useState } from "react";
import DataFilterTable from "./data-filter-table";
import { SecondaryButton } from "../../../../components/tailwind-components/buttons";

export default function ProgramSelectionDialog({ onSelect, initialSelection, programs }) {
  const [open, setOpen] = useState(false);

  let selection = [...(initialSelection ?? [])]

  const columns = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", child: item => item.name },
    { id: "editedAt", numeric: true, disablePadding: false, label: "Last Edit", child: item => new Date(item.editedAt).toLocaleString() }
  ];

  return (
    <React.Fragment>
      <SecondaryButton onClick={() => setOpen(true)}>Select Programs</SecondaryButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Select Programs</DialogTitle>
        <DialogContent>
          <DataFilterTable searchableKey="name" selectMode items={programs} columns={columns} initialSelection={initialSelection} selectionChange={ (res) => selection = res }></DataFilterTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={ () => onSelect(selection) }>Confirm</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
