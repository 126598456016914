import { Box, Dialog, DialogContent, DialogTitle, Icon } from "@material-ui/core";
import React,{ useState } from "react";
import Row from "./ui-components/row";

export default function HelpItem({ children, title, message, showIcon = true }) {

    const [show, setShow] = useState(false);

    return <React.Fragment>
        <Box onClick={ e => {e.stopPropagation(); setShow(true);} } style={ {cursor: "pointer"} }>
            <Row>
                { children }
                <Box width={4}></Box>
                { showIcon && <HelpItemIcon></HelpItemIcon> }
            </Row>
        </Box>
        <Dialog open={show} onClose={ (e) => { e.stopPropagation(); setShow(false); } }>
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                { message }
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export function HelpItemIcon() {
    return <Icon fontSize="small" style={{ fontSize: "14px", lineHeight: "16px" }} >help_outline</Icon>
}