import * as firebase from "firebase";
import "firebase/firestore";

export const generateUniqueId = () => {
  return firebase.firestore().collection("asdad").doc().id;
};

export const removeSlashSuffix = input => {
  if (input.charAt(input.length - 1) === "/") {
    return input.substr(0, input.length - 1);
  } else {
    return input;
  }
};

export const getWorkoutType = type => {
  switch (type) {
    case "sets":
      return "Sets and Reps";
    case "circuit":
      return "Circuit";
    default:
      return "undefined";
  }
};

export const getLogo = (school, isDarkMode) => {
  if (isDarkMode) {
    if (school?.config?.iconDark) {
      return school?.config?.iconDark;
    }
  }
  return school?.config?.icon;
}

export const mediaItemHasContent = (media) => {
  return media?.imageUrl || media?.videoUrl || (media?.isYoutubeVideo && media?.youtubeId);
}