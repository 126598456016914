import { Box, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import BaseExercisePreview from "./base-exercise-preview";

const useStyle = makeStyles(theme => ({
    badge: {
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: theme.palette.primary.main,
        borderRadius: 7,
        textAlign: "center"
    },
    wrapper: {
        borderColor: theme.palette.background.paper,
        // borderColor: theme.palette.text.secondary
    }
}))

export default function SupersetExercisePreview({exercise, expanded, onSelected, selectedProgression}) {

    const classes = useStyle();
    const [selectedExercise, setSelectedExercise] = useState(0);

    function selectExercise(i) {
        setSelectedExercise(i);
        onSelected();
    }

    return <div color="lighter" className={classNames(classes.wrapper, " border-dashed border-4 rounded-2xl")}>
        <Box p={1}>
            <div className={ classes.badge }>
                <Typography variant="subtitle1" className="p-1">{exercise.name}</Typography>
            </div>
        </Box>
        { exercise.exercises.map((exercise, i) => <Box p={1} key={exercise.id} onClick={ () => selectExercise(i) }>
            <BaseExercisePreview 
                exercise={exercise}
                expanded={ expanded && selectedExercise == i }
                selectProgression={ (progId) => selectedProgression(progId) }
            ></BaseExercisePreview>
        </Box>)}
    </div>
}