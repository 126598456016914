import { Typography } from '@material-ui/core';
import React from 'react';
import DashboardPageContent from "../../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../../components/dashboard/dashboard-page-header";

export default function AdminUserPage() {
    return (
        <React.Fragment>
            <DashboardPageHeader title="User"></DashboardPageHeader>
            <DashboardPageContent>
                <Typography className="text-center mt-12" color="textSecondary" variant="h2">WIP: Here you will be able to manage the selected customer</Typography>
            </DashboardPageContent>
        </React.Fragment>
    )
}