import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControlLabel, Checkbox, Button, Typography } from "@material-ui/core";

export default function PurchaseDialog({ program, close, opened, buyProgram }) {
    return <Dialog open={ opened }>
        <DialogTitle>Purchase {program?.name}</DialogTitle>
        <DialogContent style={{ overflowY: "unset" }}>
            <Typography>Billing process in not included in the current version. You can buy without being charge at the moment.</Typography>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField required id="cardName" label="Name on card" fullWidth autoComplete="cc-name" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardNumber"
                        label="Card number"
                        fullWidth
                        autoComplete="cc-number"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required id="expDate" label="Expiry date" fullWidth autoComplete="cc-exp" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cvv"
                        label="CVV"
                        helperText="Last three digits on signature strip"
                        fullWidth
                        autoComplete="cc-csc"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="saveCard" value="yes" />}
                        label="Remember credit card details for next time"
                    />
                </Grid>
            </Grid> */}
        </DialogContent>
        <DialogActions>
            <Button onClick={ close }>Cancel</Button>
            <Button onClick={ () => { buyProgram(); close(); } } color="primary">Buy Now</Button>
        </DialogActions>
    </Dialog>
}