import { makeStyles } from "@material-ui/core"
import classNames from "classnames";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: props => props.align ?? "center",
        justifyContent: props => props.justify ?? "start"
    }
}))

export default function Row({ children, ...props }) {

    const classes = useStyles(props);

    return <div className={ classNames(classes.root, props.className) } style={ props.style }>
        { children }
    </div>
}