import {
  Button,
  Divider, Drawer, Icon,
  IconButton, makeStyles,
  Typography, useMediaQuery,
  useTheme
} from "@material-ui/core";
import { PaginationItem } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../../../../../components/loading-spinner";
import MyContainer from "../../../../../components/tailwind-components/my-container";
import { fetchProgram, fetchPrograms, fetchProgramStatus, purchaseProgram } from "../../../../../db";
import ProgramStatus from "../../../../../program-status.model";
import { mediaItemHasContent } from "../../../../../utils/utils";
import { LoginDialogContext, SchoolContext, UserTenantContext } from "../../../school";
import MediaItem from "../../components/media-item";
import BuyNowLock from "../../components/program-page/buy-now";
import { ProgramContentOverview } from "../../components/program-page/program-content-overview";
// import MainLayout from "../../components/main-layout";
import ProgramOverview from "../../components/program-page/program-overview";
import ProgramSection from "../../components/program-page/program-section";
import PurchaseDialog from "../../components/program-page/purchase-dialog";
import Ratio from "../../components/ratio";
import SchoolNavBar from "../../components/school-navbar";

export const drawerWidth = 270;

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: theme.palette.background.paper
    },
  },
  pagination: {
    // position: "-webkit-sticky", /* Safari */
    position: "fixed",
    width: "100%",
    bottom: 16,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: 100,
    "& > *": {
      justifyContent: "center",
      display: "flex"
    }
  },
  item: {
    backgroundColor: theme.palette.foreground.default,
    border: "none"
  },
  active: {
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: "none !important"
  },
  buyButton: {
    width: "100%",
    margin: "auto",
    borderRadius: 10,
    fontWeight: "bold",
    padding: 8
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

export default function ProgramPage(props) {
  const [program, setProgram] = useState(props.program);

  const router = useHistory();
  const { programId } = useParams();
  const school = useContext(SchoolContext);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState();
  const classes = useStyle();
  const user = useContext(UserTenantContext);
  const isPurchased = user?.programIds?.includes(program?.id) ?? false;
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const openLogin = useContext(LoginDialogContext)


  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const isLarge = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.lg + (open ? drawerWidth : 0)));

  const location = useLocation();

  useEffect(() => {
    const page = new URLSearchParams(location.search).get("page");
    if (page) {
      setPage(parseInt(page));
    } else {
      setPage(0);
    }
  }, [location]);

  let pages;
  if (program) {
    pages = [
      <ProgramOverview
        program={program}
        slideTo={slideTo}
        startProgram={startProgram}
        pauseProgram={pauseProgram}
        continueProgram={continueProgram}
        buyButton={<BuyProgramButton program={program}></BuyProgramButton>}
        isPurchased={isPurchased}
        isLarge={isLarge}
        status={status}
        school={school}
      ></ProgramOverview>,
      ...program.sections?.map((section, i) => {
        return showSection(section) ? <ProgramSection section={section} isLarge={isLarge}  program={program} status={status}></ProgramSection> : <BuyNowLock buyButton={<BuyProgramButton program={program}></BuyProgramButton>}></BuyNowLock>
        // if (section.type === "PHASE_PROGRAM_SECTION") {
        //   return showSection(section) ? (
        //     <ProgramPhaseSection status={status} section={section} program={program}></ProgramPhaseSection>
        //   ) : (
        //     <BuyNowLock buyButton={<BuyProgramButton></BuyProgramButton>}></BuyNowLock>
        //   );
        // } else if (section.type === "PROGRAM_SECTION") {
        //   return showSection(section) ? (
        //     <ProgramSection section={section}></ProgramSection>
        //   ) : (
        //     <BuyNowLock buyButton={<BuyProgramButton></BuyProgramButton>}></BuyNowLock>
        //   );
        // }
      })
    ];
  }

  useEffect(() => {
    if (school?.id && programId) {
      fetchProgram(school.id, programId).then(res => setProgram(res));
    }
  }, [school]);

  useEffect(() => {
    setOpen(md);
  }, [md]);

  useEffect(() => {
    if (user === undefined || !school || !programId) return;
    if (user === null) {
      setStatus(null);
    } else {
      fetchProgramStatus(school.id, user.uid, programId).then(res => setStatus(res ?? null));
    }
  }, [user, school]);

  function startProgram() {
    if (user && program) {
      const status = ProgramStatus.startProgram(program, user.uid, school.id);
      setStatus(status);
    }
  }

  function buyProgram() {
    purchaseProgram(school.id, user.uid, program.id);
  }

  function pauseProgram() {
    // TODO:
  }

  function continueProgram() {
    // TODO:
  }

  function slideTo(page) {
    // to={`${program.id}?page=${i + 1}`}
    router.push(`${program.id}?page=${page}`)
    // setPage(page);
    // controlledSwiper.slideTo(page)
  }

  function BuyProgramButton({ program }) {
    function onBuyProgram() {
      if (!user) {
        // onLogin();
        openLogin(true);
      } else {
        setShowPurchaseDialog(true);
      }
    }

    const priceStr = program ? `$${program.price / 100}` : "No Price";

    return (
      <Button className={classes.buyButton ?? ""} color="secondary" variant="contained" onClick={onBuyProgram}>
        Buy Program for {priceStr}
      </Button>
    );
  }

  function showSection(section) {
    if (isPurchased === true) return true;
    if (section.isFree === true) return true;
    return false;
  }

  function body() {
    return (
      <div className="pb-20">
        {pages[page]}
        { !md && <Pagination
          className={classes.pagination}
          size="large"
          color="primary"
          page={page + 1}
          count={(program.sections?.length ?? 0) + 1}
          onChange={(event, value) => {
            slideTo((value ?? 0) - 1);
          }}
          // variant="outlined"
          // renderItem={(item, i) => <PaginationItem className={item.page === page + 1 ? classes.active : classes.item} {...item} />}
        ></Pagination>}
      </div>
    );
  }

  function content() {
    return (
      <MyContainer>
        {program && body()}
        {/* // TODO */}
        {program === undefined && <LoadingSpinner></LoadingSpinner>}
        {/* // TODO */}
        {program === null && <Typography variant="h2" className="text-center p-4 mt-10" color="textSecondary">Sorry, this program does not seem to exist</Typography> }
      </MyContainer>
    );
  }

  return (
    <React.Fragment>
      {/* <MainLayout
        ref={layoutRef}
        title={program?.name}
        description={program?.description}
        image={program?.media?.imageUrl}
        url={`/program/${program?.id}`}
      > */}
      <div className={classes.root}>
        <SchoolNavBar
          leading={
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              className={clsx("sm:mr-4 ", open && classes.hide)}
            >
              <Icon>menu</Icon>
            </IconButton>
          }
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        ></SchoolNavBar>
        
        <Drawer
          className={classes.drawer}
          variant={ sm ? "persistent" : "temporary"}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setOpen(false)}>
              <Icon>chevron_left</Icon>
            </IconButton>
          </div>
          <Divider />
          {program && (
            <React.Fragment>
              <Ratio ratio={16 / 9}>
                <MediaItem onlyImage media={ mediaItemHasContent(program.media) ? program.media : program.thumbnail}></MediaItem>
              </Ratio>
              <div className="p-4 mt-2">
                <Typography variant="h2">{program.name}</Typography>
                <Typography className="mt-4 max-line-3" variant="body1" color="textSecondary">
                  {program.description}
                </Typography>
              </div>
              <Divider></Divider>
              <ProgramContentOverview
                program={program}
                isPurchased={isPurchased}
                goToPage={slideTo}
                selectedIndex={page}
                showOverview={true}
                status={status}
              ></ProgramContentOverview>
            </React.Fragment>
          )}
        </Drawer>
        <main
          className={clsx(classes.content, "mt-16", {
            [classes.contentShift]: open
          })}
        >
          {content()}
        </main>
      </div>
      {/* </MainLayout> */}
      <PurchaseDialog
        opened={showPurchaseDialog}
        buyProgram={buyProgram}
        program={program}
        close={() => setShowPurchaseDialog(false)}
      ></PurchaseDialog>
    </React.Fragment>
  );
}

export async function getStaticPaths() {
  const programs = await fetchPrograms();

  const paths = programs.map(p => `/program/${p.id}`);

  return {
    paths,
    fallback: true
  };
}

export async function getStaticProps({ params }) {
  const program = await fetchProgram(params.id);

  return {
    props: {
      program: program
    }
  };
}
