import { Transition } from "@headlessui/react";
import { Avatar, Button, Divider, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { createContext, useContext, useState } from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { ThemeContext, UserTenantContext } from "../school";
import AdminDashboardPage from "./dashboard/dashboard";
import AdminEditProgramPage from "./programs/edit-program/edit-program";
import AdminProgramsPage from "./programs/programs";
import AdminEditRoutinePage from "./routines/edit-routine/edit-routine";
import AdminRoutinePage from "./routines/routines";
import AdminSchoolPage from "./school/school";
import AdminSellsPage from "./sells/sells";
import AdminUsersPage from "./users/users";
import AdminUserPage from "./users/user/user";
import { logout } from "../../../auth";

export const MobileDrawerContext = createContext();

export const drawerWidth = 270;

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px) !important`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

export default function AdminPage({ basePath }) {
  const [open, setOpen] = React.useState(true);
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {/* <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <Icon>chevron_left</Icon>
          </IconButton>
        </div>
        <Divider /> */}
        <DrawerDesktop basePath={basePath}></DrawerDesktop>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <MobileDrawerContext.Provider value={{ setOpen: setOpen, open: open }}>
          <MainContent basePath={basePath} showMobileDrawer={showMobileDrawer} setShowMobileDrawer={setShowMobileDrawer}></MainContent>
        </MobileDrawerContext.Provider>
      </main>
    </div>
  );

  // return (
  //   <div className="h-screen flex overflow-hidden bg-gray-50">
  //     <MobileDrawerContext.Provider value={setShowMobileDrawer}>
  //       <DrawerMobile basePath={basePath} showMobileDrawer={showMobileDrawer} setShowMobileDrawer={setShowMobileDrawer}></DrawerMobile>
  //       <DrawerDesktop basePath={basePath}></DrawerDesktop>
  //       <MainContent basePath={basePath} showMobileDrawer={showMobileDrawer} setShowMobileDrawer={setShowMobileDrawer}></MainContent>
  //     </MobileDrawerContext.Provider>
  //   </div>
  // );
}

function MainContent({ basePath, showMobileDrawer, setShowMobileDrawer }) {
  // <!-- Main column -->
  return (
    <Switch>
      <Route path={`${basePath}admin/workouts/:id`}>
        <AdminEditRoutinePage basePath={basePath}></AdminEditRoutinePage>
      </Route>
      <Route path={`${basePath}admin/users/:id`}>
        <AdminUserPage basePath={basePath}></AdminUserPage>
      </Route>
      <Route path={`${basePath}admin/programs/:id`}>
        <AdminEditProgramPage basePath={basePath}></AdminEditProgramPage>
      </Route>
      <Route path={`${basePath}admin/programs`}>
        <AdminProgramsPage></AdminProgramsPage>
      </Route>
      <Route path={`${basePath}admin/workouts`}>
        <AdminRoutinePage></AdminRoutinePage>
      </Route>
      <Route path={`${basePath}admin/school`}>
        <AdminSchoolPage path={`${basePath}admin/school`}></AdminSchoolPage>
      </Route>
      <Route path={`${basePath}admin/users`}>
        <AdminUsersPage basePath={basePath}></AdminUsersPage>
      </Route>
      <Route path={`${basePath}admin/sells`}>
        <AdminSellsPage></AdminSellsPage>
      </Route>
      <Route exact path={`${basePath}admin`}>
        <AdminDashboardPage></AdminDashboardPage>
      </Route>
    </Switch>
  );
}

const useStyles = makeStyles(theme => ({
  selectedTab: {
    backgroundColor: theme.palette.background.default
  }
}));

function DrawerLinks({ basePath }) {
  const classes = useStyles();
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);
  const user = useContext(UserTenantContext);
  const router = useHistory();
  
  return (
    <React.Fragment>
      <div className="flex flex-col items-center space-y-2">
        <Avatar style={{ width: "100px", height: "100px" }} src="https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg"></Avatar>
        <Typography variant="h3">{ user?.userName }</Typography>
        <Typography variant="body1" color="textSecondary">{ user?.email }</Typography>
        <div className="flex">
          { user && <Button className="text-red-500" onClick={ async () => {
            await logout();
            router.push(basePath)
          }}>Logout</Button>}
          <Button onClick={ () => router.push(basePath) }>School Page</Button>
        </div>
      </div>
      <Divider></Divider>
      <List>
        {[
          { title: "Dashboard", href: `${basePath}admin/`, icon: "mail" },
          { title: "School", href: `${basePath}admin/school`, icon: "school" },
          { title: "Workouts", href: `${basePath}admin/workouts`, icon: "school" },
          { title: "Programs", href: `${basePath}admin/programs`, icon: "school" },
          { title: "Sells", href: `${basePath}admin/sells`, icon: "school" },
          { title: "Users", href: `${basePath}admin/users`, icon: "school" },
        ].map(item => (
          <ListItem
            key={item.title}
            button
            className="rounded-md m-2"
            activeClassName="Mui-selected"
            style={{ width: "calc(100% - 1rem)" }}
            component={NavLink}
            exact
            replace
            to={item.href}
          >
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={item.title}></ListItemText>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );

  // return (
  //   <nav className="px-3 mt-6">
  //     <div className="space-y-1">
  //       <NavLink
  //         activeClassName="bg-gray-200"
  //         exact
  //         replace
  //         to={`${basePath}admin/`}
  //         className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-900 hover:bg-gray-50"
  //       >
  //         {/* <!-- Heroicon name: home --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth="2"
  //             d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  //           />
  //         </svg>
  //         Dashboard
  //       </NavLink>

  //       <NavLink
  //         replace
  //         activeClassName="bg-gray-200"
  //         exact
  //         to={`${basePath}admin/school/`}
  //         className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-900 hover:bg-gray-50"
  //       >
  //         {/* <!-- Heroicon name: home --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth="2"
  //             d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  //           />
  //         </svg>
  //         School
  //       </NavLink>

  //       <NavLink
  //         replace
  //         to={`${basePath}admin/workouts`}
  //         className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50`}
  //         activeClassName="bg-gray-200"
  //       >
  //         {/* <!-- Heroicon name: view-list --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
  //         </svg>
  //         Workouts
  //       </NavLink>

  //       <NavLink
  //         replace
  //         activeClassName="bg-gray-200"
  //         to={`${basePath}admin/programs`}
  //         href="#"
  //         className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50"
  //       >
  //         {/* <!-- Heroicon name: clock --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  //         </svg>
  //         Programs
  //       </NavLink>

  //       <NavLink
  //         replace
  //         activeClassName="bg-gray-200"
  //         to={`${basePath}admin/sells`}
  //         href="#"
  //         className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50"
  //       >
  //         {/* <!-- Heroicon name: clock --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  //         </svg>
  //         Sells
  //       </NavLink>

  //       <NavLink
  //         replace
  //         activeClassName="bg-gray-200"
  //         to={`${basePath}admin/users`}
  //         href="#"
  //         className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50"
  //       >
  //         {/* <!-- Heroicon name: clock --> */}
  //         <svg
  //           className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           aria-hidden="true"
  //         >
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  //         </svg>
  //         Users
  //       </NavLink>
  //     </div>
  //   </nav>
  // );
}

function DrawerMobile({ basePath, showMobileDrawer, setShowMobileDrawer }) {
  return (
    <div className="lg:hidden" style={{ pointerEvents: showMobileDrawer ? "auto" : "none" }}>
      <div className="fixed inset-0 flex z-40">
        {/* <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
        <Transition
          show={showMobileDrawer}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {ref => (
            <div ref={ref} className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
          )}
        </Transition>
        {/* <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      --> */}
        <Transition
          show={showMobileDrawer}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          {ref => (
            <div ref={ref} className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  onClick={() => setShowMobileDrawer(!showMobileDrawer)}
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center px-4">
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-logo-purple-500-mark-gray-700-text.svg"
                  alt="Workflow"
                /> */}
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <DrawerLinks></DrawerLinks>
              </div>
            </div>
          )}
        </Transition>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
        </div>
      </div>
    </div>
  );
}

function DrawerDesktop({ basePath }) {
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  return (
    <div className="">
      <div className="flex flex-col w-64 pt-5 pb-4">
        <div className="flex items-center flex-shrink-0 px-6">
          {!isDarkTheme ? (
            <img className="h-8 w-auto" src="/assets/icons/logo-text-black.png" alt="Exercisable" />
          ) : (
            <img className="h-8 w-auto" src="/assets/icons/logo-text.png" alt="Exercisable" />
          )}
          <DarkModeSwitch
            checked={!isDarkTheme}
            onChange={value => setDarkTheme(!isDarkTheme)}
            moonColor="#111827"
            sunColor="#fff"
            className="ml-4"
          ></DarkModeSwitch>
          {/* <img
            className="h-8 w-auto"
            src="https://tailwindui.com/img/logos/workflow-logo-purple-500-mark-gray-700-text.svg"
            alt="Workflow"
          /> */}
        </div>
        {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
      </div>
      <DrawerLinks basePath={basePath}></DrawerLinks>
    </div>
  );
}
