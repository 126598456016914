import { makeStyles } from "@material-ui/core";
import React from 'react';
import ReactMarkdown from "react-markdown";

const useStyle = makeStyles(theme => ({
  root: {
    "& p, li": {
      color: theme.palette.text.secondary,
      fontSize: "1.125rem",
      lineHeight: "1.5rem"
    },
    "& ul, ol": {
      listStyle: "inside"
    },
    "& ul": {
      listStyleType: "disc"
    },
    "& ol": {
      listStyleType: "decimal"
    },
    "& h1": {
      fontWeight: "bold",
      fontSize: "1.5rem",
      lineHeight: "2rem"
    },
    "& h2": {
      fontWeight: "bold",
      fontSize: "18px"
    },
    "& h3, h4, h5, h6": {
      fontWeight: "bold",
      fontSize: "16px"
    },
    "& a": {
      textDecoration: "underline"
    }
  }
}));

export function MarkdownDisplay({ value, ...props }) {

  const classes = useStyle();

  return (
    <ReactMarkdown
      className={`${classes.root} ${props.className}`}
      children={value}
      // renderers={{
      // paragraph: ({ children }) => (
      //   <Typography variant="body1" color="textSecondary">
      //     {children}
      //   </Typography>
      // ),
      // heading: ({ children }) => <Typography variant="h2">{children}</Typography>,
      // }}
    ></ReactMarkdown>
  );
}

// export function MarkdownEditor() {

//     const [value, setValue] = React.useState("**Hello world!!!**");
//     const [selectedTab, setSelectedTab] = React.useState("write");
  

//     return (
//         <div className="container">
//           <ReactMde
//             value={value}
//             onChange={setValue}
//             selectedTab={selectedTab}
//             onTabChange={setSelectedTab}
//             generateMarkdownPreview={(markdown) =>
//               Promise.resolve(
//                 <div style={{ backgroundColor: "red" }}>
//                   <ReactMarkdown source={markdown} />
//                 </div>
//               )
//             }
//             // loadSuggestions={loadSuggestions}
//             childProps={{
//               writeButton: {
//                 tabIndex: -1
//               }
//             }}
//             // paste={{
//             //   saveImage: save
//             // }}
//           />
//         </div>
//       );
// }
