import { AppBar, Dialog, Icon, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { PaginationItem } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useBasePath } from "../../../../../utils/get-subdomain";
import { hasTutor } from "../../../../../utils/routine-utils";
import { removeSlashSuffix } from "../../../../../utils/utils";
import TutorOverview from "./tutor-overview";
import TutorProgression from "./tutor-progression";

const useStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: theme.palette.background.paper
    },
  },
  background: {
    backgroundColor: theme.palette.background.default
  },
  pagination: {
    // position: "-webkit-sticky", /* Safari */
    position: "fixed",
    bottom: 16,
    // left: 0,
    // right: 0,
    // margin: "auto",
    zIndex: 1,
    "& > *": {
      // marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex"
    }
  },
  item: {
    backgroundColor: theme.palette.foreground.default,
    border: "none"
  },
  active: {
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: "none !important"
  },
  
}));

export default function TutorButton({ exercise, selectProgression }) {
  const [open, setOpen] = useState(false);

  if (hasTutor(exercise)) {
    return (
      <React.Fragment>
        {/* <IconButton onClick={() => setOpen(true)} style={{ padding: 4 }}> */}
        <IconButton className="p-1">
          <Link to={location => `${removeSlashSuffix(location.pathname)}/tutor/${exercise.id}`}>
            <Icon>school</Icon>
          </Link>
        </IconButton>
        {/* <TutorDialog open={open} exercise={exercise} onClose={() => setOpen(false)} selectProgression={selectProgression}></TutorDialog> */}
      </React.Fragment>
    );
  }
  return <div></div>;
}

export function TutorPage({ exercise }) {
  const classes = useStyle();
  const [page, setPage] = useState(0);
  const [state, setState] = useState();

  const router = useHistory();
  const location = useLocation();

  useEffect(() => {
    const page = new URLSearchParams(location.search).get("page");
    if (page) {
      setPage(parseInt(page));
    } else {
      setPage(0);
    }
  }, [location]);

  function slideTo(to) {
    if (page !== to) {
      router.push(`${exercise.id}?page=${to}`);
    }
    // controlledSwiper.slideTo(page);
    // setPage(page);
  }

  const pages = [
    <TutorOverview
      exercise={exercise}
      selectProgression={progId => {
        exercise.selectedProgressionId = progId;
        setState(progId);
      }}
      slideTo={slideTo}
    ></TutorOverview>,
    ...exercise.progressions.map((progression, i) => <TutorProgression progression={progression}></TutorProgression>)
  ];

  if (exercise) {
    return (
      <React.Fragment>
        <div className="h-full">
          {pages[page]}
          <Pagination
            className={classNames(classes.pagination, "w-full justify-center")}
            color="primary"
            size="large"
            page={page + 1}
            count={(exercise.progressions?.length ?? 0) + 1}
            onChange={(event, value) => {
              slideTo((value ?? 0) - 1);
            }}
            // variant="outlined"
            // renderItem={(item, i) => <PaginationItem className={item.page === page + 1 ? classes.active : classes.item} {...item} />}
          ></Pagination>
        </div>
      </React.Fragment>
    );
  }
  return <div></div>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function TutorDialog({ routine }) {
  const classes = useStyle();
  const [open, setOpen] = useState(true);
  const router = useHistory();
  const path = useBasePath();

  const { exerciseId } = useParams();
  console.log(JSON.parse(localStorage.getItem("flutter.UNFINISHED_WORKOUT")));
  const exercise = routine.exercises.find(ex => ex.id === exerciseId);

  const onClose = () => {
    setOpen(false);
    router.replace(`/${path}routine/${routine.id}`);
  };

  return (
    <Dialog fullScreen  open={open} onClose={onClose} TransitionComponent={Transition} className={classes.root} PaperProps={{ classes: { root: classes.background } }}>
      <AppBar position="fixed" color="inherit" className={classes.root}>
        <Toolbar>
          <IconButton edge="start" onClick={onClose} aria-label="close">
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{`${exercise.name} Tutor`}</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar></Toolbar>
      <div className={classes.background}>
        <TutorPage exercise={exercise}></TutorPage>
      </div>
    </Dialog>
  );
}
