import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, Typography, Paper, Button } from "@material-ui/core";
import MyCard from "../../main/components/ui-components/my-card";
import { PrimaryButton } from "../../../../components/tailwind-components/buttons";
import DashboardPageContent from "../components/dashboard/dashboard-page-content";
import DashboardPageHeader from "../components/dashboard/dashboard-page-header";

function createData(transaction, user, amount, date) {
  return { transaction, user, amount, date };
}

const rows = [
  createData("Beginner Program", "scott.mescudi@gmail.com", "$59.99", "30.12.2020 12:23"),
  createData("Beginner Program", "jonaswallmann@hotmail.de", "$59.99", "30.12.2020 14:23"),
  createData("Advanced Program", "jaquesW@gmail.com", "$59.99", "30.12.2020 15:23"),
  createData("Beginner Program", "some@email.de", "$59.99", "30.12.2020 20:23"),
  createData("Beginner Program", "scott.mescudi@gmail.com", "$59.99", "31.12.2020 14:23"),
  createData("Beginner Program", "jonaswallmann@hotmail.de", "$59.99", "31.12.2020 16:23"),
  createData("Advanced Program", "jaquesW@gmail.com", "$59.99", "31.12.2020 17:23"),
  createData("Beginner Program", "some@email.de", "$59.99", "31.12.2020 20:23")
];

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

export default function AdminSellsPage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <DashboardPageHeader title="Sells" actions={<PrimaryButton>Add User</PrimaryButton>}></DashboardPageHeader>
      <DashboardPageContent>
        <TableContainer component={MyCard}>
          <Table className={classes.table} aria-label="recent activity table">
            <TableHead component={Box}>
              <TableRow>
                <TableCell align="left">Transaction</TableCell>
                <TableCell align="right">User</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell>{row.transaction}</TableCell>
                  <TableCell align="right">{row.user}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardPageContent>
    </React.Fragment>
  );
}
