import { Box, Button, Collapse, Divider, Icon, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { fetchRoutine } from "../../../../../db";
import { SchoolContext } from "../../../school";
import InfoItemList from "../info-item-list";
import MediaItem from "../media-item";
import MyCard from "../ui-components/my-card";
import ResponsiveCard from "../ui-components/responsive-card";
import Row from "../ui-components/row";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useBasePath } from "../../../../../utils/get-subdomain";
import Ratio from "../ratio";

const useStyle = makeStyles(theme => ({
  day: {
    maxWidth: 50,
    maxHeight: 50,
    flexGrow: 1,
    height: 50,
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    margin: 4,
    textAlign: "center",
    lineHeight: "50px",
    cursor: "pointer",
    position: "relative",
    fontWeight: 700
  },
  dayText: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  fillDay: {
    opacity: 0.5,
    cursor: "unset"
  },
  workoutDay: {
    backgroundColor: theme.palette.blueAccent.main,
    color: "white"
  },
  finishedDay: {
    backgroundColor: "#4CAF50"
  },
  selected: {
    // border: "3px solid white",
    WebkitBoxShadow: "inset 0px 0px 0px 3px",
    MozBoxShadow: "inset 0px 0px 0px 3px",
    boxShadow: "inset 0px 0px 0px 3px",
    borderColor: theme.palette.text.primary
  },
  today: {
    WebkitBoxShadow: "inset 0px 0px 0px 3px #ffffffa6",
    MozBoxShadow: "inset 0px 0px 0px 3px #ffffffa6",
    boxShadow: "inset 0px 0px 0px 3px #ffffffa6"
  },
  weekPreview: {
    backgroundColor: theme.palette.background.lighter
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical"
  },
  routinePreview: {
    display: "flex",
    flexDirection: "column"
  },
  restContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  restLabel: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.text.secondary
  },
  link: {
    textDecoration: "none",
    color: "white"
  }
}));

export default function ProgramPhaseSection({ section, program, status }) {
  const phase = program.phases[section.phase];

  function buildPhase() {
    return (
      <MyCard>
        <Box p={1} paddingX={2}>
          <Row>
            <h2 style={{ flexGrow: 1 }}>{phase.name}</h2>
            <IconButton>
              <Icon>edit</Icon>
            </IconButton>
            <IconButton>
              <Icon>replay</Icon>
            </IconButton>
          </Row>
        </Box>
        <PhaseCalendar phases={program.phases} phaseIndex={section.phase} status={status}></PhaseCalendar>
      </MyCard>
    );
  }

  return (
    <ResponsiveCard>
      <Box p={2}>
        <h1>{section.title}</h1>
        <Box height={8}></Box>
        {buildPhase()}
        <InfoItemList items={section.items}></InfoItemList>
      </Box>
    </ResponsiveCard>
  );
}

export function ProgramScheduleItem({ section, program, status }) {
  const phase = program.phases[section.phase];
  const school = useContext(SchoolContext);
  const [routineMap, setRoutineMap] = useState();


  useEffect(() => {
    if (!school) return;
    if (!section && !status) return;
    let schedule;
    if (!status) {
      schedule = section.schedule;
    } else {
      schedule = status.phases[section.phase].schedule.map(day => day.routineId);
    }
    if (!schedule) return;
    const routinesSet = new Set();
    const routineMap = {};
    schedule.forEach(id => {
      if (id) {
        routinesSet.add(id);
      }
    });
    Promise.all([...routinesSet].map(id => fetchRoutine(school.id, id))).then(routines => {
      routines.forEach(routine => (routineMap[routine.id] = routine));
      setRoutineMap(routineMap);
    });
  }, [school, section, status]);

  return (
    <MyCard>
      <Box p={1} paddingX={2}>
        <Row>
          <Typography variant="h2" style={{ flexGrow: 1 }}>
            {phase.name}
          </Typography>
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
          <IconButton>
            <Icon>replay</Icon>
          </IconButton>
        </Row>
      </Box>
      <PhaseCalendar phases={program.phases} phaseIndex={section.phase} status={status} routineMap={routineMap}></PhaseCalendar>
    </MyCard>
  );
}

// TODO: Following is all totally bad and hacked solution, create a better concept for a final version

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function programPhaseToCalendarWeeks(phases, phaseIndex) {
  let daysBeforePhase = 0;
  for (let i = 0; i < phaseIndex; i++) {
    daysBeforePhase += phases[i].schedule.length;
  }

  const phase = phases[phaseIndex];

  const now = new Date();
  const currentWeekday = now.getDay() - 1 >= 0 ? now.getDay() - 1 : 7; // gets current weekday with 0 -> monday. 7 -> sunday

  const days = [
    ...Array.from({ length: currentWeekday }, (v, k) => undefined), // Fill from monday to current day (where the program would start)
    ...phase.schedule
  ];
  if (days.length % 7 != 0) days.push(...Array.from({ length: 7 - (days.length % 7) }, (v, k) => undefined)); // Fill last week with undefined days so week ends on sunday

  const startingDate = addDays(now, 0 - currentWeekday + daysBeforePhase);
  const dates = days.map((day, i) => {
    return {
      date: addDays(startingDate, i),
      routineId: day,
      index: i - currentWeekday
    };
  });

  const weeks = [];
  for (let i = 0; i < dates.length; i += 7) {
    weeks.push(dates.slice(i, i + 7));
  }
  return weeks;
}

export const statusToCalendarWeeks = (status, phaseIndex) => {
  let daysBeforePhase = 0;
  for (let i = 0; i < phaseIndex; i++) {
    daysBeforePhase += status.phases[i].schedule.length;
  }

  const phase = status.phases[phaseIndex];

  const now = new Date(status.startedAt);
  const currentWeekday = now.getDay() - 1 > 0 ? now.getDay() - 1 : 7; // gets current weekday with 0 -> monday. 7 -> sunday

  const days = [
    ...Array.from({ length: currentWeekday }, (v, k) => undefined), // Fill from monday to current day (where the program would start)
    ...phase.schedule
  ];
  days.push(...Array.from({ length: 7 - (days.length % 7) }, (v, k) => undefined)); // Fill last week with undefined days so week ends on sunday

  const startingDate = addDays(now, 0 - currentWeekday + daysBeforePhase);
  const dates = days.map((day, i) => {
    return {
      date: addDays(startingDate, i),
      routineId: day?.routineId,
      workoutId: day?.workoutId,
      index: i - currentWeekday
    };
  });

  const weeks = [];
  for (let i = 0; i < dates.length; i += 7) {
    weeks.push(dates.slice(i, i + 7));
  }
  return weeks;
};

function PhaseCalendar({ phases, phaseIndex, status, routineMap }) {
  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  const [weeks, setWeeks] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  useEffect(() => {
    const _weeks = status ? statusToCalendarWeeks(status, phaseIndex) : programPhaseToCalendarWeeks(phases, phaseIndex);
    const _currentWeek = _weeks.findIndex(week =>
      week.some(
        day => day.date.getDate() === new Date().getDate() && day.date.getMonth() === new Date().getMonth() && day.routineId !== undefined
      )
    );
    setWeeks(_weeks);
    setSelectedWeek(_currentWeek);
  }, [status, phases]);

  function onSelectedDay(day, weekIndex) {
    if (day.routineId !== undefined) {
      if (weekIndex !== selectedWeek) {
        setSelectedWeek(weekIndex);
      }
    }
  }

  return (
    <div>
      <Divider></Divider>
      <Row justify="space-around">
        {weekdays.map((day, i) => (
          <p key={i} style={{ opacity: "0.7", fontWeight: "500" }}>
            {day}
          </p>
        ))}
      </Row>
      {weeks?.map((week, i) => (
        <PhaseCalendarWeek key={i} week={week} weekIndex={i} selected={selectedWeek === i} onSelect={onSelectedDay} routineMap={routineMap} status={status} phaseIndex={phaseIndex} programId={status?.programId}></PhaseCalendarWeek>
      ))}
    </div>
  );
}

export const PhaseCalendarWeek = ({ week, weekIndex, onSelect, selected, showWeekDays, align, routineMap, programId, phaseIndex }) => {
  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let today = week?.findIndex(day => day.date.getDate() === new Date().getDate() && day.date.getMonth() === new Date().getMonth());
  today = today >= 0 ? today : undefined;

  const classes = useStyle();
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [selectedDay, setSelectedDay] = useState(today);

  function onSelectRoutine(day, i) {
    if (onSelect) onSelect(day, weekIndex);
    setSelectedDay(i);
  }

  return (
    <React.Fragment>
      {!showWeekDays && <Divider></Divider>}
      <Box p={showWeekDays ? 1 : 0}>
        <div className={`flex items-center ${align ?? "justify-around"}`}>
          {week.map((day, i) => (
            <Box
              key={i}
              onClick={() => onSelectRoutine(day, i)}
              className={`
                ${classes.day}
                ${day.date.getDate() === new Date().getDate() && day.date.getMonth() === new Date().getMonth() ? classes.today : ""}
                ${day.routineId === undefined ? classes.fillDay : ""}
                ${day.routineId ? classes.workoutDay : ""}
                ${day.workoutId ? classes.finishedDay : ""}
                ${selectedDay === i && selected ? classes.selected : ""}
            `}
            >
              <h3 className={classes.dayText}>{showWeekDays ? weekdays[i] : `${day.date.getDate()}.`}</h3>
            </Box>
          ))}
        </div>
      </Box>
      {
        <Collapse in={selected} collapsedHeight={0}>
          <Box className={classes.weekPreview} height={150}>
            <RoutinePreview
              className="h-full"
              day={week[selectedDay]?.index ?? week[today]?.index}
              programId={programId}
              phase={phaseIndex}
              id={week[selectedDay ?? today]?.routineId}
              // selected={selectedDay === i && selected}
              routineMap={routineMap}
            ></RoutinePreview>
          </Box>
        </Collapse>
      }
    </React.Fragment>
  );
};

function RoutinePreview({ id, selected, className, routineMap, day, programId, phase }) {
  const classes = useStyle();
  const basePath = useBasePath();

  const routine = routineMap && routineMap[id] ? routineMap[id] : null;

  let programPointerQuery = "";
  if (programId !== undefined && day !== undefined && phase !== undefined) {
    programPointerQuery = `?id=${programId}&index=${phase}&day=${day}`;
  }

  function body() {
    if (routine) {
      return (
        <div className={`h-full flex p-1 ${className} `}>
          <MyCard height={126} style={{ flex: "0 0 126px" }}>
            <MediaItem media={routine?.media} onlyImage></MediaItem>
          </MyCard>
          <Box className={classNames(classes.routinePreview, "h-full px-3")}>
            <Typography variant="h2" className={`${classes.title} text-xl sm:text-2xl`}>
              {routine?.name}
            </Typography>
            <Typography className={classes.description} variant="body1" color="textSecondary">
              {routine?.description}
            </Typography>
            <Link to={`/${basePath}routine/${routine?.id}${programPointerQuery}`} style={{ marginTop: "auto", width: "fit-content", textDecoration: "none" }}>
              <Button variant="outlined" color="secondary" style={{ borderRadius: 7 }}>
                Open Routine
              </Button>
            </Link>
          </Box>
        </div>
      );
    }
    return <div className="flex h-full items-center justify-center">
      <Typography variant="h2" color="textSecondary" className="text-center m-auto">Not Found</Typography>
    </div>
  }

  function restDay() {
    return (
      <div className="flex h-full items-center justify-center">
      <Typography variant="h2" color="textSecondary" className="text-center m-auto">Rest Day</Typography>
    </div>
    );
  }

  return (
    <Box p={1} className="h-full">
      {id ? body() : restDay()}
    </Box>
  );
}
