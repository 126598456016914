import { Card, makeStyles } from '@material-ui/core';
import React from 'react';
import classNames from "classnames";


const useStyle = makeStyles((theme) => ({
    root: props => ({
        borderRadius: props.radius ?? "0.75rem",
        width: props.width,
        height: props.height,
        overflow: 'hidden',
        backgroundColor: props.color ? theme.palette.background[props.color] : theme.palette.background.paper, // TODO: ? other color palettes?
    })  
}))

export default function MyCard(props) {

    const classes = useStyle(props);

    return (
        <Card
            style={ props.style }
            // elevation={props.elevation ? props.elevation: 5}
            className={classNames(props.className, "shadow-md", classes.root)}>
                { props.children }
        </Card>
    )
}