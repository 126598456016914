import { Icon, Typography } from "@material-ui/core";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MyButton from "../../../../../components/my-button";
import { PrimaryButton } from "../../../../../components/tailwind-components/buttons";
import MyCard from "../../../main/components/ui-components/my-card";

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

export function ExerciseDnDList({ formik, name, remove, onEdit }) {
  return (
    <DnDList
      formik={formik}
      name={name}
      remove={remove}
      onEdit={onEdit}
      listItem={(item, index, provided) => (
        <MyCard className="p-4 my-2">
          <div className="flex justify-between items-start flex-wrap">
            <div className="flex items-center">
              <Icon {...provided.dragHandleProps}>reorder</Icon>
              <div className="ml-4 mt-4">
                <Typography variant="h3">{item.name}</Typography>
                {/* <h3 className="text-lg leading-6 font-medium text-gray-900">{item.name}</h3> */}
                <Typography className="mt-2" variant="subtitle1" color="textSecondary">{item.type}</Typography>
                {/* <p className="mt-1 text-sm text-gray-500">
                  {item.type} - {item.id}
                </p> */}
              </div>
            </div>
            <div className="flex items-center justify-center h-full space-x-4">
              <MyButton onClick={() => onEdit(item)}>Edit</MyButton>
              <MyButton color="secondary" onClick={() => remove(index)} >Delete</MyButton>
            </div>
          </div>
        </MyCard>
      )}
    ></DnDList>
  );
}

export function ContentDnDList({ formik, values, name, remove, onEdit }) {
  return (
    <DnDList
      formik={formik}
      name={name}
      values={values}
      remove={remove}
      onEdit={onEdit}
      listItem={(item, index, provided) => (
        <MyCard className="p-4 my-2">
          <div className="flex justify-between items-center flex-wrap">
            <div className="flex items-center">
              <Icon {...provided.dragHandleProps}>reorder</Icon>
              <div className="ml-4">
                <Typography variant="h3">{item.title}</Typography>
                {/* <h3 className="text-lg leading-6 font-medium text-gray-900">{item.title}</h3> */}
                <Typography className="mt-2" variant="subtitle1" color="textSecondary">{item.type}</Typography>
                {/* <p className="mt-1 text-sm text-gray-500">
                  {item.type} - {item.id}
                </p> */}
              </div>
            </div>
            <div className="flex items-center justify-center h-full space-x-4">
              <MyButton onClick={() => onEdit(item)}>Edit</MyButton>
              <MyButton color="secondary" onClick={() => remove(index)} >Delete</MyButton>
              {/* <div className="ml-4 flex-shrink-0">
                <PrimaryButton onClick={() => onEdit(item)}>Edit</PrimaryButton>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button
                  onClick={() => remove(index)}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Delete
                </button>
              </div> */}
            </div>
          </div>
        </MyCard>
      )}
    ></DnDList>
  );
}

export function DnDList({ formik, items, name, values, remove, onEdit, listItem }) {
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const newList = reorder(items ?? formik.values[name], result.source.index, result.destination.index);

    formik.setFieldValue(name, newList);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div>
              {(values ? values[name] : formik.values[name])?.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {listItem(item, index, provided)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
